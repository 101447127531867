import { NChampionshipCompetency } from './interfaces'
import ChampionshipCompetencyModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class ChampionshipCompetencyPlugin extends BasePlugin<
  NChampionshipCompetency.ICompetency,
  'id',
  keyof NChampionshipCompetency.ICompetency,
  ChampionshipCompetencyModule
> {}
