import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { Ids, Banner, BannerBlock, BannerParams, BannersFilters, BlocksFilters } from './interface'
import { $axios } from '~/utils/api'
import { defaultData, FormError, PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'bannerBlocks',
  stateFactory: true,
  namespaced: true
})
export default class BannerBlocksModule extends VuexModule {
  /**
   * * Значение фильтров блоков баннеров
   */
  blocksFiltersValue: BlocksFilters = {
    role: undefined
  }

  /**
   * * Массив блоков
   */
  blocks: ResponseData<BannerBlock> = defaultData

  /**
   * * Текущий блок
   */
  block: BannerBlock = {
    name: '',
    code: '',
    active: true,
    siteId: 1,
    createdAt: 0,
    updatedAt: 0,
    useNameOnSite: false
  }

  /**
   * * Массив параметров баннеров
   */
  bannerParamsValue: BannerParams[] = []

  /**
   * * Фильтры баннеров
   * * Дефолтные значения фильтров undefined
   */
  bannersFiltersValue: BannersFilters = {
    name: undefined,
    active: undefined,
    role: undefined
  }

  /**
   * * Массив баннеров
   */
  banners: ResponseData<Banner> = defaultData

  /**
   * * Текущий баннер
   */
  banner: Banner = {
    name: '',
    bannerBlockId: 0,
    url: '',
    sort: 0,
    dateFrom: null,
    dateTo: null,
    actionEndDate: null,
    actionContinue: false,
    roles: [],
    active: true,
    createdAt: 0,
    text: null,
    updatedAt: 0,
    siteId: [],
    params: [],
    fileId: null,
    products: [],
    dates: [],
    mobileFileId: null,
    categories: [],
    videoId: null,
    mobileVideoId: null
  }

  // ? ______________ getters ______________

  /**
   * * Получить фильтры блоков
   */
  get blocksFilters (): BlocksFilters {
    return this.blocksFiltersValue
  }

  /**
   * * Получить фильтры баннеров
   */
  get bannersFilters (): BannersFilters {
    return this.bannersFiltersValue
  }

  /**
   * * Получить массив блоков
   */
  get bannerBlocks (): ResponseData<BannerBlock> {
    return this.blocks
  }

  /**
   * * Получить текущий шаблон
   */
  get currentBlock (): BannerBlock {
    return this.block
  }

  /**
   * * Получить список параметров баннера
   */
  get bannerParams (): BannerParams[] {
    return this.bannerParamsValue
  }

  /**
   * * Получить массив баннеров
   */
  get blockBanners (): ResponseData<Banner> {
    return this.banners
  }

  /**
   * * Получить текущий баннер
   */
  get currentBanner (): Banner {
    return this.banner
  }

  /**
   * * Валидатор для формы баннеров
   */
  get bannerValidators (): ValidatorParams {
    return {
      name: [{ required: true, message: 'Введите название баннера', trigger: 'blur' }],
      // roles: [{ required: true, type: 'number', min: 1, message: 'Выберите роли', trigger: ['blur', 'change'] }],
      dateTo: [{ required: true, type: 'number', message: 'Выберите продолжительность показа', trigger: ['blur', 'change'] }],
      dateFrom: [{ required: true, type: 'number', message: 'Выберите продолжительность показа', trigger: ['blur', 'change'] }],
      sort: [{ pattern: validatorsPattern.wholeNumbers, message: 'Введите целое число', trigger: ['blur', 'change'] }]
    }
  }

  get bannerParamsValidators (): ValidatorParams {
    return {
      params: [{ pattern: validatorsPattern.naturalNumbers, message: 'Введите натуральное число', trigger: ['blur'] }]
    }
  }

  /**
   * * Валидатор для формы блоков
   */
  get blockValidators (): ValidatorParams {
    return {
      name: [{ required: true, message: 'Введите название блока', trigger: 'blur' }],
      code: [{ required: true, message: 'Введите код блока', trigger: 'blur' }]
    }
  }

  /**
   * * Получить блок по id
   */
  get bannerBlockById () {
    const blocks = this.bannerBlocks
    return function (id: number): BannerBlock | undefined {
      return blocks.data.find(block => block.id === id)
    }
  }

  /**
   * * Получить баннер по id
   */
  get bannerById () {
    const banners = this.banners
    return function (id: number): Banner | undefined {
      return banners.data.find(banner => banner.id === id)
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить фильтры блоков баннеров
   * @param blockFilters - значения фильтров
   */
  @Mutation
  setBlocksFilters (blockFilters: BlocksFilters) {
    this.blocksFiltersValue = blockFilters
  }

  /**
   * * Сбросить фильтры блоков баннеро
   */
  @Mutation
  resetBlocksFilters () {
    this.blocksFiltersValue = {
      role: undefined
    }
  }

  /**
   * * Установить фильтры баннеров
   * @param bannersFilters - значения фильтров
   */
  @Mutation
  setBannersFilters (bannersFilters: BannersFilters) {
    this.bannersFiltersValue = bannersFilters
  }

  /**
   * * Сбросить фильтры баннеров
   */
  @Mutation
  resetBannersFilters () {
    this.bannersFiltersValue = {
      name: undefined,
      active: undefined
    }
  }

  /**
   * * Установить массив блоков
   * @param blocks массив блоков
   */
  @Mutation
  setBannerBlocks (blocks: ResponseData<BannerBlock>) {
    this.blocks = blocks
  }

  /**
   * * Установить текущий блок
   * @param block текущий блок
   */
  @Mutation
  setCurrentBlock (block: BannerBlock) {
    this.block = block
  }

  /**
   * * Обнулить форму блока
   */
  @Mutation
  resetCurrentBlock () {
    this.block = {
      name: '',
      code: '',
      active: true,
      siteId: 1,
      createdAt: 0,
      updatedAt: 0,
      useNameOnSite: false
    }
  }

  /**
   * * Установить массив баннеров
   */
  @Mutation
  setBlockBanners (banners: ResponseData<Banner>) {
    this.banners = banners
  }

  /**
   * * Установить массив параметров баннеров
   */
  @Mutation
  setBannerParams (bannerParams: BannerParams[]) {
    this.bannerParamsValue = bannerParams
  }

  /**
   * * Установить текущий баннер
   */
  @Mutation
  setCurrentBanner (banner: Banner) {
    this.banner = banner
  }

  /**
   * * Обнулить список баннеров
   */
  @Mutation
  resetBlocks () {
    this.blocks = defaultData
  }

  /**
   * * Обнулить список баннеров
   */
  @Mutation
  resetBanners () {
    this.banners = defaultData
  }

  /**
   * * Обнулить форму баннера
   */
  @Mutation
  resetCurrentBanner () {
    this.banner = {
      name: '',
      bannerBlockId: 0,
      url: '',
      sort: 0,
      dateFrom: null,
      dateTo: null,
      actionEndDate: null,
      actionContinue: false,
      text: null,
      roles: [],
      active: true,
      createdAt: 0,
      updatedAt: 0,
      siteId: [],
      fileId: null,
      products: [],
      params: [],
      dates: [],
      categories: [],
      mobileFileId: null,
      videoId: null,
      mobileVideoId: null
    }
  }

  /**
   * * Обнулить форму параметров баннера
   */
  @Mutation
  resetBannerParams (): void {
    this.bannerParamsValue = []
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список блоков баннеров
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setBannerBlocks'
  })
  async getBannerBlocks (pageParams: PageParams | null = null) {
    try {
      const { data } = await $axios.get('/banner-block', { params: { ...pageParams, ...this.blocksFilters } })
      const response: ResponseData<BannerBlock> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить блок баннеров по id
   * @param id id блока
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setCurrentBlock'
  })
  async getBannerBlockById (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/banner-block/${id}`)
      const response: BannerBlock = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать блок
   */
  @Action({
    rawError: true
  })
  async createBannerBlock () {
    try {
      const newBannerBlock = {
        name: this.currentBlock.name,
        code: this.currentBlock.code,
        active: this.currentBlock.active,
        siteId: this.currentBlock.siteId
      }
      const { data: { data } } = await $axios.post('/banner-block', newBannerBlock)
      const response: BannerBlock = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Редактировать блок
   */
  @Action({
    rawError: true
  })
  async editBannerBlock () {
    const { id, ...rest } = this.currentBlock
    try {
      const { data: { data } } = await $axios.put(`banner-block/${id}`, rest)
      const response: BannerBlock = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить блок
   */
  @Action({
    rawError: true
  })
  async removeBannerBlock (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`banner-block/${id}`)
      const response: BannerBlock = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список блоков баннеров
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setBannerParams'
  })
  async getBannerParams () {
    try {
      const { id } = this.currentBlock
      const { data: { data } } = await $axios.get(`banner-block/${id}/banner/params`)
      const response: BannerParams[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список блоков баннеров
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setBlockBanners'
  })
  async getBanners (pageParams: PageParams | null = null) {
    try {
      const { id } = this.currentBlock
      const { data } = await $axios.get(`banner-block/${id}/banner`, {
        params: {
          ...pageParams,
          ...this.bannersFilters,
          sort: pageParams?.sort || 'sort',
          order: pageParams?.order || 'asc'
        }
      })
      const response: ResponseData<Banner> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить баннер по id
   * @param ids id баннера
   */
  @Action({
    rawError: true,
    commit: 'setCurrentBanner'
  })
  async getBannerById (ids: Ids) {
    try {
      const { data: { data } } = await $axios.get(`/banner-block/${ids.bannerBlockId}/banner/${ids.bannerId}`)
      const response: Banner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать баннер
   */
  @Action({
    rawError: true
  })
  async createBanner () {
    const newBanner = {
      ...this.currentBanner,
      actionEndDate: this.currentBanner.actionEndDate || null,
      roles: this.currentBanner.roles.filter(role => typeof role === 'string'),
      products: this.currentBanner.products?.map((product: { id: number; productId: number; x: number; y: number }) => {
        return {
          id: product.id,
          productId: product.productId,
          x: product.x,
          y: product.y
        }
      }) ?? []
    }
    try {
      const { data: { data } } = await $axios.post(`/banner-block/${this.currentBlock.id}/banner`, newBanner)
      const response: Banner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить баннер
   */
  @Action({
    rawError: true
  })
  async editBanner () {
    const newBanner = {
      ...this.currentBanner,
      actionEndDate: this.currentBanner.actionEndDate || null,
      roles: this.currentBanner.roles.filter(role => typeof role === 'string'),
      products: this.currentBanner.products?.map((product: { id: number; productId: number; x: number; y: number }) => {
        return {
          id: product.id,
          productId: product.productId,
          x: product.x,
          y: product.y
        }
      }) ?? []
    }
    try {
      const { data: { data } } = await $axios.put(`/banner-block/${this.currentBlock.id}/banner/${this.currentBanner.id}`, newBanner)
      const response: Banner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить баннер
   */
  @Action({
    rawError: true
  })
  async removeBanner (id: number): Promise<Banner> {
    try {
      const { data: { data } } = await $axios.delete(`/banner-block/${this.currentBlock.id}/banner/${id}`)
      const response: Banner = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
