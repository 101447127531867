
import { Component, Prop, Vue, Model } from 'nuxt-property-decorator'

@Component({
  name: 'UiInputNumber'
})
export default class UiInputNumber extends Vue {
  @Model('change', { type: Number, required: false }) value!: number | null

  @Prop({ type: Number, default: -Infinity }) min!: number
  @Prop({ type: Number, default: Infinity }) max!: number
  @Prop({ type: Number, default: 1 }) step!: number
  @Prop({ type: Boolean, default: true }) controls!: boolean
  @Prop({ type: String, default: 'right' }) controlsPosition!: string
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: String, default: 'default' }) size!: string
  @Prop({ type: Boolean, default: false }) clearable!: boolean
  @Prop({ type: Number, default: false }) defaultValue!: number

  get computedValue (): number | null {
    return this.value
  }

  set computedValue (val: number | null) {
    if (val !== this.value) {
      this.$emit('change', val)
    }
  }

  clearValue (): void {
    this.$emit('clear', this.defaultValue)
  }

  onChange (val: number | null): void {
    this.$emit('change', val)
  }
}
