import { NGamificationRewards } from './interfaces'
import GamificationRewardsModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class GamificationRewardsPlugin extends BasePlugin<
  NGamificationRewards.IReward,
  'id',
  keyof NGamificationRewards.IReward,
  GamificationRewardsModule
> {}
