import { Component } from 'nuxt-property-decorator'
import { NChampionshipAgeGroup } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'ChampionshipAgeGroupMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  async getChampionshipAgeGroups (pageParams?: PageParams<NChampionshipAgeGroup.IAgeGroup>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipAgeGroups' },
      this.$championship.ageGroup.getList(pageParams, save)
    )
  }

  async getChampionshipAgeGroupById (id: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipAgeGroupById' },
      this.$championship.ageGroup.getItem(id, save)
    )
  }

  createChampionshipAgeGroup () {
    return this.asyncRequestHandler(
      {
        loader: 'createChampionshipAgeGroup',
        message: (ageGroup: NChampionshipAgeGroup.IAgeGroup) => `Группа ${ageGroup.name} создана`
      },
      this.$championship.ageGroup.createItem()
    )
  }

  editChampionshipAgeGroup (id: NChampionshipAgeGroup.IAgeGroup['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editChampionshipAgeGroup',
        message: (ageGroup: NChampionshipAgeGroup.IAgeGroup) => `Группа ${ageGroup.name} изменена`
      },
      this.$championship.ageGroup.editItem(id)

    )
  }

  async removeChampionshipAgeGroupById (id: NChampionshipAgeGroup.IAgeGroup['id']) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipAgeGroupById' },
      this.$championship.ageGroup.removeItem(id)
    )
  }
}
