import { Module, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { NOneSignal } from '../interfaces'
import { createdTemplateDefaultData, templateDefaultData } from '../const'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'oneSignalPushNotificationTemplate',
  stateFactory: true,
  namespaced: true
})
export default class PushNotificationTemplateModule extends BaseModule<
    NOneSignal.IOneSignalTemplate,
    'id',
    keyof NOneSignal.IOneSignalTemplate
> {
  protected config: ModuleConfig<NOneSignal.IOneSignalTemplate, 'id'> = {
    apiUrl: 'api/notifications/v1/onesignal/templates',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_DOMAIN'
  }

  protected itemData: NOneSignal.IOneSignalTemplate = templateDefaultData
  protected createdTemplateData: NOneSignal.IOneSignalCreatedTemplate = createdTemplateDefaultData
  protected statusesData: NOneSignal.IOneSignalStatus[] = []

  get createdTemplate () {
    return this.createdTemplateData
  }

  set createdTemplate (templateData) {
    this.createdTemplateData = templateData
  }

  get statusesList () {
    return this.statusesData
  }

  set statusesList (statusesData: NOneSignal.IOneSignalStatus[]) {
    this.statusesData = statusesData
  }

  @Mutation
  resetItemData () {
    this.itemData = templateDefaultData
  }

  @Mutation
  resetCreatedItemData () {
    this.createdTemplateData = createdTemplateDefaultData
  }

  @Mutation
  setCreatedTemplate (templateData: NOneSignal.IOneSignalCreatedTemplate) {
    this.createdTemplateData = templateData
  }

  @Mutation
  setStatuses (statusesData: NOneSignal.IOneSignalStatus[]) {
    this.statusesData = statusesData
  }

  @Action({
    rawError: true
  })
  async createPushNotificationTemplate (): Promise<NOneSignal.IOneSignalCreatedTemplate['id']> {
    try {
      const payload = (({ id, ...rest }) => rest)(this.createdTemplate)

      const { data } = await $axios.post(
        `${this.config.apiUrl}`,
        payload,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )

      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async editPushNotificationTemplate (id: NOneSignal.IOneSignalCreatedTemplate['id']) {
    const payload = (({ id, ...rest }) => rest)(this.createdTemplate)

    try {
      await $axios.put<NOneSignal.IOneSignalCreatedTemplate>(
            `${this.config.apiUrl}/${id}`,
            payload,
            {
              headers: {
                common: {
                  apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
                }
              }
            }
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setStatuses'
  })
  async getStatuses () {
    try {
      const { data } = await $axios.get<NOneSignal.IOneSignalStatus[]>(
        'api/notifications/v1/onesignal/statuses',
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )

      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async editStatus (params: {statusCode: NOneSignal.IOneSignalStatus['code'], templateId: NOneSignal.IOneSignalTemplate['id']}) {
    const payload = params

    try {
      const { data } = await $axios.put<NOneSignal.IOneSignalStatus[]>(
        'api/notifications/v1/onesignal/match',
        payload,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )

      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
