import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipChampionships } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipChampionships',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipChampionshipsModule extends BaseModule<
  NChampionshipChampionships.IChampionship,
  'id',
  keyof NChampionshipChampionships.IChampionship
> {
  protected config: ModuleConfig<NChampionshipChampionships.IChampionship, 'id'> = {
    apiUrl: '/championship',
    identifier: 'id'
  }

  protected itemData: NChampionshipChampionships.IChampionship = {
    name: '',
    code: '',
    cityId: null,
    ageGroupIds: [],
    type: '',
    active: true,
    params: {},
    stages: []
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      name: '',
      code: '',
      cityId: null,
      ageGroupIds: [],
      type: '',
      active: true,
      stages: [],
      params: {}
    }
  }
}
