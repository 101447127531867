import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipCities } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipCities',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipCitiesModule extends BaseModule<
  NChampionshipCities.ICity,
  'id',
  keyof NChampionshipCities.ICity
> {
  protected config: ModuleConfig<NChampionshipCities.ICity, 'id'> = {
    apiUrl: '/championship/city',
    identifier: 'id'
  }

  protected itemData: NChampionshipCities.ICity = {
    name: '',
    code: '',
    sort: 0,
    active: true
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      name: '',
      code: '',
      sort: 0,
      active: true
    }
  }
}
