import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, ResponseData, defaultData, PageParams, ValidatorParams } from '../../interfaces'
import { PromoCode, Filters, PromoCodesCreationMethod } from './interfaces'
import { $axios } from '~/utils/api'
import validators from '~/utils/validators'

@Module({
  name: 'promoCodes',
  stateFactory: true,
  namespaced: true
})

export default class PromoCodesModule extends VuexModule {
  /**
   * * Массив всех promoCode исходя из запроса
   */
  promoCodes: ResponseData<PromoCode> = defaultData

  promoCodesCreationMethods: ResponseData<PromoCodesCreationMethod> = defaultData

  filtersValues: Filters = {
    code: undefined,
    discount: undefined,
    type_id: undefined,
    startDate: undefined,
    endDate: undefined,
    reusable: undefined,
    source: undefined,
    product_group_id: undefined,
    is_active: undefined
  }

  /**
   * * Текущая promoCode
   */
  promoCode: PromoCode = {
    code: '',
    description: '',
    type: 0,
    productGroupId: null,
    discount: 1,
    isActive: true,
    priceLimit: 1,
    minProducts: 1,
    manual: true,
    isSet: true,
    reusable: true,
    startDate: null,
    endDate: null,
    onlySets: false,
    userId: null
  }

  promoCodesCreationMethod: PromoCodesCreationMethod = {
    code: '',
    name: '',
    description: ''
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      discount: [this.currentPromoCode.type === 2 ? { required: true, type: 'number', min: 1, message: 'Введите значение скидки', trigger: ['blur'] } : { required: true, type: 'number', min: 1, max: 95, message: 'Введите значение скидки(макс: 95)', trigger: ['blur'] }, { pattern: validators.naturalNumbers, message: 'Введите целое число' }],
      priceLimit: [{ type: 'number', message: 'Введите значение порога', trigger: ['blur'] }, { pattern: validators.naturalNumbers, message: 'Введите целое число' }, { type: 'number', min: 1, message: 'Минимальное значение 1' }],
      source: [{ required: true, message: 'Выберите метод создания промокода' }]
    }
  }

  get methodsValidators (): ValidatorParams {
    return {
      code: [{ required: true, message: 'Введите код метода создания промокода', trigger: ['change', 'blur'] }],
      description: [{ required: true, message: 'Введите название метода создания промокода', trigger: ['change', 'blur'] }]
    }
  }

  /**
   * * Получить массив promoCodes и пагинацией
   */
  get promoCodesList (): ResponseData<PromoCode> {
    return this.promoCodes
  }

  get promoCodesCreationMethodsList (): ResponseData<PromoCodesCreationMethod> {
    return this.promoCodesCreationMethods
  }

  /**
   * * Получить filters
   */
  get filters (): Filters {
    return this.filtersValues
  }

  /**
   * * Получить promoCode из массива promoCodes
   */
  get promoCodeById () {
    const promoCodes = this.promoCodes
    return function (id: number): PromoCode | undefined {
      return promoCodes.data.find(promoCode => promoCode.id === id)
    }
  }

  /**
   * * Получить текущую promoCode для измения или создания promoCode
   */
  get currentPromoCode () {
    return this.promoCode
  }

  get currentPromoCodesCreationMethod () {
    return this.promoCodesCreationMethod
  }

  // ? ______________ setters ______________

  // * Сбросить фильтры
  @Mutation
  resetFilters (): void {
    this.filtersValues = {
      code: undefined,
      discount: undefined,
      source: undefined,
      reusable: undefined,
      type: undefined,
      productGroupId: undefined,
      startDate: undefined,
      startDateFrom: undefined,
      startDateTo: undefined,
      endDate: undefined,
      endDateFrom: undefined,
      endDateTo: undefined,
      type_id: undefined,
      product_group_id: undefined,
      is_active: undefined
    }
  }

  /**
   * * Установить массив PromoCodes
   * @param promoCodes массив PromoCodes
   */
  @Mutation
  setPromoCodesList (promoCodes: ResponseData<PromoCode>) {
    this.promoCodes = promoCodes
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValues = filters
  }

  /**
   * * Установить CurrentPromoCode для измения или создания promoCode
   * @param promoCode текущая PromoCode, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentPromoCode (promoCode: PromoCode) {
    this.promoCode = promoCode
  }

  @Mutation
  setCurrentPromoCodesCreationMethod (promoCodesCreationMethod: PromoCodesCreationMethod) {
    this.promoCodesCreationMethod = promoCodesCreationMethod
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentPromoCode () {
    this.promoCode = {
      code: '',
      description: '',
      type: 0,
      productGroupId: null,
      discount: 1,
      isActive: true,
      priceLimit: 1,
      minProducts: 1,
      manual: true,
      isSet: true,
      reusable: false,
      startDate: null,
      endDate: null,
      onlySets: false,
      userId: null
    }
  }

  @Mutation
  resetCurrentPromoCodesCreationMethod () {
    this.promoCodesCreationMethod = {
      code: '',
      name: '',
      description: ''
    }
  }

  @Mutation
  resetPromoCodes () {
    this.promoCodes = defaultData
  }

  @Mutation
  resetPromoCodesCreationMethods () {
    this.promoCodesCreationMethods = defaultData
  }

  @Mutation
  setPromoCodesCreationMethodsList (methods: ResponseData<PromoCodesCreationMethod>) {
    this.promoCodesCreationMethods = methods
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список PromoCodes по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setPromoCodesList'
  })
  async getPromoCodes (params: { pageParams: PageParams, siteDomain: string }) {
    const { pageParams, siteDomain } = params
    try {
      const { data } = await $axios.get(`${siteDomain}/api/promocode/v1`, { params: { ...pageParams } })
      const response: ResponseData<PromoCode> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить promoCode по id
   * @param id id promoCode которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentPromoCode'
  })
  async getPromoCode (params: { id: number, siteDomain: string }) {
    try {
      const { id, siteDomain } = params
      const { data: { data } } = await $axios.get(`${siteDomain}/api/promocode/v1/${id}`)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить promoCode
   */
  @Action({
    rawError: true
  })
  async editPromoCode (siteDomain: string) {
    try {
      const { id, ...promoCode } = this.currentPromoCode
      const { data: { data } } = await $axios.put(`${siteDomain}/api/promocode/v1/${id}`, promoCode)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать PromoCode
   */
  @Action({
    rawError: true
  })
  async createPromoCode (siteDomain: string) {
    try {
      const { data: { data } } = await $axios.post(`${siteDomain}/api/promocode/v1`, this.currentPromoCode)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить promoCode по id
   * @param id  id promoCode которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removePromoCode (params: { id: number, siteDomain: string }) {
    try {
      const { id, siteDomain } = params
      const { data: { data } } = await $axios.delete(`${siteDomain}/api/promocode/v1/${id}`)
      const response: PromoCode = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setPromoCodesCreationMethodsList'
  })
  async getPromoCodesCreationMethods (params: { pageParams: PageParams, siteDomain: string }) {
    const { pageParams, siteDomain } = params

    try {
      const { data } = await $axios.get(`${siteDomain}/api/promocode/v1/methods/creating`, { params: { ...pageParams } })
      const response: ResponseData<PromoCodesCreationMethod> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async createPromoCodesCreationMethod (siteDomain: string) {
    try {
      const { data: { data } } = await $axios.post(`${siteDomain}/api/promocode/v1/methods/creating`, this.currentPromoCodesCreationMethod)
      const response: PromoCodesCreationMethod = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
