import { Method } from 'axios'
import { ReindexerPlugin } from '~/store/modules/reindexer/interfaces'

export default function getPluginApi (reindexerStore:any) {
  const api: ReindexerPlugin = {
    export (url: string, method: Method): Promise<boolean> {
      return reindexerStore.export({ url, method })
    }
  }
  return api
}
