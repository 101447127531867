import { Module, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { NRefund } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { defaultData, FormError } from '~/store/interfaces'
import { ModuleConfig, ResponseData } from '~/types/store/store-base-type'
import { $axios } from '~/utils/api'

@Module({
  name: 'refunds',
  stateFactory: true,
  namespaced: true
})
export default class RefundModule extends BaseModule<
NRefund.IRefund,
  'id',
  keyof NRefund.IRefund
> {
  protected config: ModuleConfig<NRefund.IRefund, 'id'> = {
    apiUrl: 'api/orders/v1/admin/refunds',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_DOMAIN'
  }

  protected itemData: NRefund.IRefund = {
    articul: '',
    createdAt: 0,
    email: '',
    fio: '',
    id: 0,
    managerResponse: '',
    offerImagesIds: [],
    offerVideoId: 0,
    phone: '',
    reason: '',
    receiptImageId: 0,
    refundParams: {
      city: '',
      kladrId: '',
      pickupPointAddress: '',
      pickupPointCode: '',
      zip: ''
    },
    statusId: 0,
    updatedAt: 0
  }

  protected refundsStatusesList: ResponseData<NRefund.IRefundStatus> = defaultData

  get refundsStatuseslist () {
    return this.refundsStatusesList
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      articul: '',
      createdAt: 0,
      email: '',
      fio: '',
      id: 0,
      managerResponse: '',
      offerImagesIds: [],
      offerVideoId: 0,
      phone: '',
      reason: '',
      receiptImageId: 0,
      refundParams: {
        city: '',
        kladrId: '',
        pickupPointAddress: '',
        pickupPointCode: '',
        zip: ''
      },
      statusId: 0,
      updatedAt: 0
    }
  }

  @Mutation
  setRefundsStatusesList (refundsStatusesList: ResponseData<NRefund.IRefundStatus>) {
    this.refundsStatusesList = refundsStatusesList
  }

  @Action({
    rawError: true
  })
  async rejectRefund (identifier: number) {
    try {
      const payload = { managerResponce: this.item.managerResponse }
      const { data } = await $axios.post<ResponseData<NRefund.IRefund>>(
        `${this.config.apiUrl}/${identifier}/reject`,
        payload,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async createOrderFromRefund (identifier: number) {
    try {
      const payload = { managerResponce: this.item.managerResponse }
      const { data } = await $axios.post<ResponseData<NRefund.IRefund>>(
        `api/orders/v1/admin/order-from-refund/${identifier}`,
        payload,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setRefundsStatusesList'
  })
  async getRefundsStatuses () {
    try {
      const { data } = await $axios.get<ResponseData<NRefund.IRefundStatus>>(
        `${this.config.apiUrl}/statuses`,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
