import { NChampionshipAgeType } from './interfaces'
import ChampionshipAgeTypeModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class ChampionshipAgeTypePlugin extends BasePlugin<
  NChampionshipAgeType.IAgeType,
  'id',
  keyof NChampionshipAgeType.IAgeType,
  ChampionshipAgeTypeModule
> {}
