import { Component } from 'nuxt-property-decorator'
import { NChampionshipPermission } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'ChampionshipPermissionMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$championship.permission.resetItem()
    this.$championship.permission.resetList()
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getChampionshipPermissions (pageParams?: PageParams<NChampionshipPermission.IPermission>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipPermissions' },
      this.$championship.permission.getList(pageParams, save)
    )
  }

  async editChampionshipPermissionItem (
    id: NChampionshipPermission.IPermission['id'],
    item?: NChampionshipPermission.IPermission
  ) {
    return await this.asyncRequestHandler(
      { loader: 'editChampionshipPerimssionItem', message: ({ id }) => `Роль №${id} изменена` },
      this.$championship.permission.editItem(id, item)
    )
  }

  setChampionshipPermissionItem<K extends keyof NChampionshipPermission.IPermission> (
    { key, value }: { key: K, value: NChampionshipPermission.IPermission[K] }
  ) {
    const permission = this.$championship.permission.item
    this.$championship.permission.item = { ...permission, [key]: value }
  }
}
