import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import {
  WebsiteAssistantPage,
  WebsiteAssistantPageForm,
  WebsiteAssistantQuestion,
  WebsiteAssistantQuestionForm,
  UpdateAssistantPageParams,
  UpdateAssistantQuestionParams
} from './interfaces'
import { ResponseData, FormError, PageParams, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'
@Module({
  name: 'websiteAssistant',
  stateFactory: true,
  namespaced: true
})
export default class WebsiteAssistantModule extends VuexModule {
  private baseApiUrl = '/website-assistant/v1/admin'

  protected config = {
    apiUrlPrefixEnv: 'SITE_API'
  }

  private get baseHeaders () {
    return {
      common: {
        apiUrlPrefixEnv: this.config.apiUrlPrefixEnv
      }
    }
  }

  pages = {} as ResponseData<WebsiteAssistantPage>
  currentPage = {} as WebsiteAssistantPage
  questions = {} as ResponseData<WebsiteAssistantQuestion>
  currentQuestion = {} as WebsiteAssistantQuestion

  get websiteAssistantPages (): ResponseData<WebsiteAssistantPage> {
    return this.pages
  }

  get currentWebsiteAssistantPage (): WebsiteAssistantPage {
    return this.currentPage
  }

  get websiteAssistantQuestions (): ResponseData<WebsiteAssistantQuestion> {
    return this.questions
  }

  get currentWebsiteAssistantQuestion (): WebsiteAssistantQuestion {
    return this.currentQuestion
  }

  get validators (): ValidatorParams {
    return {
      title: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        trigger: ['blur'],
        message: 'Введите название страницы'
      }],
      siteTitle: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        trigger: ['blur'],
        message: 'Введите заголовок на сайте'
      }],
      url: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        trigger: ['blur'],
        message: 'Введите URL'
      }],
      question: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        trigger: ['blur'],
        message: 'Введите вопрос'
      }],
      answer: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        trigger: ['blur'],
        message: 'Введите ответ'
      }]
    }
  }

  @Mutation
  setWebsiteAssistantPages (pages: ResponseData<WebsiteAssistantPage>) {
    this.pages = pages
  }

  @Mutation
  setCurrentWebsiteAssistantPage (page: WebsiteAssistantPage) {
    this.currentPage = page
  }

  @Mutation
  setWebsiteAssistantQuestions (
    questions: ResponseData<WebsiteAssistantQuestion>
  ) {
    this.questions = questions
  }

  @Mutation
  setCurrentWebsiteAssistantQuestion (question: WebsiteAssistantQuestion) {
    this.currentQuestion = question
  }

  @Action({ rawError: true, commit: 'setCurrentWebsiteAssistantPage' })
  async fetchWebsiteAssistantPageByID (id: string) {
    try {
      const { data } = await $axios.get(`${this.baseApiUrl}/${id}`, {
        headers: this.baseHeaders
      })
      return data.data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async deleteWebsiteAssistantPageByID (id: number) {
    try {
      await $axios.delete(`${this.baseApiUrl}/${id}`, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async createWebsiteAssistantPage (data: WebsiteAssistantPageForm) {
    try {
      await $axios.post(`${this.baseApiUrl}`, data, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async updateWebsiteAssistantPageByID ({
    id,
    params
  }: {
    id: number
    params: UpdateAssistantPageParams
  }) {
    try {
      await $axios.patch(`${this.baseApiUrl}/${id}`, params, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true, commit: 'setWebsiteAssistantQuestions' })
  async fetchWebsiteAssistantQuestions ({
    pagesAssistantId,
    params
  }: {
    pagesAssistantId: number
    params: PageParams
  }) {
    try {
      const response = await $axios.get(
        `${this.baseApiUrl}/${pagesAssistantId}/questions`,
        {
          headers: this.baseHeaders,
          params
        }
      )
      return response.data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true, commit: 'setCurrentWebsiteAssistantQuestion' })
  async fetchWebsiteAssistantQuestionByID (id:number) {
    try {
      const { data } = await $axios.get(`${this.baseApiUrl}/questions/${id}`, {
        headers: this.baseHeaders
      })
      return data.data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async createWebsiteAssistantQuestion ({ id, data }: {id: number, data: WebsiteAssistantQuestionForm}) {
    try {
      await $axios.post(`${this.baseApiUrl}/questions`, { ...data, pageAssistantId: id }, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async updateWebsiteAssistantQuestionByID ({ id, params }: { id: number, params: UpdateAssistantQuestionParams }) {
    try {
      await $axios.patch(`${this.baseApiUrl}/questions/${id}`, params, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async deleteWebsiteAssistantQuestionByID (id: number) {
    try {
      await $axios.delete(`${this.baseApiUrl}/questions/${id}`, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
