import RewardsPlugin from './rewards'
import RewardsModule from './rewards/store'
import TasksPlugin from './tasks'
import TasksModule from './tasks/store'

export { RewardsModule as GamificationRewardsModule }
export { TasksModule as GamificationTasksModule }

export class GamificationPlugin {
  rewards: RewardsPlugin
  tasks: TasksPlugin

  constructor (
    rewardsModule: RewardsModule,
    tasksModule: TasksModule
  ) {
    this.rewards = new RewardsPlugin(rewardsModule)
    this.tasks = new TasksPlugin(tasksModule)
  }
}

export default function getGamificationApi (
  rewardsModule: RewardsModule,
  tasksModule: TasksModule
) {
  return new GamificationPlugin(
    rewardsModule,
    tasksModule
  )
}
