import { NChampionshipCities } from './interfaces'
import ChampionshipCitiesModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class CitiesPlugin extends BasePlugin<
  NChampionshipCities.ICity,
  'id',
  keyof NChampionshipCities.ICity,
  ChampionshipCitiesModule
> {}
