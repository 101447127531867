import { NChampionshipChampionships } from './interfaces'
import ChampionshipChampionshipsModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class ChampionshipChampionshipsPlugin extends BasePlugin<
  NChampionshipChampionships.IChampionship,
  'id',
  keyof NChampionshipChampionships.IChampionship,
  ChampionshipChampionshipsModule
> {}
