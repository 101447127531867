import { NGiftsType } from './interfaces'
import GiftsModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export class Gifts extends BasePlugin<
  NGiftsType.IGiftType,
  'id',
  keyof NGiftsType.IGiftType,
  GiftsModule
> {
  private store: GiftsModule

  constructor (store: GiftsModule) {
    super(store)
    this.store = store
  }

  get giftTypes () {
    return this.store.giftTypes
  }

  async fetchGiftTypes () {
    return await this.store.fetchGiftTypes()
  }
}

export default function getPluginApi (store: GiftsModule) {
  return new Gifts(store)
}
