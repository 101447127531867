import { NChampionshipStages } from './interfaces'
import ChampionshipStagesModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class StagesPlugin extends BasePlugin<
  NChampionshipStages.IStage,
  'id',
  keyof NChampionshipStages.IStage,
  ChampionshipStagesModule
> {}
