export declare namespace NChampionshipPermission {
    interface IPermissionsListItem {
        code: string,
        name: string
    }

    interface IPermission {
        id?: number,
        role: string,
        permissions: string[],
        createdAt: number,
        updatedAt: number
    }
}

export const permissionsDefaultData = {
  role: '',
  permissions: [],
  createdAt: 0,
  updatedAt: 0
}
