import { NOneSignal } from '../interfaces'
import PushNotificationTemplateModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class PushNotificationTemplatePlugin extends BasePlugin<
  NOneSignal.IOneSignalTemplate,
  'id',
  keyof NOneSignal.IOneSignalTemplate,
  PushNotificationTemplateModule
> {
  get createdTemplate (): NOneSignal.IOneSignalCreatedTemplate {
    return this.module.createdTemplate
  }

  set createdTemplate (templateData) {
    this.module.setCreatedTemplate(templateData)
  }

  get statusesList (): NOneSignal.IOneSignalStatus[] {
    return this.module.statusesList
  }

  set statusesList (statusesData: NOneSignal.IOneSignalStatus[]) {
    this.module.setStatuses(statusesData)
  }

  createPushNotificationTemplate (): Promise<NOneSignal.IOneSignalCreatedTemplate['id']> {
    return this.module.createPushNotificationTemplate()
  }

  editPushNotificationTemplate (id: NOneSignal.IOneSignalCreatedTemplate['id']): Promise<void> {
    return this.module.editPushNotificationTemplate(id)
  }

  getStatuses () {
    return this.module.getStatuses()
  }

  editStatus (params: {statusCode: NOneSignal.IOneSignalStatus['code'], templateId: NOneSignal.IOneSignalTemplate['id']}) {
    return this.module.editStatus(params)
  }
}
