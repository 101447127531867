import { NChampionshipAction } from '../interfaces'
import ChampionshipActionsModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class ActionsPlugin extends BasePlugin<
  NChampionshipAction.IAction,
  'id',
  keyof NChampionshipAction.IAction,
  ChampionshipActionsModule
> {}
