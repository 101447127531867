import { NChampionshipAgeGroup } from './interfaces'
import ChampionshipAgeGroupModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class ChampionshipAgeGroupPlugin extends BasePlugin<
  NChampionshipAgeGroup.IAgeGroup,
  'id',
  keyof NChampionshipAgeGroup.IAgeGroup,
  ChampionshipAgeGroupModule
> {}
