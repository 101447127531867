import { Component } from 'nuxt-property-decorator'
import { NGiftsType } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams, ORDER, ValidatorParams } from '~/types/store/store-base-type'
import pageSizes from '~/utils/page-sizes'
import validatorsPattern from '~/utils/validators'

@Component({
  name: 'GiftsMixin'
})
export default class GiftsMixin extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  async created () {
    this.$wait.end('leaveRouter')
    try {
      await this.$gifts.fetchGiftTypes()
    } catch (error: any) {
      this.$notify({
        type: 'error',
        title: error.error_code,
        message: error.error_message
      })
    }
  }

  validators: ValidatorParams<NGiftsType.IGiftType,
        | 'name'
        | 'typeId'
        | 'offerId'
        > = {
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название подарка',
        trigger: ['blur']
      }],
      typeId: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Выберите тип подарка',
        trigger: ['blur']
      }],
      offerId: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите ID торгового предложения',
        trigger: ['blur']
      }]
    }

  get pageParams () {
    return {
      page: +this.$route.query?.page || 1,
      pageSize: +this.$route.query.pageSize || pageSizes.default,
      sort:
        (this.$route.query?.sort as keyof NGiftsType.IGiftType) || undefined,
      order: (this.$route.query?.order as ORDER) || undefined
    }
  }

  get configGiftsTypesCodes () {
    return this.$configsList.clientConfig['admin-frontend']?.gifts ?? {}
  }

  async getGifts (pageParams?: PageParams<NGiftsType.IGiftType>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getGiftsList' },
      this.$gifts.getList(pageParams, save)
    )
  }

  async createGift () {
    return await this.asyncRequestHandler(
      { loader: 'createGift' },
      this.$gifts.createItem()
    )
  }

  async editGiftById (id?: number) {
    return await this.asyncRequestHandler(
      { loader: 'editGiftById' },
      this.$gifts.editItem(id)
    )
  }

  async removeGiftById (identifier: NGiftsType.IGiftType['id']) {
    return await this.asyncRequestHandler(
      { loader: 'removeGiftById' },
      this.$gifts.removeItem(
        identifier)
    )
  }
}
