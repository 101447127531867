import { Module, Mutation } from 'vuex-module-decorators'
import { NAchievements } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'achievements',
  stateFactory: true,
  namespaced: true
})
export default class AchievementsModule extends BaseModule<
  NAchievements.IAchievement,
  'id',
  keyof NAchievements.IAchievement
> {
  protected config: ModuleConfig<NAchievements.IAchievement, 'id'> = {
    apiUrl: '/achievements/v1/admin',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_API'
  }

  protected itemData: NAchievements.IAchievement = {
    description: '',
    imageId: null,
    isSystem: true,
    title: ''
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      description: '',
      imageId: null,
      isSystem: true,
      title: ''
    }
  }
}
