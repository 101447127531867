import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, PageParams, defaultData, ResponseData } from '../../interfaces'
import { Group, Filters, ProductParams } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'groupsParams',
  stateFactory: true,
  namespaced: true
})

export default class GroupsParamsModule extends VuexModule {
  groupsValue: ResponseData<Group> = defaultData

  filtersValue: Filters = {
    type: undefined
  }

  groupValue: Group = {
    active: true,
    groupId: undefined,
    id: 0,
    name: '',
    sort: undefined,
    code: ''
  }

  productParamsValue: ResponseData<ProductParams> = defaultData

  // ? ______________ getters ______________
  /**
   * * Получить значение тикета
   */
  get groups (): ResponseData<Group> {
    return this.groupsValue
  }

  get group (): Group {
    return this.groupValue
  }

  /**
   * * Получить фильтры групп статусов
   */
  get filters (): Filters {
    return this.filtersValue
  }

  get productParams (): ResponseData<ProductParams> {
    return this.productParamsValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить значение тикета
   * @param filters - значение тикета
   */
  @Mutation
  setGroupsValue (val: ResponseData<Group>) {
    this.groupsValue = val
  }

  @Mutation
  setGroupValue (val: any) {
    this.groupValue = val
  }

    /**
   * * Установить значения фильтров
   * @param filters - значения фильтров
   */
    @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

    /**
   * * Сбросить значения фильтров
   */
    @Mutation
    resetFilters () {
      this.filtersValue = {
        type: undefined
      }
    }

    @Mutation
    resetGroupValue () {
      this.groupValue = {
        active: false,
        groupId: undefined,
        id: undefined,
        name: '',
        sort: undefined,
        code: ''
      }
    }

    @Mutation
    setProductParamsValue (val: any) {
      this.productParamsValue = val
    }

    @Mutation
    resetProductParamsValue () {
      this.productParamsValue = defaultData
    }

    // ? ______________________________________actions______________________________________
  /**
   * *
   * @param id заказа
   */
  @Action({
    rawError: true,
    commit: 'setGroupsValue'
  })
    async getGroups ({
      siteApiUrl,
      pageParams
    }: {
    siteApiUrl: string;
    pageParams: PageParams | undefined
  }) {
      try {
        const { data } = await $axios.get(`${siteApiUrl}/optional-product-params/v1/groups`, { params: pageParams, ...this.filters })
        const response: Group[] = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true,
      commit: 'setGroupValue'
    })
  async getGroup ({
    siteApiUrl,
    id
  }: {
        siteApiUrl: string;
        id: number
      }) {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/optional-product-params/v1/groups/${id}`)
      const response: Group = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
    async createGroup ({
      siteApiUrl,
      group
    }: {
    siteApiUrl: string;
    group: Group;
  }) {
      try {
        const { data } = await $axios.post(`${siteApiUrl}/optional-product-params/v1/groups`, group)
        const response: Group = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
  async editGroup ({
    siteApiUrl,
    group,
    id
  }: {
        siteApiUrl: string;
        group: Group;
        id: number
      }) {
    try {
      const { data } = await $axios.put(`${siteApiUrl}/optional-product-params/v1/groups/${id}`, group)
      const response: Group = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
    async deleteGroup ({
      siteApiUrl,
      id
    }: {
      siteApiUrl: string;
      id: number
    }) {
      try {
        const { data } = await $axios.delete(`${siteApiUrl}/optional-product-params/v1/groups/${id}`)
        const response: Group = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
  async uploadFile ({
    siteApiUrl,
    file
  }: {
        siteApiUrl: string;
        file: any
      }) {
    try {
      const { data } = await $axios.put(`${siteApiUrl}/optional-product-params/v1/products/xlsx`, file)
      const response: ProductParams = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setProductParamsValue'
  })
    async getProductParams ({
      siteApiUrl,
      id,
      pageParams
    }: {
    siteApiUrl: string;
    id: number;
    pageParams: any
  }) {
      try {
        const { data } = await $axios.get(`${siteApiUrl}/optional-product-params/v1/products/${id}`, { params: pageParams })
        const response: ProductParams[] = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
  async editProductParams ({
    siteApiUrl,
    id,
    params
  }: {
            siteApiUrl: string;
            id: number;
            params: ProductParams
          }) {
    try {
      const { data } = await $axios.put(`${siteApiUrl}/optional-product-params/v1/products/${id}`, params)
      const response: ProductParams = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
    async updateProductParams ({
      siteApiUrl
    }: {
          siteApiUrl: string;
        }) {
      try {
        const { data } = await $axios.put(`${siteApiUrl}/optional-product-params/v1/groups/composition`)
        const response: ProductParams = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }
}
