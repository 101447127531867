import type { ResponseData, CreateItem, PageParams, IFilters } from './store-base-type'
import BaseModule from '~/types/store/store-base'

export default class BasePlugin <T, I extends keyof T, KEYS, Module, FILTERS_KEYS extends keyof Partial<T> = keyof Partial<T>> {
  module
  constructor (module: Module & BaseModule<T, I, KEYS>) {
    this.module = module
  }

  get filters () {
    return this.module.filters
  }

  get list (): ResponseData<T> {
    return this.module.list
  }

  itemByIdentifier (id: T[I]): T | undefined {
    return this.module.itemByIdentifier(id)
  }

  get item (): T {
    return this.module.item
  }

  set filters (filters) {
    this.module.setFilters(filters)
  }

  set list (list: ResponseData<T>) {
    this.module.setList(list)
  }

  set item (item: T) {
    this.module.setItem(item)
  }

  setItemProp <K extends keyof T, V extends T[K]> (key: K, value: V) {
    this.module.setItemProp({ key, value })
  }

  setItemPropByKeys (value: any, ...keys: KEYS[]) {
    this.module.setItemPropByKeys({ keys, value })
  }

  setFilterByKey<K extends FILTERS_KEYS> (
    key: K,
    value: IFilters<T, keyof T>[K]
  ) {
    this.module.setFilterByKey({ key, value })
  }

  resetFilters () {
    this.module.resetFilters()
  }

  resetList () {
    this.module.resetList()
  }

  resetItem () {
    this.module.resetItemData()
  }

  async getList (pageParams?: PageParams<T>, save: boolean = true) {
    const data = await this.module.getList(pageParams)
    if (save) {
      this.module.setList(data)
    }
    return data
  }

  async getItem (identifier: T[I], save: boolean = true) {
    const data = await this.module.getItem(identifier)
    if (save) {
      this.module.setItem(data)
    }
    return data
  }

  createItem (item: CreateItem<T> = this.module.item) {
    return this.module.createItem(item)
  }

  editItem (identifier: T[I], item: T = this.module.item) {
    return this.module.editItem({ identifier, item })
  }

  removeItem (identifier: T[I]) {
    return this.module.removeItem(identifier)
  }
}
