import { Module, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { NGiftsType } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'
import { $axios } from '~/utils/api'
import { ResponseData, FormError, defaultData } from '~/store/interfaces'

@Module({
  name: 'gifts',
  stateFactory: true,
  namespaced: true
})
export default class GiftsModule extends BaseModule<
  NGiftsType.IGiftType,
  'id',
  keyof NGiftsType.IGiftType
> {
  protected config: ModuleConfig<NGiftsType.IGiftType, 'id'> = {
    apiUrl: '/gifts/v1',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_API'
  }

  protected gifts: NGiftsType.IGiftType = {
    code: '',
    id: null,
    name: '',
    typeId: null,
    condition: {
      basketPrice: null,
      productGroupID: null,
      promocode: null
    },
    offerId: null,
    offerCount: 0,
    useOfferRemnants: false,
    override: false,
    priority: 0,
    createdAt: null,
    updatedAt: null
  }

  giftTypes: ResponseData<NGiftsType.IGiftTypeOption> = defaultData

  @Mutation
  SET_GIFT_TYPES (types: ResponseData<NGiftsType.IGiftTypeOption>) {
    this.giftTypes = types
  }

  @Mutation
  resetItem (): void {
    this.gifts = {
      code: '',
      id: null,
      name: '',
      typeId: null,
      condition: {
        basketPrice: null,
        productGroupID: null,
        promocode: null
      },
      offerId: null,
      offerCount: 0,
      useOfferRemnants: false,
      override: false,
      priority: 0,
      createdAt: null,
      updatedAt: null
    }
  }

  @Action({
    rawError: true,
    commit: 'SET_GIFT_TYPES'
  })
  async fetchGiftTypes (): Promise<ResponseData<NGiftsType.IGiftTypeOption>> {
    try {
      const response = await $axios.get(`${this.config.apiUrl}/types`, {
        headers: {
          common: {
            apiUrlPrefixEnv: this.config.apiUrlPrefixEnv
          }
        }
      })
      return response.data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
