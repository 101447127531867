import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { IOrdersStatusesStatistics } from './interfaces'
import { FormError, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'ordersStatusesStatistics',
  stateFactory: true,
  namespaced: true
})
export default class OrdersStatusesStatisticsModule extends VuexModule {
  defaultOrdersStatusesStatisticsData = {
    data: {
      statusGroups: [],
      total: {
        count: 0,
        sum: 0
      },
      totalShipped: {
        count: 0,
        sum: 0
      }
    }
  }

  ordersStatusesStatisticsValue: IOrdersStatusesStatistics = this.defaultOrdersStatusesStatisticsData

  get ordersStatusesStatistics () {
    return this.ordersStatusesStatisticsValue
  }

  get validators (): ValidatorParams {
    return {
      from: [{ required: true, message: 'Введите начальную дату подсчёта статистики', trigger: ['blur', 'change'] }],
      to: [{ required: true, message: 'Введите конечную дату подсчёта статистики', trigger: ['blur', 'change'] }]
    }
  }

  @Mutation
  setOrdersStatusesStatistics (ordersStatusesStatistics: IOrdersStatusesStatistics) {
    this.ordersStatusesStatisticsValue = ordersStatusesStatistics
  }

  @Mutation
  resetOrdersStatusesStatistics () {
    this.ordersStatusesStatisticsValue = this.defaultOrdersStatusesStatisticsData
  }

  @Action({
    rawError: true,
    commit: 'setOrdersStatusesStatistics'
  })
  async getOrdersStatusesStatistics (params: { timePeriod : {from: number, to: number}, siteDomain: string }) {
    const { timePeriod, siteDomain } = params
    const { from, to } = timePeriod
    try {
      const { data } = await $axios.get(`${siteDomain}/api/orders/v1/admin/orders/statuses/statistic`, { params: { from, to } })
      const response: IOrdersStatusesStatistics = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
