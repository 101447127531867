import { Component } from 'nuxt-property-decorator'
import { NOneSignal } from '../interfaces'
import StoreMixin from '~/mixins/store-mixin'
import validatorsPattern from '~/utils/validators'
import { PageParams, ValidatorParams } from '~/types/store/store-base-type'

@Component({
  name: 'OneSignalPushNotificationMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$oneSignal.notification.resetItem()
    this.$oneSignal.notification.resetList()
    this.$oneSignal.notification.resetFilters()
    next()
  }

  validators: ValidatorParams<NOneSignal.IOneSignalCreatedNotification,
          | 'heading'
          | 'content'
          | 'webUrl'
          > = {
      heading: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите заголовок уведомления',
        trigger: ['blur']
      }],
      content: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите текст уведомления',
        trigger: ['blur']
      }],
      webUrl: [{
        required: true,
        message: 'Введите ссылку',
        trigger: ['blur']
      }]
    }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getPushNotifications (
    pageParams?: PageParams<NOneSignal.IOneSignalNotification>,
    save = true
  ) {
    return await this.asyncRequestHandler(
      { loader: 'getPushNotifications' },
      this.$oneSignal.notification.getList(pageParams, save)
    )
  }

  async createPushNotification () {
    await this.asyncRequestHandler(
      { loader: 'createPushNotification', message: () => 'Уведомление добавлено' },
      // :todo only for dev
      // @ts-ignore
      this.$oneSignal.notification.createPushNotification()
    )
  }

  async cancelPushNotification (id: NOneSignal.IOneSignalNotification['id']) {
    return await this.asyncRequestHandler(
      { loader: 'cancelPushNotification', message: () => 'Уведомление отменено' },
      // :todo only for dev
      // @ts-ignore
      this.$oneSignal.notification.createPushNotification()
    )
  }

  async getSubscribers (pageParams: PageParams<NOneSignal.IOneSignalNotification>) {
    const data = await this.asyncRequestHandler(
      { loader: 'getSubscribers' },
      this.$oneSignal.notification.getSubscribers(pageParams)
    )
    return data
  }
}
