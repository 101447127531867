import { Component } from 'nuxt-property-decorator'
import { NChampionshipTeamProfileFields } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'ChampionshipTeamProfileFieldsMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    this.$championship.teamProfileFields.resetItem()
    next()
  }

  getChampionshipTeamProfileFields (pageParams?: PageParams<NChampionshipTeamProfileFields.ITeamProfileField>, save = true) {
    return this.asyncRequestHandler({
      loader: 'getChampionshipTeamProfileFields'
    },
    this.$championship.teamProfileFields.getList(pageParams,
      save)
    )
  }

  getChampionshipTeamProfileField (id: NChampionshipTeamProfileFields.ITeamProfileField['id'], save = true) {
    return this.asyncRequestHandler(
      { loader: 'getChampionshipTeamProfileField' },
      this.$championship.teamProfileFields.getItem(id,
        save)
    )
  }

  createChampionshipTeamProfileField () {
    return this.asyncRequestHandler(
      {
        loader: 'createChampionshipTeamProfileField',
        message: (teamProfileField: NChampionshipTeamProfileFields.ITeamProfileField) => `Поле ${teamProfileField.name} создано`
      },
      this.$championship.teamProfileFields.createItem()
    )
  }

  editChampionshipTeamProfileField (id: NChampionshipTeamProfileFields.ITeamProfileField['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editChampionshipTeamProfileField',
        message: (teamProfileField: NChampionshipTeamProfileFields.ITeamProfileField) => `Поле ${teamProfileField.name} изменено`
      },
      this.$championship.teamProfileFields.editItem(id)

    )
  }

  removeChampionshipTeamProfileField (id: NChampionshipTeamProfileFields.ITeamProfileField['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'removeChampionshipTeamProfileField',
        message: (teamProfileField: NChampionshipTeamProfileFields.ITeamProfileField) => `Поле ${teamProfileField.name} удалено`
      },
      this.$championship.teamProfileFields.removeItem(id)

    )
  }
}
