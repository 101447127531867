import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipAgeType } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipAgeType',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipAgeTypeModule extends BaseModule<
  NChampionshipAgeType.IAgeType,
  'id',
  keyof NChampionshipAgeType.IAgeType
> {
  protected config: ModuleConfig<NChampionshipAgeType.IAgeType, 'id'> = {
    apiUrl: '/championship/age-type',
    identifier: 'id'
  }

  protected itemData: NChampionshipAgeType.IAgeType = {
    name: '',
    code: '',
    sort: 0,
    active: true
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      name: '',
      code: '',
      sort: 0,
      active: true
    }
  }
}
