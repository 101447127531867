
import { Component, Prop, mixins } from 'nuxt-property-decorator'
import DataViewerMixin from '~/mixins/data-viewer'
import AddressSearch from '~/components/ui/address-search/index.vue'
import transformStrToBool from '~/utils/boolean-to-string-transformer'

@Component({
  name: 'UiFormItem',
  components: { AddressSearch },
  mixins: [DataViewerMixin]
})
export default class UiFormItem extends mixins(DataViewerMixin) {
  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
    fields: any

  @Prop({
    type: Object,
    required: false,
    default: () => {}
  })
    rules: any

  @Prop({
    type: Object,
    required: false,
    default: () => {}
  })
    currentStore: any

  @Prop({
    type: String,
    required: false,
    default: () => {}
  })
    currentGetter: any

  @Prop({
    type: Function,
    required: false,
    default: () => {}
  })
    getStringType: any

  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    stringCurrentFields: any

  achievements: any[] = []
  competencies: any[] = []
  locationsBoost: any[] = []
  switchers: Record<string, string> = {
    allowTechMessages: 'Управление оправкой уведомлений для пользователя. Например, письма с регистрацией, письма с заказом, письма с изменением статусов заказа',
    allowTriggerMessages: 'Управление отправкой писем из модуля “Триггерные события”. Например, письма “С днём рождения”, “Давно не заходили на сайт”, письма с автосгенерированными промокодами',
    allowMassMessages: 'Удаление/добавление пользователя в чёрный список модуля рассылки. Данная настройка синхронизируется с активностью записи в блоке “Подписчики”'
  }

  getSwitcherValue (code: string) {
    const value = this.getFields[code]
    if (value === '' || value === undefined) { return code !== 'allowMassMessages' }
    return transformStrToBool(value)
  }

  get getFields () {
    return this.currentStore?.[this.currentGetter] || {}
  }

  get birthDate () {
    const birthDate = this.getFields?.birthDate
    if (birthDate && typeof birthDate === 'string' && birthDate.includes('-')) {
      const [day, month, year] = birthDate.split('-').map(part => parseInt(part, 10))
      // Создать объект Date
      const dateObject = new Date(year, month - 1, day)
      // Получить Unix-время в секундах
      const unixTime = Math.floor(dateObject.getTime())
      return unixTime
    }
    return birthDate
  }

  set birthDate (event: any) {
    const dateObject = new Date(event)
    // Извлечь компоненты даты
    const day = String(dateObject.getDate()).padStart(2, '0')
    const month = String(dateObject.getMonth() + 1).padStart(2, '0') // Месяцы нумеруются с нуля
    const year = dateObject.getFullYear()
    // Форматировать строку даты
    const formattedDate = `${day}-${month}-${year}`
    this.currentStore[this.currentGetter] = { ...this.getFields, birthDate: formattedDate }
  }

  getDynamicValues (type: string) {
    if (type === 'achievements') {
      return this.achievements || []
    }

    if (type === 'competencies') {
      return this.competencies || []
    }

    return []
  }

  onChange (key: string, value: any) {
    const switcherCodes = new Set(Object.keys(this.switchers))
    this.$emit('changeField', { key, value: switcherCodes.has(key) ? String(value) : value })
  }

  async mounted () {
    if (this.fields.find((el: { code: string }) => el.code === 'achievements')) {
      this.achievements = (await this.fetchDataView<any>({
        schema: {
          tableName: this.ETablesNames.GAMIFICATION_ACHIEVEMENTS_VIEW
        }
      }, 'getAchievements'))?.data || []
    }

    if (this.fields.find((el: { code: string }) => el.code === 'competencies')) {
      this.competencies = (await this.$championship.competency.getList())?.data || []
    }
  }
}
