import { Component } from 'nuxt-property-decorator'
import { NRefund } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'RefundMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$refunds.resetItem()
    this.$refunds.resetList()
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getRefund (identifier: NRefund.IRefund['id']) {
    return await this.asyncRequestHandler(
      { loader: 'getRefund' },
      this.$refunds.getItem(
        identifier)
    )
  }

  async getRefunds (pageParams?: PageParams<NRefund.IRefund>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getRefunds' },
      this.$refunds.getList(pageParams, save)
    )
  }

  async getRefundsStatuses () {
    return await this.asyncRequestHandler(
      { loader: 'getRefundsStatuses' },
      this.$refunds.getRefundsStatuses()
    )
  }

  async createOrderFromRefund (identifier: NRefund.IRefund['id']) {
    return await this.asyncRequestHandler(
      // @ts-ignore
      { loader: 'createOrderFromRefund', message: ({ orderId }) => `Претензия переведена в заказ №${orderId}` },
      this.$refunds.createOrderFromRefund(identifier)
    )
  }

  async rejectRefund (identifier: NRefund.IRefund['id']) {
    return await this.asyncRequestHandler(
      { loader: 'rejectRefund', message: () => 'Претензия отклонена' },
      this.$refunds.rejectRefund(identifier)
    )
  }
}
