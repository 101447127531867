import { Module, Mutation } from 'vuex-module-decorators'
import { NGamificationRewards } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'gamificationRewards',
  stateFactory: true,
  namespaced: true
})
export default class GamificationRewardsModule extends BaseModule<
  NGamificationRewards.IReward,
  'id',
  keyof NGamificationRewards.IReward
> {
  protected config: ModuleConfig<NGamificationRewards.IReward, 'id'> = {
    apiUrl: '/gamification/v1/admin/rewards',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_API'
  }

  protected itemData: NGamificationRewards.IReward = {
    active: true,
    completeRequest: {},
    sort: 0,
    title: '',
    typeCode: ''
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      active: true,
      completeRequest: {},
      sort: 0,
      title: '',
      typeCode: ''
    }
  }
}
