
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import UiInputNumber from '~/components/ui/ui-number-input/index.vue'

  @Component({
    name: 'UiRange',
    components: { UiInputNumber }
  })
export default class UiRange extends Vue {
    @Prop({ required: true }) value!: number[]
    @Prop({ required: true }) bounds!: number[]

    @Watch('value')
    onChangeValue (newValue: number[]) {
      this.rangeModelValue = newValue
    }

    @Watch('bounds')
    onChangeBounds (newValue: number[]) {
      this.rangeModelValue = newValue
      this.boundsValue = newValue
    }

    mounted () {
      this.rangeModelValue = this.bounds
      this.boundsValue = this.bounds
    }

    private rangeModelValue: number[] = this.bounds
    private boundsValue: number[] = this.bounds

    onSliderChange (value: number[]) {
      this.$emit('emitFilterChanged', value)
    }
}
