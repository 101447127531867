import { Component } from 'nuxt-property-decorator'
import { NOneSignal } from '../interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams, ValidatorParams } from '~/types/store/store-base-type'
import validatorsPattern from '~/utils/validators'

@Component({
  name: 'OneSignalPushNotificationTemplateMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$oneSignal.template.resetItem()
    this.$oneSignal.template.resetList()
    this.$oneSignal.template.resetFilters()
    next()
  }

  linkWithTemplateRegex = /^https?:\/\/[^\s/$.?#].[^\s]*(\/{{\.[a-zA-Z0-9_]+}})?$/

  validators: ValidatorParams<NOneSignal.IOneSignalCreatedTemplate,
            | 'code'
            | 'content'
            | 'heading'
            | 'link'
            > = {
      code: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите код шаблона',
        trigger: ['blur']
      }],
      content: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите текст уведомления',
        trigger: ['blur']
      }],
      heading: [{
        required: true,
        message: 'Введите заголовок',
        trigger: ['blur']
      }],
      link: [
        {
          required: true,
          message: 'Введите ссылку',
          trigger: ['blur']
        },
        {
          pattern: this.linkWithTemplateRegex,
          message: 'Введите корректную ссылку',
          trigger: ['blur']
        }
      ]
    }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getPushNotificationTemplates (
    pageParams?: PageParams<NOneSignal.IOneSignalTemplate>,
    save = true
  ) {
    return await this.asyncRequestHandler(
      { loader: 'getPushNotificationTemplates' },
      this.$oneSignal.template.getList(pageParams, save)
    )
  }

  async createPushNotificationTemplate () {
    return await this.asyncRequestHandler(
      { loader: 'createPushNotificationTemplate', message: id => `Шаблон уведомления №${id} добавлен` },
      this.$oneSignal.template.createPushNotificationTemplate()
    )
  }

  async editPushNotificationTemplate (id: NOneSignal.IOneSignalTemplate['id']) {
    return await this.asyncRequestHandler(
      { loader: 'editPushNotificationTemplate', message: () => `Шаблон уведомления №${id} изменен` },
      this.$oneSignal.template.editPushNotificationTemplate(id)
    )
  }

  async removePushNotificationTemplate (id: NOneSignal.IOneSignalTemplate['id']) {
    return await this.asyncRequestHandler(
      { loader: 'removePushNotificationTemplate', message: () => `Шаблон уведомления №${id} удалён` },
      this.$oneSignal.template.removeItem(id)
    )
  }

  async getStatuses () {
    return await this.asyncRequestHandler(
      { loader: 'getStatuses' },
      this.$oneSignal.template.getStatuses()
    )
  }

  async editStatus (params: {statusCode: NOneSignal.IOneSignalStatus['code'], templateId: NOneSignal.IOneSignalTemplate['id']}) {
    return await this.asyncRequestHandler(
      { loader: 'editStatus' },
      this.$oneSignal.template.editStatus(params)
    )
  }
}
