import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError } from '../../interfaces'
import { Filters } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'basicReport',
  stateFactory: true,
  namespaced: true
})

export default class BasicReportModule extends VuexModule {
  basicReportValue: any = {}

  filtersValue: Filters = {
    dateFrom: undefined,
    dateTo: undefined,
    clientPlatformId: undefined,
    userType: undefined,
    userRoles: undefined
  }

  configValue = {
    statusCodes: [
      {
        code: 'otmenyon',
        name: 'Отмененные заказы'
      },
      {
        code: 'obedinenie_zakazov',
        name: 'Объединенные заказы'
      },
      {
        code: 'testirovanie',
        name: 'Тестовые заказы'
      }
    ],
    statusesOrder: [
      'otmenyon', 'obedinenie_zakazov', 'testirovanie'
    ],
    statusesToCountPercent: [
      {
        code: 'percent_testirovanie',
        name: 'Процент отмененных заказов (Кол)',
        statusCode: 'testirovanie'
      },
      {
        code: 'percent_otmenyon',
        name: 'Процент отмененных заказов (Кол)',
        statusCode: 'otmenyon'
      }
    ],
    statusesWithExcludes: [
      {
        code: 'without_testirovanie',
        excludeFromTotal: [
          'testirovanie'
        ],
        name: 'Итог без учета тестов'
      },
      {
        code: 'without_testirovanie_obedinenie',
        excludeFromTotal: [
          'testirovanie', 'obedinenie_zakazov'
        ],
        name: 'Итог без учета тестов и объединений'
      },
      {
        code: 'without_testirovanie_otmenyon',
        excludeFromTotal: [
          'testirovanie', 'otmenyon'
        ],
        name: 'Итог без учета тестов и отмен'
      },
      {
        code: 'without_testirovanie_otmenyon_obedinenie',
        excludeFromTotal: [
          'testirovanie', 'otmenyon', 'obedinenie_zakazov'
        ],
        name: 'Итог без учета тестов, отмен и объединений'
      }
    ]
  }

  // ? ______________ getters ______________

  get basicReport (): any {
    return this.basicReportValue
  }

  /**
   * * Получить фильтры групп статусов
   */
  get filters (): Filters {
    return this.filtersValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить значение тикета
   * @param filters - значение тикета
   */
  @Mutation
  setBasicReportValue (val: any) {
    this.basicReportValue = val
  }

  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

    /**
   * * Сбросить значения фильтров
   */
    @Mutation
  resetFilters () {
    this.filtersValue = {}
  }

    // ? ______________________________________actions______________________________________

  @Action({
    rawError: true,
    commit: 'setBasicReportValue'
  })
    async getBasicReport ({
      siteApiUrl
    }: {
    siteApiUrl: string;
  }): Promise<any> {
      try {
        const { data } = await $axios.post(
        `${siteApiUrl}/orders/v1/admin/basic-report`,
        { ...this.configValue, ...this.filtersValue, userRoles: this.filtersValue.userRoles?.split(',') }
        )
        return data.data
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

    @Action({
      rawError: true
    })
  async getBasicReportXlsx ({
    siteApiUrl
  }: {
      siteApiUrl: string;
    }): Promise<any> {
    try {
      const { data } = await $axios.post(
          `${siteApiUrl}/orders/v1/admin/basic-report/xlsx`,
          { ...this.configValue, ...this.filtersValue, userRoles: this.filtersValue.userRoles?.split(',') },
          {
            responseType: 'blob' // Загружаем как бинарные данные
          }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
