import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipAction } from '../interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipAction',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipActionsModule extends BaseModule<
  NChampionshipAction.IAction,
  'id',
  keyof NChampionshipAction.IAction
> {
  protected config: ModuleConfig<NChampionshipAction.IAction, 'id'> = {
    apiUrl: '/championship/action',
    identifier: 'id'
  }

  protected itemData: NChampionshipAction.IAction = {
    id: 0,
    name: '',
    description: '',
    previewId: 0,
    roles: [],
    createdAt: 0,
    updatedAt: 0,
    fields: [],
    code: '',
    showAfterComplete: false
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      id: 0,
      name: '',
      description: '',
      previewId: 0,
      roles: [],
      createdAt: 0,
      updatedAt: 0,
      fields: [],
      code: '',
      showAfterComplete: false
    }
  }
}
