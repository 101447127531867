import { Context } from '@nuxt/types'
import { FormError, defaultData, ResponseData, PageParams, ValidatorParams } from '../../interfaces'
import { Filters, Group, GroupsPlugin, ProductParams } from './interfaces'

import GroupsParamsModule from './store'

export default function getPluginApi (store: GroupsParamsModule) {
  const ctx: Context = this
  const api: GroupsPlugin = {
    get filters (): Filters {
      return store.filters
    },

    get groups (): ResponseData<Group> {
      return store.groups
    },

    get group () {
      return store.group
    },

    get productParams () {
      return store.productParams
    },

    // ? ________ setters ________
    set filters (filters: Filters) {
      store.setFilters(filters)
    },
    set groups (groups: ResponseData<Group>) {
      store.setGroupsValue(groups)
    },
    set group (group: any) {
      store.setGroupValue(group)
    },

    set productParams (val: any) {
      store.setProductParamsValue(val)
    },

    resetGroup () {
      store.resetGroupValue()
    },
    resetFilters () {
      store.resetFilters()
    },

    resetProductParamsValue () {
      store.resetProductParamsValue()
    },

    // ? ________ actions ________
    getGroups (pageParams?: PageParams): Promise<Group[]> {
      return store.getGroups({ siteApiUrl: ctx.$config.SITE_API, pageParams })
    },

    getGroup (id: number): Promise<Group> {
      return store.getGroup({ siteApiUrl: ctx.$config.SITE_API, id })
    },

    createGroup (group: Group): Promise<Group> {
      return store.createGroup({ siteApiUrl: ctx.$config.SITE_API, group })
    },

    editGroup (id: number, group: Group): Promise<Group> {
      return store.editGroup({ siteApiUrl: ctx.$config.SITE_API, id, group })
    },

    deleteGroup (id: number): Promise<Group> {
      return store.deleteGroup({ siteApiUrl: ctx.$config.SITE_API, id })
    },

    uploadFile (file: any): Promise<ProductParams> {
      return store.uploadFile({ siteApiUrl: ctx.$config.SITE_API, file })
    },

    getProductParams (id: number, pageParams?: any): Promise<ProductParams[]> {
      return store.getProductParams({ siteApiUrl: ctx.$config.SITE_API, id, pageParams })
    },

    editProductParams (id: number, params: any): Promise<ProductParams> {
      return store.editProductParams({ siteApiUrl: ctx.$config.SITE_API, params, id })
    },

    updateProductParams (): Promise<ProductParams> {
      return store.updateProductParams({ siteApiUrl: ctx.$config.SITE_API })
    }
  }
  return api
}
