import { Context } from '@nuxt/types'
import { BasicReportPlugin, Filters } from './interfaces'
import BasicReportModule from './store'

export default function getPluginApi (this: Context, basicReportStore: BasicReportModule) {
  const ctx: Context = this
  const api: BasicReportPlugin = {
    get filters (): Filters {
      return basicReportStore.filters
    },

    get basicReport () {
      return basicReportStore.basicReport
    },

    // ? ________ setters ________
    set filters (filters: Filters) {
      basicReportStore.setFilters(filters)
    },

    resetFilters () {
      basicReportStore.resetFilters()
    },

    set basicReport (val: any) {
      basicReportStore.setBasicReportValue(val)
    },

    // ? ________ actions ________
    getBasicReport: function (): Promise<any> {
      return basicReportStore.getBasicReport({ siteApiUrl: ctx.$config.SITE_API })
    },
    getBasicReportXlsx: function (): Promise<Blob> {
      return basicReportStore.getBasicReportXlsx({ siteApiUrl: ctx.$config.SITE_API })
    }
  }
  return api
}
