import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { ProductOffer, Params, Ids, Filters } from './interfaces'
import { defaultData, ResponseData, FormError, ValidatorParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'productOffers',
  stateFactory: true,
  namespaced: true
})

export default class ProductOffersModule extends VuexModule {
  /**
   * * Значения фильтров
   */
  filtersValue: Filters = {
    active: undefined
  }

  onlyRealOffersValue: boolean = true

  /**
   * * Список торговых предложений
   */
  productOffersValue: ResponseData<ProductOffer> = defaultData

  /**
   * * Торговое предложение
   */
  productOfferValue: ProductOffer = {
    active: true,
    title: '',
    minOrderCount: 1,
    barcode: '',
    height: 0,
    weight: 0,
    width: 0,
    length: 0,
    descr: '',
    remnants: [],
    options: [],
    offerGroupId: null,
    prices: []
  }

  // ? ______________ getters ______________

  /**
   * * Получить значения фильтров
   */
  get filters (): Filters {
    return this.filtersValue
  }

  get onlyRealOffers (): boolean {
    return this.onlyRealOffersValue
  }

  /**
   * * Получить список торговых предложений
   */
  get productOffers (): ResponseData<ProductOffer> {
    return this.productOffersValue
  }

  /**
   * * Получить торговое предложение
   */
  get productOffer (): ProductOffer {
    return this.productOfferValue
  }

  /**
   * * Получить торговое предложение по id
   */
  get productOfferById () {
    const productOffers = this.productOffers.data
    return function (id: number): ProductOffer | undefined {
      return productOffers.find(productOffer => productOffer.id === id)
    }
  }

  /**
   * * валидна ли форма
   */
  get validators (): ValidatorParams {
    return {
      height: [{ required: true, pattern: validatorsPattern.notWholeNumbers, message: 'Введите положительное число', trigger: ['blur'] }],
      weight: [{ required: true, pattern: validatorsPattern.notWholeNumbers, message: 'Введите положительное число', trigger: ['blur'] }],
      width: [{ required: true, pattern: validatorsPattern.notWholeNumbers, message: 'Введите положительное число', trigger: ['blur'] }],
      length: [{ required: true, pattern: validatorsPattern.notWholeNumbers, message: 'Введите положительное число', trigger: ['blur'] }],
      minOrderCount: [{ required: true, pattern: validatorsPattern.naturalNumbers, message: 'Введите натуральное число', trigger: ['blur'] }],
      barcode: [{ pattern: /\d+/, message: 'Введите корректный штрихкод', trigger: ['blur'] }],
      pricesFildes: {
        siteId: [{ required: true, message: 'Выберите сайт', trigger: ['blur'] }],
        roleName: [{ required: true, message: 'Выберите роль', trigger: ['blur'] }],
        currencyId: [{ required: true, message: 'Выберите валюту', trigger: ['blur'] }],
        price: [{
          pattern: validatorsPattern.prices,
          message: 'Введите корректную цену',
          trigger: ['change', 'blur']
        }]
      },
      remnantCollapse: {
        siteId: [{ required: true, message: 'Выберите сайт', trigger: ['blur'] }],
        remnants: [{
          pattern: validatorsPattern.prices,
          message: 'Введите корректный остаток',
          trigger: ['change', 'blur']
        }]
      }
    }
  }

  // ? ______________ setters ______________

  /**
   * * Установить значения фильтров
   * @param filters - значение фильтров
   */
  @Mutation
  setOnlyRealOffers (val: boolean) {
    this.onlyRealOffersValue = val
  }

  @Mutation
  setFilters (filters: Filters) {
    this.filtersValue = filters
  }

  /**
   * * Сбросить значения фильтров
   */
  @Mutation
  resetFilters () {
    this.filtersValue = {
      active: undefined
    }
  }

  /**
   * * Установить список торговых предложений
   * @param productOffers - список торговых предложений и пагинатор
   */
  @Mutation
  setProductOffers (productOffers: ResponseData<ProductOffer>) {
    this.productOffersValue = productOffers
  }

  /**
   * * Сбросить список торговых предложений
   */
  @Mutation
  resetProductOffers () {
    this.productOffersValue = defaultData
  }

  /**
   * * Установить торговое предложение
   * @param productOffer
   */
  @Mutation
  setProductOffer (productOffer: ProductOffer) {
    this.productOfferValue = productOffer
  }

  /**
   * * Сбросить торговое предложение
   */
  @Mutation
  resetProductOffer () {
    this.productOfferValue = {
      active: true,
      title: '',
      minOrderCount: 1,
      barcode: '',
      height: 0,
      weight: 0,
      width: 0,
      length: 0,
      descr: '',
      remnants: [],
      options: [],
      offerGroupId: null,
      prices: []
    }
  }

  // ? ______________ actions ______________

  /**
   * * Запрос на получение списка торговых предложений
   * @param params - параметры запроса
   * @returns - список торговых предложений и пагинатор
   */
  @Action({
    rawError: true,
    commit: 'setProductOffers'
  })
  async getProductOffers (params: Params): Promise<ResponseData<ProductOffer>> {
    try {
      const { productId, ...pageParams } = params
      const { data } = await $axios.get(`/shop/products/${productId}/offers`, { params: { ...pageParams, ...this.filters } })
      const response: ResponseData<ProductOffer> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на создание торгового предложения
   * @param productId - id товара
   * @returns - торговое предложение
   */
  @Action({
    rawError: true
  })
  async createProductOffer (productId: number): Promise<ProductOffer> {
    try {
      const { data: { data } } = await $axios.post(`/shop/products/${productId}/offers`, this.productOffer)
      const response: ProductOffer = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение торгового предложения по id
   * @param ids - id`s товара и торгового предложения
   * @returns - торговое предложение
   */
  @Action({
    rawError: true,
    commit: 'setProductOffer'
  })
  async getProductOfferById (ids: Ids): Promise<ProductOffer> {
    try {
      const { data: { data } } = await $axios.get(`/shop/products/${ids.productId}/offers/${ids.offerId}`)
      const response: ProductOffer = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на изменение торгового предложения
   * @param productId - id товара
   * @returns - торговое предложение
   */
  @Action({
    rawError: true
  })
  async editProductOffer (productId: number): Promise<ProductOffer> {
    try {
      const { id, ...newOffer } = this.productOffer
      const { data: { data } } = await $axios.put(`/shop/products/${productId}/offers/${id}`, newOffer)
      const response: ProductOffer = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на удаление торгового предложения
   * @param ids - id`s товара и торгового предложения
   * @returns - торговое предложение
   */
  @Action({
    rawError: true
  })
  async removeProductOffer (ids: Ids): Promise<ProductOffer> {
    try {
      const { data: { data } } = await $axios.delete(`/shop/products/${ids.productId}/offers/${ids.offerId}`)
      const response: ProductOffer = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
