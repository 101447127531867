import { Component } from 'nuxt-property-decorator'
import { NChampionshipAction } from '../interfaces'
import StoreMixin from '~/mixins/store-mixin'
import validatorsPattern from '~/utils/validators'
import { PageParams, ValidatorParams } from '~/types/store/store-base-type'

@Component({
  name: 'ChampionshipActionMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$championship.action.resetItem()
    this.$championship.action.resetList()
    this.$championship.action.resetFilters()
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getChampionshipActionList (
    pageParams?: PageParams<NChampionshipAction.IAction>,
    save = true
  ) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipActionList' },
      this.$championship.action.getList(pageParams, save)
    )
  }

  async getChampionshipActionItem (
    id: NChampionshipAction.IAction['id'],
    save = true
  ) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipActionItem' },
      this.$championship.action.getItem(id, save)
    )
  }

  async createChampionshipActionItem (item?: NChampionshipAction.IAction) {
    return await this.asyncRequestHandler(
      { loader: 'createChampionshipActionItem', message: ({ name }) => `Действие ${name} изменено` },
      this.$championship.action.createItem(item)
    )
  }

  async editChampionshipActionItem (
    id: NChampionshipAction.IAction['id'],
    item?: NChampionshipAction.IAction
  ) {
    return await this.asyncRequestHandler(
      { loader: 'editChampionshipActionItem' },
      this.$championship.action.editItem(id, item)
    )
  }

  async removeChampionshipActionItem (id: NChampionshipAction.IAction['id']) {
    return await this.asyncRequestHandler(
      { loader: 'removeChampionshipActionItem', message: ({ name }) => `Действие ${name} добавление` },
      this.$championship.action.removeItem(id)
    )
  }

  championshipActionValidators: ValidatorParams<
    NChampionshipAction.IAction,
    'name' | 'description' | 'code' | 'previewId' | 'roles'
  > = {
      name: [
        {
          required: true,
          pattern: validatorsPattern.stringEmpty,
          message: 'Введите название',
          trigger: ['blur']
        }
      ],
      description: [
        {
          required: true,
          pattern: validatorsPattern.stringEmpty,
          message: 'Введите описание',
          trigger: ['blur']
        }
      ],
      code: [
        {
          required: true,
          pattern: validatorsPattern.stringEmpty,
          message: 'Введите код',
          trigger: ['blur']
        }
      ],
      previewId: [
        {
          required: true,
          pattern: validatorsPattern.naturalNumbers,
          message: 'Загрузите изображение',
          trigger: ['blur']
        }
      ],
      roles: [
        {
          required: true,
          type: 'array',
          message: 'Выберите роли',
          trigger: ['change']
        }
      ]
    }
}
