import { Component, mixins } from 'nuxt-property-decorator'
import { NAchievements } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { ORDER, PageParams } from '~/types/store/store-base-type'
import DataViewerMixin from '~/mixins/data-viewer'
import pageSizes from '~/utils/page-sizes'
import { convertKeysToCamelCase } from '~/utils/snake-camel-cases'

@Component({
  name: 'AchievementsMixin',
  mixins: [StoreMixin, DataViewerMixin]
})
export default class extends mixins(StoreMixin, DataViewerMixin) {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$achievements.resetItem()
    this.$achievements.resetList()
    this.$achievements.resetFilters()
    next()
  }

  async getAchievements (pageParams?: PageParams<NAchievements.IAchievement>) {
    return await this.asyncRequestHandler(
      { loader: 'getAchievements' },
      (async () => {
        const data = await this.fetchDataView<any>(
          {
            schema: {
              tableName: this.ETablesNames.GAMIFICATION_ACHIEVEMENTS_VIEW,
              page: +(pageParams?.page || 1),
              pageSize: +(pageParams?.pageSize || pageSizes.default),
              sortBy: (pageParams?.sort || 'id') as keyof NAchievements.IAchievement,
              sortDir: (pageParams?.order || ORDER.ASC) as any
            }
          },
          'getAchievements'
        )

        data.data = convertKeysToCamelCase(data.data)

        this.$achievements.list = data
      })()
    )
  }

  async getAchievementById (id: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getAchievementById' },
      this.$achievements.getItem(id, save)
    )
  }

  async removeAchievementById (id: number) {
    return await this.asyncRequestHandler(
      { loader: 'removeAchievementById' },
      this.$achievements.removeItem(id)
    )
  }

  async saveAchievement (id?: number) {
    return await this.asyncRequestHandler(
      { loader: 'saveAchievement' },
      id
        ? this.$achievements.editItem(id)
        : this.$achievements.createItem()
    )
  }

  setAchievementItemProp<K extends keyof NAchievements.IAchievement> ({ key, value }: { key: K, value: NAchievements.IAchievement[K] }) {
    this.$achievements.setItemProp(key, value)
  }

  async updateAchievementFormItemProp ({ key, value, id } : { key: keyof NAchievements.IAchievement, value: number, id: number }) {
    const temp = this.$achievements.itemByIdentifier(id)

    if (!temp) { return }

    this.$achievements.item = { ...temp, [key]: value }

    await this.saveAchievement(id)

    this.$achievements.resetItem()

    this.$fetch()
  }
}
