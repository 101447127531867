import { Context } from '@nuxt/types'
import CalculationsModule from './store'
import { CalculationsPlugin, CalculateRequestParams, StaticCalculateRequestParams, RecalculateRequestParams, RecalculateDeliveryRequestParams, CalculationResponse } from './interfaces'

export default function getPluginApi (store: CalculationsModule) {
  const ctx: Context = this

  const api: CalculationsPlugin = {

    get calculation (): CalculationResponse {
      return store.calculation
    },

    get calculationManual (): any {
      return store.calculationManual
    },

    set calculationManual (calculationManualValue: any) {
      store.setCalculationManual(calculationManualValue)
    },

    set calculation (calculationValue: CalculationResponse) {
      store.setCalculation(calculationValue)
    },

    resetCalculation (): void {
      store.resetCalculation()
    },

    calculateByAddress (requestParams: CalculateRequestParams): Promise<CalculationResponse> {
      return store.calculateByAddress({ siteApiUrl: ctx.$config.SITE_API, requestParams })
    },

    calculateByDeliveryPoint (requestParams: CalculateRequestParams): Promise<CalculationResponse> {
      return store.calculateByDeliveryPoint({ siteApiUrl: ctx.$config.SITE_API, requestParams })
    },

    calculateManualByDeliveryPoint (requestParams: any): Promise<any> {
      return store.calculateManualByDeliveryPoint({ siteApiUrl: ctx.$config.SITE_API, requestParams })
    },

    calculateManualByAddress (requestParams: any): Promise<any> {
      return store.calculateManualByAddress({ siteApiUrl: ctx.$config.SITE_API, requestParams })
    },

    calculateByStatic (requestParams: StaticCalculateRequestParams): Promise<CalculationResponse> {
      return store.calculateByStatic({ siteApiUrl: ctx.$config.SITE_API, requestParams })
    },

    recalculateDelivery (requestParams: RecalculateDeliveryRequestParams): Promise<void> {
      return store.recalculateDelivery({ siteApiUrl: ctx.$config.SITE_API, requestParams })
    },

    recalculate (requestParams: RecalculateRequestParams): Promise<void> {
      return store.recalculate({ siteApiUrl: ctx.$config.SITE_API, requestParams })
    }
  }

  return api
}
