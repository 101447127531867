import { Vue, Component } from 'nuxt-property-decorator'
import {
  NewQuestionForm,
  UpdateQuestionFormType,
  UserAnswerForm
} from './interfaces'
import { PageParams } from '~/store/interfaces'
import pageSizes from '~/utils/page-sizes'

@Component
export default class SurveysMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  mounted () {
    this.$wait.end('leaveRouter')
  }

  getPageParams (defaultPageParams: Partial<PageParams> = {}): PageParams {
    return {
      page: +(this.$route.query.page ?? 1),
      pageSize: +(this.$route.query.pageSize ?? pageSizes.default),
      order: (this.$route.query.order || defaultPageParams.order || 'ASC') as 'ASC' | 'DESC',
      sort: (this.$route.query.sort || defaultPageParams.sort || 'id') as string
    }
  }

  handleNotifyError (error: any) {
    console.error(error)
    this.$notify({
      type: 'error',
      title: error.error_code,
      message: error.error_message
    })
  }

  getCurrentSurveyById (id: number) {
    return this.$surveys.surveys.data?.find(survey => survey.id === id)
  }

  getCurrentSurveyByCode (code: string) {
    return this.$surveys.surveys.data?.find(survey => survey.code === code)
  }

  async getSurveys (pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getSurveys')
      return await this.$surveys.getSurveys(pageParams)
    } catch (e: any) {
      this.handleNotifyError(e)
      throw e
    } finally {
      this.$wait.end('getSurveys')
    }
  }

  async addSurvey (titleSurvey: string, active: boolean) {
    try {
      this.$wait.start('addSurvey')
      return await this.$surveys.addSurvey(titleSurvey, active)
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('addSurvey')
    }
  }

  async updateSurvey ({
    surveyId,
    titleSurvey,
    active
  }: {
    surveyId: number
    titleSurvey?: string
    active?: boolean
  }) {
    try {
      this.$wait.start('updateSurvey')
      return await this.$surveys.updateSurvey({ surveyId, titleSurvey, active })
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('updateSurvey')
    }
  }

  async deleteSurvey (surveyId: number) {
    try {
      this.$wait.start('deleteSurvey')
      return await this.$surveys.deleteSurvey(surveyId)
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('deleteSurvey')
    }
  }

  async getQuestions (code: string, pageParams: PageParams | null = null) {
    try {
      this.$wait.start('getQuestions')
      return await this.$surveys.getQuestions(code, pageParams)
    } catch (e: any) {
      this.handleNotifyError(e)
      throw e
    } finally {
      this.$wait.end('getQuestions')
    }
  }

  async addNewQuestion (code: string, questionForm: NewQuestionForm) {
    try {
      this.$wait.start('addNewQuestion')
      return await this.$surveys.addNewQuestion(code, questionForm)
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('addNewQuestion')
    }
  }

  async updateQuestion (id: number, questionForm: UpdateQuestionFormType) {
    try {
      this.$wait.start('updateQuestion')
      return await this.$surveys.updateQuestion(id, questionForm)
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('updateQuestion')
    }
  }

  async deleteQuestion (questionId: number) {
    try {
      this.$wait.start('deleteQuestion')
      return await this.$surveys.deleteQuestion(questionId)
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('deleteQuestion')
    }
  }

  async getUserAnswers (surveyId: number, orderId: number, pageParams?: PageParams) {
    try {
      this.$wait.start('getUserAnswers')
      return await this.$surveys.getUserAnswers(surveyId, orderId, pageParams)
    } catch (e: any) {
      this.handleNotifyError(e)
      throw e
    } finally {
      this.$wait.end('getUserAnswers')
    }
  }

  async saveUserAnswers (surveyId: number, answers: UserAnswerForm[]) {
    try {
      this.$wait.start('saveUserAnswers')
      return await this.$surveys.saveUserAnswers(surveyId, answers)
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('saveUserAnswers')
    }
  }

  async updateUserAnswers (surveyId: number, answers: UserAnswerForm[]) {
    try {
      this.$wait.start('updateUserAnswers')
      return await this.$surveys.updateUserAnswers(surveyId, answers)
    } catch (e: any) {
      this.handleNotifyError(e)
    } finally {
      this.$wait.end('updateUserAnswers')
    }
  }
}
