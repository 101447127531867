export default {
  stringEmpty: /^(\S+\s*)*$/,
  emptyStringEmpty: /^(\s*\S+\s*)*$/,
  idArrayInput: /^((?:\d+,)*\d+|)$/,
  fioInput: /^[a-zA-ZА-яЁё ]+$/,
  naturalNumbers: /^[0-9]+$/,
  wholeNumbers: /^-?[0-9]+$/,
  notWholeNumbers: /^[0-9]+[.]?[0-9]*$/,
  prices: /^[0-9]+[.|,]?[0-9]{0,2}$/,
  color: /^#[0-9A-F]{6}$/,
  email: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
  cities: /^[A-ЯЁ][А-Яа-яЁё\s\-_]+$/,
  floatFrom1To99: /^(([1-8][0-9]?|9[0-8]?)\.\d+|[1-9][0-9]?)$/,
  intFrom1: /[1-9][0-9]*$/,
  regionCode: /^[0-9]{2}$/,
  link: /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*\/?$/
}
