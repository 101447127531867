import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class BasicReportMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$basicReport.resetFilters()
    next()
  }

  async getBasicReport () {
    try {
      this.$wait.start('getBasicReport')
      return await this.$store.dispatch('basicReport/getBasicReport')
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getBasicReport')
    }
  }
}
