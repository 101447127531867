import { NGamificationTasks } from './interfaces'
import GamificationTasksModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class GamificationTasksPlugin extends BasePlugin<
  NGamificationTasks.ITask,
  'id',
  keyof NGamificationTasks.ITask,
  GamificationTasksModule
> {}
