import {
  SurveysPlugin,
  SurveyTemplate,
  SurveyQuestion,
  UserAnswerData,
  NewQuestionForm,
  UpdateQuestionFormType,
  UserAnswerForm,
  ResponseUserAnswer
} from './interfaces'
import { ResponseData, ValidatorParams, PageParams } from '~/store/interfaces'

export default function getPluginApi (surveysStore: any): SurveysPlugin {
  const api: SurveysPlugin = {
    get surveys (): ResponseData<SurveyTemplate> {
      return surveysStore.surveys
    },
    get questions (): ResponseData<SurveyQuestion> {
      return surveysStore.questions
    },
    get validators (): ValidatorParams {
      return surveysStore.validators
    },
    get userAnswers (): ResponseUserAnswer {
      return surveysStore.userAnswers
    },
    async getSurveys (
      pageParams: PageParams | null = null
    ): Promise<ResponseData<SurveyTemplate>> {
      return await surveysStore.getSurveys({ pageParams })
    },
    async addSurvey (
      titleSurvey: string,
      active: boolean
    ): Promise<ResponseData<SurveyTemplate>> {
      return await surveysStore.addSurvey({ titleSurvey, active })
    },
    async updateSurvey ({
      surveyId,
      titleSurvey,
      active
    }: {
      surveyId: number
      titleSurvey?: string
      active?: boolean
    }): Promise<ResponseData<SurveyTemplate>> {
      return await surveysStore.updateSurvey({ surveyId, titleSurvey, active })
    },
    async deleteSurvey (
      surveyId: number
    ): Promise<ResponseData<SurveyTemplate>> {
      return await surveysStore.deleteSurvey({ surveyId })
    },
    async getQuestions (
      code: string,
      pageParams: PageParams | null = null
    ): Promise<ResponseData<SurveyQuestion>> {
      return await surveysStore.getQuestions({ code, pageParams })
    },
    async addNewQuestion (
      code: string,
      questionForm: NewQuestionForm
    ): Promise<ResponseData<SurveyQuestion>> {
      return await surveysStore.addNewQuestion({ code, questionForm })
    },
    async updateQuestion (
      id: number,
      questionForm: UpdateQuestionFormType
    ): Promise<ResponseData<SurveyQuestion>> {
      return await surveysStore.updateQuestion({ id, questionForm })
    },
    async deleteQuestion (
      questionId: number
    ): Promise<ResponseData<SurveyQuestion>> {
      return await surveysStore.deleteQuestion({ questionId })
    },
    async getUserAnswers (
      surveyId: number,
      orderId: number,
      pageParams?: PageParams | null
    ): Promise<ResponseUserAnswer> {
      return await surveysStore.getUserAnswers({ surveyId, orderId, pageParams })
    },
    async saveUserAnswers (
      surveyId: number,
      answers: UserAnswerForm[]
    ): Promise<UserAnswerData> {
      return await surveysStore.saveUserAnswers({ surveyId, answers })
    },
    async updateUserAnswers (
      surveyId: number,
      answers: UserAnswerForm[]
    ): Promise<UserAnswerData> {
      return await surveysStore.updateUserAnswers({ surveyId, answers })
    }
  }
  return api
}
