
import Vue from 'vue'
import { Component, Watch } from 'nuxt-property-decorator'
import { menuItems } from '~/utils/routes'
import { ConfigIds } from '~/store/modules/config/interfaces'

@Component
export default class SideBar extends Vue {
  /**
   * *________________ Data ______________________
   */
  isCollapse: boolean | null = null
  menuItem = menuItems
  title = this.$config.SITE_NAME

  /**
   * *________________ Watch ______________________
   */
  @Watch('$route.path')
  onChangePage () {
    // @ts-ignore
    this.$refs.menu.openedMenus = []
    if (this.isCollapse) { return }
    menuItems.map(({ item }) => {
      const regexp = new RegExp(`^/${item.link}`)
      if (regexp.test(this.$route.path)) {
        // @ts-ignore
        this.$refs.menu.openedMenus = [item.link]
      }
    })
  }

  /**
   * *________________ Created ______________________
   */
  created () {
    this.isCollapse = this.$cookies.get('menuCollapsed')
  }
  /**
     * *________________ Computed ______________________
     */

  get pagesConfig () {
    return this.$configsList.clientConfig[ConfigIds['pages-options']]
  }

  get menuModules () {
    return this.menuItem.map(module => ({
      ...module,
      subItems: (module.subItems as any[])?.filter((item: any) => this.checkPermsForPage(item, `${module.item.link}/${item.link}`)) || undefined
    })).filter(module => !module.subItems ? this.checkPermsForPage(module.item, module.item.link) : module.subItems.length)
  }

  /**
   * *________________ Mounted ______________________
   */
  mounted () {
    // @ts-ignore
    this.$refs.menu.openedMenus = []
    if (this.isCollapse) { return }
    menuItems.map(({ item }) => {
      const regexp = new RegExp(`^/${item.link}`)
      if (regexp.test(this.$route.path)) {
        // @ts-ignore
        this.$refs.menu.openedMenus = [item.link]
      }
    })
  }
  /**
   * *________________ Methods ______________________
   */

  checkPermsForPage (item: any, link: string) {
    return (this.$config.ADMIN_ROLES.includes(this.$auth.profile.role.name) ||
     (this.pagesConfig?.rolesPages?.[this.$auth.role.name]
       ? this.pagesConfig?.rolesPages?.[this.$auth.role.name].includes(link)
       : !item.perms?.[0] || (this.$auth.role.permissions.some((permission: string) => permission.startsWith(`${item.perms?.[0]}`))))) &&
          !this.pagesConfig?.banList?.includes(link)
  }

  activeStyle (link: string) {
    const regexp = new RegExp(`^/${link}`)
    if (regexp.test(this.$route.path)) {
      return 'primaryColor'
    }
    return ''
  }

  changeCollapse () {
    this.isCollapse = !this.isCollapse
    this.$cookies.set('menuCollapsed', this.isCollapse)
    this.$emit('changeSideBar', this.isCollapse)
  }
}
