interface PageItem {
  value: string,
  link: string,
  perms?: string[],
  icon?: string
}

export const feedsItems: PageItem[] = [
  {
    value: 'Список фидов',
    link: 'feeds-list',
    perms: ['feed']
  }
]
export const staticPagesItems: PageItem[] = [
  {
    value: 'Шаблоны',
    link: 'templates',
    perms: ['shop.page_templates', 'shop.pages.list']
  },
  {
    value: 'Страницы',
    link: 'pages',
    perms: ['shop.pages', 'core.sites.list', 'shop.price_roles.list']
  }
]
export const shopItems: PageItem[] = [
  {
    value: 'Товары',
    link: 'products',
    perms: ['shop.products', 'shop.categories.list', 'shop.price_roles.list', 'shop.stickers.list', 'core.sites.list', 'core.currencies.list', 'shop.options.list', 'shop.partners.list', 'seo.template.list', 'shop.offers_product.list']
  },
  {
    value: 'Справочники',
    link: 'options',
    perms: ['shop.options', 'shop.price_roles.list', 'shop.categories.list']
  },
  {
    value: 'Заказы',
    link: 'orders',
    perms: ['shop.orders', 'shop.order_statuses.list', 'shop.order_status_group.list', 'payments.payment_types.list', 'core.sites.list', 'core.roles.list']
  },
  {
    value: 'Калькулятор',
    link: 'calculate',
    perms: ['delivery.groups', 'core.config.list']
  },
  {
    value: 'Список пользователей',
    link: 'users',
    perms: ['core.users', 'core.roles.list', 'bonus.account.list']
  },
  {
    value: 'Базвый отчет',
    link: 'basic-report',
    perms: ['core.roles.list']
  },
  {
    value: 'Категории',
    link: 'categories',
    perms: ['shop.categories']
  },
  {
    value: 'Стикеры',
    link: 'stickers',
    perms: ['shop.stickers']
  },
  {
    value: 'Корзины',
    link: 'carts',
    perms: ['shop.basket']
  },
  {
    value: 'Группы товаров',
    link: 'product-groups',
    perms: ['shop.product_groups', 'shop.options.list', 'shop.stickers.list', 'discounts.discount_groups.list', 'shop.categories.list']
  },
  {
    value: 'Группы скидок',
    link: 'discount-groups',
    perms: ['discounts.discount_groups', 'core.sites.list', 'core.currencies.list', 'shop.product_groups.list', 'shop.price_roles.list', 'shop.price_role_assignment.list']
  },
  {
    value: 'Группы доставок',
    link: 'delivery-groups',
    perms: ['delivery.groups']
  },
  {
    value: 'Бонусы',
    link: 'bonuses',
    perms: ['bonus.bonus', 'bonus.type', 'bonus.account', 'shop.order_status_group.list', 'user_type.list']
  },
  {
    value: 'Бренды',
    link: 'partners',
    perms: ['shop.partners', 'shop.options.list']
  },
  {
    value: 'Промокоды',
    link: 'promocodes',
    perms: ['shop.promo_codes', 'shop.product_groups.list']
  },
  {
    value: 'Произвольные фильтры',
    link: 'custom-filters',
    perms: ['shop.custom_filters']
  },
  {
    value: 'Файлы выгрузки',
    link: 'upload-files',
    perms: ['feed']
  },
  {
    value: 'Подарки',
    link: 'gifts',
    perms: ['gifts']
  }
]
export const settingsItems: PageItem[] = [
  {
    value: 'Конфиг (для разработчиков)',
    link: 'dev-config',
    perms: ['core.config']
  },
  {
    value: 'Настройки сайта',
    link: 'site-config',
    perms: ['core.config', 'shop.options', 'chat_channel']
  },
  // {
  //   value: 'Настройки страниц',
  //   link: 'pages-config',
  //   perms: [],
  //   hidden: true
  // },
  {
    value: 'Настройки задач',
    link: 'tasks-config',
    perms: ['task.statuses', 'task.user_options', 'task.options', 'core.roles.list']
  },
  {
    value: 'Настройки почтовых уведомлений',
    link: 'email-env-config',
    perms: []
  },
  {
    value: 'Валюты',
    link: 'currencies',
    perms: ['core.currencies', 'currency_source.index']
  },
  {
    value: 'Сайты',
    link: 'sites',
    perms: ['core.sites']
  },
  {
    value: 'Ценовые роли',
    link: 'price-roles',
    perms: ['shop.price_roles', 'core.roles.list', 'shop.price_role_assignment', 'shop.price_role_params']
  },
  {
    value: 'Роли',
    link: 'roles',
    perms: ['core.roles', 'core.permissions.list']
  },
  {
    value: 'Типы пользователей',
    link: 'user-types',
    perms: ['user_type', 'shop.order_status_group.list']
  },
  {
    value: 'Статусы',
    link: 'status',
    perms: []
  },
  {
    value: 'Менеджеры',
    link: 'managers',
    perms: ['managers']
  },
  {
    value: 'Произвольные параметры товаров',
    link: 'group-params',
    perms: []
  },
  {
    value: 'Статусы заказов',
    link: 'status-orders',
    perms: ['shop.order_status_group', 'shop.order_statuses']
  },
  {
    value: 'Шаблоны опросов',
    link: 'survey-templates',
    perms: ['surveys']
  },
  {
    value: 'Регионы',
    link: 'regions',
    perms: ['points.regions', 'points.points.list']
  },
  {
    value: 'Магазины на карте',
    link: 'shops',
    perms: ['points.points', 'points.regions.list']
  },
  {
    value: 'Способы оплаты',
    link: 'payments',
    perms: ['payments.payment_types', 'shop.price_role.list']
  },
  {
    value: 'Дилеры',
    link: 'dealers',
    perms: ['points.points', 'points.regions.list']
  },
  {
    value: 'Сотрудники',
    link: 'personnel',
    perms: ['core.config']
  },
  {
    value: 'Настройки почты',
    link: 'email-config',
    perms: ['core.config']
  }
]
export const seoItems: PageItem[] = [
  {
    value: 'Поисковая оптимизация',
    link: 'pages',
    perms: ['seo.pages', 'core.sites.list']
  },
  {
    value: 'Поисковые запросы',
    link: 'search-logs',
    perms: ['search_log']
  },
  {
    value: 'Шаблоны',
    link: 'templates',
    perms: ['seo.template', 'shop.categories.list']
  },
  {
    value: 'Склонение слов',
    link: 'wordcase',
    perms: ['seo.word_case']
  },
  {
    value: 'Склонение слов по числу',
    link: 'wordplural',
    perms: ['seo.word_plural']
  },
  {
    value: 'Склонение слов по родам',
    link: 'wordadjective',
    perms: ['seo.word_adjective']
  },
  {
    value: 'Замена слов',
    link: 'wordreplace',
    perms: ['seo.word_replace']
  },
  {
    value: 'Редактировать Robots.txt',
    link: 'robots',
    perms: ['seo.robots']
  }
]
export const reviewItems: PageItem[] = [
  {
    value: 'Список отзывов',
    link: 'list',
    perms: ['reviews']
  },
  {
    value: 'Типы отзывов',
    link: 'type',
    perms: ['reviews']
  },
  {
    value: 'Типы параметров отзывов',
    link: 'param-type',
    perms: ['reviews']
  }
]
export const formItems: PageItem[] = [
  {
    value: 'Список форм',
    link: 'list',
    perms: ['form', 'form_type.list', 'form_params.list']
  },
  {
    value: 'Типы формы',
    link: 'type',
    perms: ['form_type', 'form_params.list', 'email_templates.list']
  },
  {
    value: 'Типы параметров формы',
    link: 'param-type',
    perms: ['form_type']
  }
]
export const sendingItems: PageItem[] = [
  {
    value: 'Группы шаблонов',
    link: 'email-template-groups',
    perms: ['email_template.groups']
  },
  {
    value: 'Шаблоны писем',
    link: 'email-templates',
    perms: ['email_templates', 'email_template.groups.list']
  },
  {
    value: 'Подписчики',
    link: 'subscribers',
    perms: ['mass_mailing']
  },
  {
    value: 'Отправка рассылок',
    link: 'newsletters',
    perms: []
  }
]
export const statisticsItems: PageItem[] = [
  {
    value: 'Статусы заказов отчет',
    link: 'orders-statuses-list',
    perms: []
  },
  {
    value: 'Покупатели',
    link: 'buyers',
    perms: ['shop.order_statuses', 'shop.order_status_group.list']
  }
]
export const EventTriggers: PageItem[] = [
  {
    value: 'Триггер',
    link: 'triggers',
    perms: ['email_templates']
  }
  // {
  //   value: 'Шаблон письма',
  //   link: 'templates',
  //   code: ''
  // }
]

export const Championships: PageItem[] = [
  {
    value: 'Наши чемпионаты',
    link: 'championship',
    perms: ['championship.championship', 'championship.city.index', 'championship.stages.index', 'championship.age_group.index']
  },
  {
    value: 'Настройки чемпионатов',
    link: 'settings',
    perms: ['championship.age_group', 'championship.city', 'championship.team_profile_fields', 'championship.age_type']
  },
  {
    value: 'Список компетенций',
    link: 'competencies',
    perms: ['championship.competency', 'championship.age_group.index', 'championship.city.index', 'championship.age_type.index']
  },
  {
    value: 'Настройки этапов',
    link: 'stages',
    perms: ['championship.stages', 'championship.team_profile_fields.index', 'championship.city.index', 'championship.action.index', 'form_type.list', 'core.roles.list']
  },
  {
    value: 'Список действий ролей',
    link: 'action',
    perms: ['championship.action', 'core.roles.list', 'form_type.list']
  },
  {
    value: 'Настройка доступов ролей',
    link: 'permission',
    perms: ['championship.permissions', 'core.roles.list']
  }
]

export const Calculators: PageItem[] = [
  {
    value: 'Калькуляторы',
    link: 'calculators',
    perms: ['calculators.calculators', 'calculators.types.index']
  },
  {
    value: 'Типы калькуляторов',
    link: 'types',
    perms: ['calculators.types']
  }
]

export const Notifications: PageItem[] = [
  {
    value: 'Отправка уведомлений',
    link: 'send-notifications',
    perms: []
  },
  {
    value: 'Шаблоны уведомлений',
    link: 'notification-templates',
    perms: []
  },
  {
    value: 'Настройки полей',
    link: 'notification-field-settings',
    perms: []
  }
]

export const pushNotifications: PageItem[] = [
  {
    value: 'OneSignal',
    link: 'push-notifications-onesignal',
    perms: []
  },
  {
    value: 'Шаблоны уведомлений',
    link: 'push-notifications-templates',
    perms: []
  }
]

export const Gamification = [
  {
    value: 'Список заданий',
    link: 'tasks',
    perms: []
  },
  {
    value: 'Награды',
    link: 'rewards',
    perms: []
  },
  {
    value: 'Дополнительные поля заданий',
    link: 'additional-tasks-fields',
    perms: []
  }
]

export const WebsiteAssistantPages: PageItem[] = [
  {
    value: 'Список страниц с помощью',
    link: 'assistant-pages',
    perms: ['website_assistant', 'core.users.list', 'form_type.list']
  }
]

export const menuItems = [
  {
    item: {
      value: 'Настройки',
      icon: 'el-icon-setting',
      link: 'settings'
    },
    subItems: settingsItems
  },
  {
    item: {
      value: 'Магазин',
      icon: 'el-icon-goods',
      link: 'shop'
    },
    subItems: shopItems
  },
  {
    item: {
      value: 'SEO',
      icon: 'el-icon-discover',
      link: 'seo'
    },
    subItems: seoItems
  },
  {
    item: {
      value: 'Статистика',
      icon: 'el-icon-data-analysis',
      link: 'statistics'
    },
    subItems: statisticsItems
  },
  {
    item: {
      value: 'Отзывы',
      icon: 'el-icon-chat-line-square',
      link: 'review'
    },
    subItems: reviewItems
  },
  {
    item: {
      value: 'Формы обратной связи',
      icon: 'el-icon-service',
      link: 'form'
    },
    subItems: formItems
  },
  {
    item: {
      value: 'Фиды',
      icon: 'el-icon-document-copy',
      link: 'feeds'
    },
    subItems: feedsItems
  },
  {
    item: {
      value: 'Рассылки',
      icon: 'el-icon-message',
      link: 'sending'
    },
    subItems: sendingItems
  },
  {
    item: {
      value: 'Чемпионаты',
      icon: 'el-icon-trophy',
      link: 'championships'
    },
    subItems: Championships
  },
  {
    item: {
      value: 'Контент',
      icon: 'el-icon-picture-outline',
      link: 'banner-block',
      perms: ['banner_blocks', 'core.sites.list', 'banners', 'shop.price_roles.list', 'shop.options.list', 'shop.categories.list', 'core.roles.list']
    }
  },
  {
    item: {
      value: 'Виджеты',
      icon: 'el-icon-film',
      link: 'widgets',
      perms: ['shop.product_widgets', 'core.sites.list', 'shop.price_roles.list', 'shop.product_groups.list']
    }
  },
  {
    item: {
      value: 'Лист ожидания',
      icon: 'el-icon-bell',
      link: 'list-waiting',
      perms: ['shop.shop_offer_notify']
    }
  },
  {
    item: {
      value: 'Новости',
      icon: 'el-icon-notebook-2',
      link: 'news',
      perms: ['news', 'core.sites.list', 'news_group']
    }
  },
  {
    item: {
      value: 'Статические страницы',
      icon: 'el-icon-document',
      link: 'pages'
    },
    subItems: staticPagesItems
  },
  {
    item: {
      value: 'Синонимы для поиска',
      icon: 'el-icon-search',
      link: 'search-text-replace',
      perms: ['search_text_replace', 'shop.options.list']
    }
  },
  {
    item: {
      value: 'Коллекции',
      icon: 'el-icon-collection',
      link: 'collections',
      perms: ['shop.collections', 'shop.options.list']
    }
  },
  {
    item: {
      value: 'Розыгрыши',
      icon: 'el-icon-present',
      link: 'raffles',
      perms: []
    }
  },
  {
    item: {
      value: 'Триггерные события',
      icon: 'el-icon-alarm-clock',
      link: 'event-triggers'
    },
    subItems: EventTriggers
  },
  {
    item: {
      value: 'Калькуляторы',
      icon: 'el-icon-set-up',
      link: 'calculators'
    },
    subItems: Calculators
  },
  {
    item: {
      value: 'Рассылка уведомлений',
      icon: 'el-icon-message',
      link: 'notifications'
    },
    subItems: Notifications
  },
  {
    item: {
      value: 'Push-уведомления',
      icon: 'el-icon-s-promotion',
      link: 'push-notifications'
    },
    subItems: pushNotifications
  },
  {
    item: {
      value: 'Геймификация',
      icon: 'el-icon-monitor',
      link: 'gamification'
    },
    subItems: Gamification
  },
  {
    item: {
      value: 'Сложные задания',
      icon: 'el-icon-odometer',
      link: 'difficult-tasks'
    },
    subItems: [{
      value: 'Список сложных заданий',
      link: 'list'
    }]
  },
  {
    item: {
      value: 'Помощник на сайте',
      icon: 'el-icon-question',
      link: 'website-assistant'
    },
    subItems: WebsiteAssistantPages
  }
]
