import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipTeamProfileFields } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipTeamProfileFields',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipTeamProfileFieldsModule extends BaseModule<
  NChampionshipTeamProfileFields.ITeamProfileField,
  'id',
  keyof NChampionshipTeamProfileFields.ITeamProfileField
> {
  protected config: ModuleConfig<NChampionshipTeamProfileFields.ITeamProfileField, 'id'> = {
    apiUrl: '/championship/team-profile-field',
    identifier: 'id'
  }

  protected itemData: NChampionshipTeamProfileFields.ITeamProfileField = {
    name: '',
    code: '',
    sort: 0,
    active: true,
    type: '',
    params: {}
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      name: '',
      code: '',
      sort: 0,
      active: true,
      type: '',
      params: {}
    }
  }
}
