export const CHAMPIONSHIP_ROLES = ['teacher', 'expert', 'student']

export declare namespace NChampionshipAction {
  export interface IField {
    code: string;
    name: string;
    type: string;
    params: Record<string, unknown>
  }
  export interface IAction {
    id?: number;
    name: string;
    code: string;
    description: string;
    previewId: number;
    roles: string[];
    createdAt?: number;
    updatedAt?: number;
    fields: IField[];
    showAfterComplete: boolean;
  }
}
