import { Component } from 'nuxt-property-decorator'
import StoreMixin from '../store-mixin'
import { EArrayOp, EBoolOp, EOp, ESortDir, ETablesNames, NDataViewer } from '~/types/data-viewer'

@Component({
  name: 'DataViewerMixin'
})
export default class DataViewerMixin extends StoreMixin {
  ETablesNames = ETablesNames
  ESortDir = ESortDir
  EOp = EOp
  EArrayOp = EArrayOp
  EBoolOp = EBoolOp

  async fetchDataView<T>(params: { schema: NDataViewer.IRequestSchema<T>, format?: NDataViewer.TDataFormat }, loader?: string): Promise<NDataViewer.IResponse<T>>;
  // eslint-disable-next-line no-dupe-class-members
  async fetchDataView<T>(params: { schema: NDataViewer.IRequestSchema<T>, format?: NDataViewer.TDataFormat }, loader?: string): Promise<Blob>;
  // eslint-disable-next-line no-dupe-class-members
  async fetchDataView<T> (
    params: { schema: NDataViewer.IRequestSchema<T>, format?: NDataViewer.TDataFormat },
    loader: string = 'fetchDataView'
  ): Promise<NDataViewer.IResponse<T> | Blob> {
    return await this.asyncRequestHandler(
      { loader },
      this.$dataViewer.fetch(params.schema, params.format) as Promise<NDataViewer.IResponse<T> | Blob>
    )
  }
}
