import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import {
  SurveyTemplate,
  SurveyQuestion,
  NewQuestionForm,
  UpdateQuestionFormType,
  UserAnswerData,
  UserAnswerForm,
  ResponseUserAnswer
} from './interfaces'

import { $axios } from '~/utils/api'
import {
  ResponseData,
  PageParams,
  FormError,
  ValidatorParams,
  defaultData,
  defaultPaginator
} from '~/store/interfaces'
import validatorsPattern from '~/utils/validators'

@Module({
  name: 'surveys',
  stateFactory: true,
  namespaced: true
})
export default class SurveysStore extends VuexModule {
  public surveys: ResponseData<SurveyTemplate> = defaultData
  public questions: ResponseData<SurveyQuestion> = defaultData
  public userAnswers: ResponseUserAnswer = {
    data: {
      questionsWithAnswers: [],
      createdAt: 0,
      templateActive: false,
      id: 0,
      templateId: 0,
      templateTitle: ''
    },
    paginator: defaultPaginator
  }

  protected config = {
    apiUrlPrefixEnv: 'SITE_DOMAIN'
  }

  private baseApiUrl = '/api/surveys/v1'
  private get baseHeaders () {
    return {
      common: {
        apiUrlPrefixEnv: this.config.apiUrlPrefixEnv
      }
    }
  }

  get validators (): ValidatorParams {
    return {
      title: [
        {
          required: true,
          pattern: validatorsPattern.emptyStringEmpty,
          message: 'Введите название опроса',
          trigger: 'blur'
        }
      ],
      questionText: [
        {
          required: true,
          pattern: validatorsPattern.emptyStringEmpty,
          message: 'Введите вопрос',
          trigger: 'blur'
        }
      ],
      answerOptions: [
        {
          validator: (
            rule: any,
            value: string[],
            callback: (error?: Error) => void
          ) => {
            if (!value || value.length < 2) {
              callback(new Error('Введите больше одного варианта'))
            } else if (!value.every(option => !!`${option}`.trim())) {
              callback(new Error('Каждый вариант ответа должен быть заполнен'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ]
    }
  }

  @Mutation
  setSurveys (surveys: ResponseData<SurveyTemplate>) {
    this.surveys = surveys
  }

  @Mutation
  setQuestions (questions: ResponseData<SurveyQuestion>) {
    this.questions = questions
  }

  @Mutation
  setUserAnswers (userAnswers: ResponseUserAnswer) {
    this.userAnswers = userAnswers
  }

  @Action({ rawError: true, commit: 'setSurveys' })
  async getSurveys ({ pageParams }: { pageParams?: PageParams }) {
    try {
      const { data } = await $axios.get(`${this.baseApiUrl}/templates`, {
        params: pageParams,
        headers: this.baseHeaders
      })
      const response: ResponseData<SurveyTemplate> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async addSurvey ({
    titleSurvey,
    active
  }: {
    titleSurvey: string
    active: boolean
  }) {
    try {
      await $axios.post(
        `${this.baseApiUrl}/templates`,
        {
          title: titleSurvey,
          active
        },
        {
          headers: this.baseHeaders
        }
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async updateSurvey ({
    surveyId,
    titleSurvey,
    active
  }: {
    surveyId: number
    titleSurvey?: string
    active?: boolean
  }) {
    try {
      await $axios.put(
        `${this.baseApiUrl}/templates/${surveyId}`,
        {
          title: titleSurvey,
          active
        },
        {
          headers: this.baseHeaders
        }
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async deleteSurvey ({ surveyId }: { surveyId: number }) {
    try {
      await $axios.delete(`${this.baseApiUrl}/templates/${surveyId}`, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true, commit: 'setQuestions' })
  async getQuestions ({
    code,
    pageParams
  }: {
    code: string
    pageParams?: PageParams
  }) {
    try {
      const { data } = await $axios.get(
        `${this.baseApiUrl}/templates/${code}/questions`,
        {
          params: pageParams,
          headers: this.baseHeaders
        }
      )
      const response: ResponseData<SurveyQuestion> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async addNewQuestion ({
    code,
    questionForm
  }: {
    code: string
    questionForm: NewQuestionForm
  }) {
    try {
      await $axios.post(
        `${this.baseApiUrl}/templates/${code}/questions`,
        questionForm,
        {
          headers: this.baseHeaders
        }
      )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async updateQuestion ({
    id,
    questionForm
  }: {
    id: number
    questionForm: UpdateQuestionFormType
  }) {
    try {
      await $axios.put(`${this.baseApiUrl}/questions/${id}`, questionForm, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async deleteQuestion ({ questionId }: { questionId: number }) {
    try {
      await $axios.delete(`${this.baseApiUrl}/questions/${questionId}`, {
        headers: this.baseHeaders
      })
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true, commit: 'setUserAnswers' })
  async getUserAnswers ({
    surveyId,
    orderId,
    pageParams
  }: {
    surveyId: number
    orderId: number
    pageParams?: PageParams
  }) {
    try {
      const { data } = await $axios.get(`${this.baseApiUrl}`, {
        params: { id: surveyId, orderId, sort: 'questionSort', ...(pageParams ?? {}) },
        headers: this.baseHeaders
      })
      const response: UserAnswerData = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async saveUserAnswers ({
    surveyId,
    answers
  }: {
    surveyId: number
    answers: UserAnswerForm[]
  }) {
    try {
      const response = await $axios.post(
        `${this.baseApiUrl}`,
        { templateId: surveyId, answers },
        {
          headers: this.baseHeaders
        }
      )
      return response.data?.data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({ rawError: true })
  async updateUserAnswers ({
    surveyId,
    answers
  }: {
    surveyId: number
    answers: UserAnswerForm[]
  }) {
    try {
      const response = await $axios.put(
        `${this.baseApiUrl}/${surveyId}/answers`,
        { answers },
        {
          headers: this.baseHeaders
        }
      )
      return response.data?.data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
