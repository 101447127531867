import { Module, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { NOneSignal } from '../interfaces'
import { notificationDefaultData, createdNotificationDefaultData, subscribersDefaultData } from '../const'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig, ResponseData } from '~/types/store/store-base-type'
import { FormError, PageParams } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import { defaultData } from '~/types/store/constants'

@Module({
  name: 'oneSignalPushNotification',
  stateFactory: true,
  namespaced: true
})
export default class PushNotificationModule extends BaseModule<
    NOneSignal.IOneSignalNotification,
    'id',
    keyof NOneSignal.IOneSignalNotification
> {
  protected config: ModuleConfig<NOneSignal.IOneSignalNotification, 'id'> = {
    apiUrl: 'api/notifications/v1/onesignal/notifications',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_DOMAIN'
  }

  protected itemData: NOneSignal.IOneSignalNotification = notificationDefaultData
  protected createdNotificationData: NOneSignal.IOneSignalCreatedNotification = createdNotificationDefaultData
  protected subscribersData: ResponseData<NOneSignal.IOneSignalSubscribersResponseData> = defaultData

  get createdNotification () {
    return this.createdNotificationData
  }

  set createdNotification (notificationData) {
    this.createdNotificationData = notificationData
  }

  get subscribersList () {
    return this.subscribersData
  }

  set subscribersList (subscribersData) {
    this.subscribersData = subscribersData
  }

  @Mutation
  resetItemData () {
    this.itemData = notificationDefaultData
  }

  @Mutation
  resetCreatedItemData () {
    this.createdNotificationData = createdNotificationDefaultData
  }

  @Mutation
  setCreatedNotification (notificationData: NOneSignal.IOneSignalCreatedNotification) {
    this.createdNotificationData = notificationData
  }

  @Mutation
  setSubscribers (subscribers: ResponseData<NOneSignal.IOneSignalSubscribersResponseData>) {
    this.subscribersData = subscribers
  }

  @Action({
    rawError: true
  })
  createPushNotification () {
    try {
      // const payload = this.createdNotification

      // await $axios.post<ResponseData<NOneSignal.IOneSignalCreatedNotification>>(
      //   `${this.config.apiUrl}`,
      //   payload,
      //   {
      //     headers: {
      //       common: {
      //         apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
      //       }
      //     }
      //   }
      // )
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true,
    commit: 'setSubscribers'
  })
  async getSubscribers (params: PageParams) {
    try {
      const { data } = await $axios.get<ResponseData<NOneSignal.IOneSignalSubscribersResponseData>>(
        'api/notifications/v1/onesignal/players',
        {
          params: { ...params },
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )

      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
