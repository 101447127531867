import { Component } from 'nuxt-property-decorator'
import { NChampionshipCities } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'ChampionshipCitiesMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    this.$championship.cities.resetItem()
    next()
  }

  getChampionshipCities (pageParams?: PageParams<NChampionshipCities.ICity>, save = true) {
    return this.asyncRequestHandler({
      loader: 'getChampionshipCities'
    },
    this.$championship.cities.getList(pageParams,
      save)
    )
  }

  getChampionshipCity (id: NChampionshipCities.ICity['id'], save = true) {
    return this.asyncRequestHandler(
      { loader: 'getChampionshipCity' },
      this.$championship.cities.getItem(id,
        save)
    )
  }

  createChampionshipCity () {
    return this.asyncRequestHandler(
      {
        loader: 'createChampionshipCity',
        message: (city: NChampionshipCities.ICity) => `Город ${city.name} создан`
      },
      this.$championship.cities.createItem()
    )
  }

  editChampionshipCity (id: NChampionshipCities.ICity['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editChampionshipCity',
        message: (city: NChampionshipCities.ICity) => `Город ${city.name} изменен`
      },
      this.$championship.cities.editItem(id)

    )
  }

  removeChampionshipCity (id: NChampionshipCities.ICity['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'removeChampionshipCity',
        message: (city: NChampionshipCities.ICity) => `Город ${city.name} удален`
      },
      this.$championship.cities.removeItem(id)

    )
  }
}
