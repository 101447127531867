import { Context } from '@nuxt/types'
import { IOrdersStatusesStatistics, IOrdersStatusesStatisticsPlugin } from './interfaces'
import OrdersStatusesStatisticsModule from './store'
import { ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (this: Context, ordersStatusesStatisticsStore: OrdersStatusesStatisticsModule) {
  const ctx = this
  const api: IOrdersStatusesStatisticsPlugin = {
    get ordersStatusesStatistics (): IOrdersStatusesStatistics {
      return ordersStatusesStatisticsStore.ordersStatusesStatistics
    },

    get validators (): ValidatorParams {
      return ordersStatusesStatisticsStore.validators
    },

    resetOrdersStatusesStatistics () {
      ordersStatusesStatisticsStore.resetOrdersStatusesStatistics()
    },

    getOrdersStatusesStatistics (from: number, to: number): Promise<IOrdersStatusesStatistics> {
      const timePeriod = { from, to }
      const params = { timePeriod, siteDomain: ctx.$config.SITE_DOMAIN }

      return ordersStatusesStatisticsStore.getOrdersStatusesStatistics(params)
    }
  }
  return api
}
