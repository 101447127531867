import { Component, mixins } from 'nuxt-property-decorator'
import { NGamificationTasks } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { ORDER, PageParams, ValidatorParams } from '~/types/store/store-base-type'
import validatorsPattern from '~/utils/validators'
import DataViewerMixin from '~/mixins/data-viewer'
import pageSizes from '~/utils/page-sizes'
import { convertKeysToCamelCase, parseStringFromCamelToSnakeCase } from '~/utils/snake-camel-cases'

@Component({
  name: 'GamificationTasksMixin',
  mixins: [StoreMixin, DataViewerMixin]
})
export default class extends mixins(StoreMixin, DataViewerMixin) {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$gamification.tasks.resetItem()
    this.$gamification.tasks.resetList()
    this.$gamification.tasks.resetFilters()
    next()
  }

  validators: ValidatorParams<any,
    'title' |
    'announcement' |
    'rewardId' |
    'filterLinkTemplateId' |
    'role' |
    'championshipId' |
    'registrationType' |
    'ageGroupIds' |
    'championshipToStageId' |
    'competencyIds'
  > = {
      title: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название задания',
        trigger: ['blur']
      }],
      announcement: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите анонс',
        trigger: ['blur']
      }],
      rewardId: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Выберите награду',
        trigger: ['change']
      }],
      filterLinkTemplateId: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Выберите способ добавления получателей',
        trigger: ['change']
      }],
      role: [{
        required: true,
        type: 'array',
        min: 1,
        message: 'Выберите роль',
        trigger: ['blur', 'change']
      }],
      championshipId: [{
        required: true,
        pattern: validatorsPattern.naturalNumbers,
        message: 'Выберите чемпионат',
        trigger: ['change']
      }],
      registrationType: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Выберите тип регистрации',
        trigger: ['change']
      }],
      ageGroupIds: [{
        required: true,
        type: 'array',
        min: 1,
        message: 'Выберите возрастную группу',
        trigger: ['blur', 'change']
      }],
      championshipToStageId: [{
        required: true,
        pattern: validatorsPattern.naturalNumbers,
        message: 'Выберите этап чемпионата',
        trigger: ['change']
      }],
      competencyIds: [{
        required: true,
        type: 'array',
        min: 1,
        message: 'Выберите компетенцию',
        trigger: ['blur', 'change']
      }]
    }

  async getGamificationTasks (pageParams?: PageParams<NGamificationTasks.ITask>) {
    return await this.asyncRequestHandler(
      { loader: 'getGamificationTasks' },
      (async () => {
        const data = await this.fetchDataView<any>(
          {
            schema: {
              tableName: this.ETablesNames.GAMIFICATION_TASKS_VIEW,
              page: +(pageParams?.page || 1),
              pageSize: +(pageParams?.pageSize || pageSizes.default),
              sortBy: parseStringFromCamelToSnakeCase(pageParams?.sort as string || 'id') as keyof NGamificationTasks.ITask,
              sortDir: (pageParams?.order || ORDER.ASC) as any,
              conditions: (pageParams?.conditions?.[0]?.conditions?.length && pageParams?.conditions) || undefined
            }
          },
          'getTasks'
        )

        data.data = convertKeysToCamelCase(data.data)

        this.$gamification.tasks.list = data
      })()
    )
  }

  async getGamificationTaskById (id: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getGamificationTaskById' },
      this.$gamification.tasks.getItem(id, save)
    )
  }

  async removeGamificationTaskById (id: number) {
    return await this.asyncRequestHandler(
      {
        loader: 'removeGamificationTaskById',
        message: (task: NGamificationTasks.ITask) => `Задание ${task.title} удалено`
      },
      this.$gamification.tasks.removeItem(id)
    )
  }

  async saveGamificationTask (id?: number) {
    // const userFilterUrl = this.$gamification.tasks.item.params

    return await this.asyncRequestHandler(
      {
        loader: 'saveGamificationTask',
        message: (task: NGamificationTasks.ITask) => `Задание ${task.title} ${id ? 'изменено' : 'создано'}`
      },
      id
        ? this.$gamification.tasks.editItem(id)
        : this.$gamification.tasks.createItem()
    )
  }

  setGamificationTaskItemProp<K extends keyof NGamificationTasks.ITask> ({ key, value }: { key: K, value: NGamificationTasks.ITask[K] }) {
    this.$gamification.tasks.setItemProp(key, value)
  }

  setGamificationTaskParamsItemProp<K extends keyof NGamificationTasks.ITask> ({ key, value }: { key: K, value: NGamificationTasks.ITask[K] }) {
    const params = { ...this.$gamification.tasks.item.params }

    this.$set(params, key, value)
    this.$gamification.tasks.setItemProp('params', params)
  }

  async updateTaskFormItemProp ({ key, value, id } : { key: keyof NGamificationTasks.ITask, value: number, id: number }) {
    const temp = this.$gamification.tasks.itemByIdentifier(id)

    if (!temp) { return }

    this.$gamification.tasks.item = { ...temp, [key]: value }

    await this.saveGamificationTask(id)

    this.$gamification.tasks.resetItem()

    this.$fetch()
  }

  async updateAdditionalField ({ code, params } : { code: string, params: Record<string, boolean> }) {
    this.$wait.start('updateAdditionalField')
    await this.$gamification.tasks.module.updateAdditionalField({ code, params })
    this.$wait.end('updateAdditionalField')
  }
}
