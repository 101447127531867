import { Component } from 'nuxt-property-decorator'
import { NChampionshipStages } from '../stages/interfaces'
import { NChampionshipChampionships } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams, ValidatorParams } from '~/types/store/store-base-type'
import validatorsPattern from '~/utils/validators'

@Component({
  name: 'ChampionshipChampionshipsMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$championship.championships.resetItem()
    this.$championship.championships.resetList()
    this.$championship.championships.resetFilters()
    next()
  }

  validators: ValidatorParams<NChampionshipChampionships.IChampionship,
      | 'name'
      | 'code'
      | 'type'
      | 'ageGroupIds'
      | 'cityId'
      | 'stages'
      > = {
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название компетенции',
        trigger: ['blur']
      }],
      code: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите код',
        trigger: ['blur']
      }],
      type: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите тип',
        trigger: ['blur']
      }],
      ageGroupIds: [{
        required: true,
        type: 'array',
        message: 'Выберите возрастную группу',
        trigger: ['change']
      }],
      cityId: [{
        required: true,
        message: 'Выберите город',
        trigger: ['change']
      }],
      stages: [{
        required: true,
        type: 'array',
        message: 'Выберите этап',
        trigger: ['change']
      }]
    }

  async getChampionships (pageParams?: PageParams<NChampionshipChampionships.IChampionship>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionships' },
      this.$championship.championships.getList(pageParams, save)
    )
  }

  async getChampionshipById (id: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipById' },
      this.$championship.championships.getItem(id, save)
    )
  }

  async removeChampionshipById (id: number) {
    return await this.asyncRequestHandler(
      { loader: 'removeChampionshipById' },
      this.$championship.championships.removeItem(id)
    )
  }

  async saveChampionship (id?: number) {
    return await this.asyncRequestHandler(
      { loader: 'saveChampionship' },
      id
        ? this.$championship.championships.editItem(id)
        : this.$championship.championships.createItem()
    )
  }

  setChampionshipItemProp<K extends keyof NChampionshipChampionships.IChampionship> ({ key, value }: { key: K, value: NChampionshipChampionships.IChampionship[K] }) {
    if (key === 'stages') {
      const stages = JSON.parse(JSON.stringify(this.$championship.championships.item.stages)) as NChampionshipStages.IStage[]
      const findStages = this.$championship.stages.list.data?.filter((el: NChampionshipStages.IStage) => (value as number[]).includes(el.id as number))

      const updatedStages = (value as number[]).map((id: number) => {
        const existingStage = stages?.find(stage => stage.championshipStageId === id)
        return existingStage || findStages?.map(el => ({ championshipStageId: el.id, sort: 0 }))?.find(stage => stage.championshipStageId === id)
      }).filter(Boolean) as NChampionshipChampionships.IChampionship[K]

      this.$championship.championships.setItemProp(key, updatedStages)
      return
    }

    this.$championship.championships.setItemProp(key, value)
  }

  setChampionshipStageItemProp ({ idx, key, value }: { idx: number, key: keyof NChampionshipStages.IStage, value: any }) {
    const stages = JSON.parse(JSON.stringify(this.$championship.championships.item.stages)) as number[] | NChampionshipStages.IStage[]
    if (!stages.length) { return }

    (stages[idx] as any)[key] = value
    this.$championship.championships.setItemProp('stages', stages)
  }

  async updateFormItemProp ({ key, value, id } : { key: keyof NChampionshipChampionships.IChampionship, value: number, id: number }) {
    const temp = this.$championship.championships.itemByIdentifier(id)

    if (!temp) { return }

    this.$championship.championships.item = { ...temp, [key]: value }

    await this.saveChampionship(id)

    this.$championship.championships.resetItem()

    this.$fetch()
  }
}
