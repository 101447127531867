import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, ResponseData, defaultData, PageParams, ValidatorParams } from '../../interfaces'
import { SeoPage, Filters, LinkTypes } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'seoPages',
  stateFactory: true,
  namespaced: true
})

export default class SeoPagesModule extends VuexModule {
  /**
   * * Массив всех seoPage исходя из запроса
   */
  seoPages: ResponseData<SeoPage> = defaultData

  linkTypesValues:LinkTypes = {
    data: []
  }

  filtersValues: Filters = {
    url: undefined,
    title: undefined,
    description: undefined,
    keywords: undefined,
    h1: undefined,
    type_id: undefined,
    only_equal: undefined,
    is_active: undefined
  }

  /**
   * * Текущая seoPage
   */
  seoPage: SeoPage = {
    url: '',
    typeId: 1,
    title: '',
    created: 0,
    updated: 0,
    isActive: true,
    description: '',
    h1: '',
    ogTitle: '',
    ogDescription: '',
    changeFreq: '',
    priority: 0,
    params: {},
    additionalFields: null,
    metaTags: '[]',
    replaceDescription: false,
    manual: false
  }

  // ? ______________ getters ______________

  /**
   * *  Шаблон валидатора для формы
   */
  get validators (): ValidatorParams {
    return {
      url: [{ required: true, message: 'Введите ссылку', trigger: ['blur'] },
        { pattern: /^(([/]{1}[^\s/]+)*|[/]{1})$/, message: 'Введите корректную ссылку', trigger: ['blur', 'change'] }]
    }
  }

  /**
   * * Получить массив seoPages и пагинацией
   */
  get seoPagesList (): ResponseData<SeoPage> {
    return this.seoPages
  }

  /**
   * * Получить filters
   */
  get filters (): Filters {
    return this.filtersValues
  }

  /**
   * * Получить LinkTypes
   */
  get linkTypes (): LinkTypes {
    return this.linkTypesValues
  }

  /**
   * * Получить seoPage из массива seoPages
   */
  get seoPageById () {
    const seoPages = this.seoPages
    return function (id: number): SeoPage | undefined {
      return seoPages.data.find(seoPage => seoPage.id === id)
    }
  }

  get validateData () {
    return !(this.seoPage.url && this.seoPage.typeId)
  }

  /**
   * * Получить текущую seoPage для измения или создания seoPage
   */
  get currentSeoPage () {
    return this.seoPage
  }

  // ? ______________ setters ______________

  // * Сбросить фильтры
  @Mutation
  resetFilters (): void {
    this.filtersValues = {
      url: undefined,
      title: undefined,
      description: undefined,
      keywords: undefined,
      h1: undefined,
      type_id: undefined,
      only_equal: undefined,
      is_active: undefined
    }
  }

  /**
   * * Установить массив SeoPages
   * @param seoPages массив SeoPages
   */
  @Mutation
  setSeoPagesList (seoPages: ResponseData<SeoPage>) {
    this.seoPages = seoPages
  }

  /**
   * * Установить массив LinkTypes
   * @param linkTypes массив linkTypes
   */
  @Mutation
  setLinkTypes (linkTypes: LinkTypes) {
    this.linkTypesValues = linkTypes
  }

  /**
   * * Установить filters
   */
  @Mutation
  setFilters (filters: Filters) {
    this.filtersValues = filters
  }

  /**
   * * Установить CurrentSeoPage для измения или создания seoPage
   * @param seoPage текущая SeoPage, которую мы изменяем или создаем
   */
  @Mutation
  setCurrentSeoPage (seoPage: SeoPage) {
    this.seoPage = seoPage
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentSeoPage () {
    this.seoPage = {
      url: '',
      typeId: 1,
      title: '',
      created: 0,
      updated: 0,
      isActive: true,
      description: '',
      h1: '',
      ogTitle: '',
      ogDescription: '',
      changeFreq: '',
      priority: 0,
      params: {},
      additionalFields: null,
      metaTags: '',
      replaceDescription: false,
      manual: false
    }
  }

  @Mutation
  resetSeoPages () {
    this.seoPages = defaultData
  }

  @Mutation
  resetLinkTypes () {
    this.linkTypesValues = {
      data: []
    }
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список SeoPages по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setSeoPagesList'
  })
  async getSeoPages (pageParams: PageParams) {
    try {
      const { data } = await $axios.get('/seo/pages', { params: { ...pageParams, ...this.filtersValues } })
      const response: ResponseData<SeoPage> = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список SeoPages по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setLinkTypes'
  })
  async getLinksTypes () {
    try {
      const { data } = await $axios.get('/seo/pages/types')
      const response: LinkTypes = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить seoPage по id
   * @param id id seoPage которую мы хотим получить
   */
  @Action({
    rawError: true,
    commit: 'setCurrentSeoPage'
  })
  async getSeoPage (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/seo/pages/${id}`)
      const response: SeoPage = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Изменить seoPage
   */
  @Action({
    rawError: true
  })
  async editSeoPage () {
    try {
      const { id, ...seoPage } = this.currentSeoPage
      const { data: { data } } = await $axios.put(`/seo/pages/${id}`, seoPage)
      const response: SeoPage = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать SeoPage
   */
  @Action({
    rawError: true
  })
  async createSeoPage () {
    try {
      const { data: { data } } = await $axios.post('/seo/pages', this.currentSeoPage)
      const response: SeoPage = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Удалить seoPage по id
   * @param id  id seoPage которую мы хотим удалить
   */
  @Action({
    rawError: true
  })
  async removeSeoPage (id: number) {
    try {
      const { data: { data } } = await $axios.delete(`/seo/pages/${id}`)
      const response: SeoPage = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Генератор sitemap
   */
  @Action({
    rawError: true
  })
  async generateSiteMap () {
    try {
      await $axios.post('/seo/pages/sitemap')
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Запрос на получение exel таблицы сео поисковой оптимизации
   */
  @Action({
    rawError: true
  })
  async exportExcel (pageParams: PageParams | null = null): Promise<Blob> {
    try {
      const { data } = await $axios.get('/seo/pages/excel', {
        params: {
          ...pageParams,
          ...this.filtersValues
        },
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Генератор sitemap
   */
  @Action({
    rawError: true
  })
  async updateCategories () {
    try {
      await $axios.post('/seo/pages/update-categories')
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  @Action({
    rawError: true
  })
  async updateContent (type: string) {
    try {
      await $axios.post(`/seo/pages/update-static?subtype=${type}`)
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
