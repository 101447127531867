
import { Component, Prop, Watch } from 'nuxt-property-decorator'
import Vue from 'vue'

@Component
export default class AddressSearch extends Vue {
  /**
   * *________________ Props ______________________
   */
  @Prop({
    type: String,
    required: false,
    default: () => ''
  })
    address!: string

  @Prop({
    type: String,
    required: false,
    default: 'Введите адрес'
  })
    placeholder!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    onlyCity!: Boolean

  @Prop({
    type: Boolean,
    required: false,
    default: true
  })
    clearSearch!: Boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    disabled!: Boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false
  })
    fetchOrg!: boolean

  @Prop({
    type: Array,
    required: false,
    default: () => []
  })
    locationsBoost!: any[]

  /**
   * *________________ Data ______________________
   */
  searchAddress = ''

  /**
   * *________________ Watch ______________________
   */
  @Watch('address')
  onChangeAddress (value: string) {
    this.searchAddress = value || ''
  }

  /**
   * *________________ Created ______________________
   */
  created () {
    this.searchAddress = this.address || ''
  }

  /**
   * *________________ Methods ______________________
   */

  async suggestAddress (queryString: any, cb: any) {
    try {
      const suggests = !this.fetchOrg ? await this.$dadata.fetch(queryString) : await this.$dadata.organization(queryString, JSON.stringify(this.locationsBoost))
      cb(suggests)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        title: 'Error',
        message: e.message,
        type: 'error'
      })
    }
  }
}
