import { WebsiteAssistantPlugin, WebsiteAssistantPage, WebsiteAssistantPageForm, WebsiteAssistantQuestionForm, UpdateAssistantPageParams, UpdateAssistantQuestionParams } from './interfaces'
import { PageParams } from '~/store/interfaces'

export default function getPluginApi (websiteAssistantStore: any) {
  const api: WebsiteAssistantPlugin = {
    get websiteAssistantPages () {
      return websiteAssistantStore.websiteAssistantPages
    },

    get currentWebsiteAssistantPage () {
      return websiteAssistantStore.currentWebsiteAssistantPage
    },

    get websiteAssistantQuestions () {
      return websiteAssistantStore.websiteAssistantQuestions
    },
    get currentWebsiteAssistantQuestion () {
      return websiteAssistantStore.currentWebsiteAssistantQuestion
    },
    get validators () {
      return websiteAssistantStore.validators
    },
    async fetchWebsiteAssistantPageByID (
      id: string
    ): Promise<WebsiteAssistantPage> {
      return await websiteAssistantStore.fetchWebsiteAssistantPageByID(id)
    },

    async deleteWebsiteAssistantPageByID (id: number) {
      return await websiteAssistantStore.deleteWebsiteAssistantPageByID(id)
    },

    async createWebsiteAssistantPage (data: WebsiteAssistantPageForm) {
      return await websiteAssistantStore.createWebsiteAssistantPage(data)
    },
    async updateWebsiteAssistantPageByID (id: number, params: UpdateAssistantPageParams) {
      return await websiteAssistantStore.updateWebsiteAssistantPageByID({ id, params })
    },

    async fetchWebsiteAssistantQuestions (pagesAssistantId: number, params: PageParams) {
      return await websiteAssistantStore.fetchWebsiteAssistantQuestions({ pagesAssistantId, params })
    },
    async fetchWebsiteAssistantQuestionByID (id:number) {
      return await websiteAssistantStore.fetchWebsiteAssistantQuestionByID(id)
    },
    async createWebsiteAssistantQuestion (id: number, data: WebsiteAssistantQuestionForm) {
      return await websiteAssistantStore.createWebsiteAssistantQuestion({ id, data })
    },
    async updateWebsiteAssistantQuestionByID (id: number, params: UpdateAssistantQuestionParams) {
      return await websiteAssistantStore.updateWebsiteAssistantQuestionByID({ id, params })
    },
    async deleteWebsiteAssistantQuestionByID (id: number) {
      return await websiteAssistantStore.deleteWebsiteAssistantQuestionByID(id)
    }
  }
  return api
}
