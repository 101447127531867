import { Component } from 'nuxt-property-decorator'
import { NChampionshipCompetency } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams, ValidatorParams } from '~/types/store/store-base-type'
import validatorsPattern from '~/utils/validators'

@Component({
  name: 'ChampionshipCompetencyMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$championship.competency.resetItem()
    this.$championship.competency.resetList()
    this.$championship.competency.resetFilters()
    next()
  }

  validators: ValidatorParams<
    NChampionshipCompetency.ICompetency,
    'name' | 'code' | 'ageGroupIds' | 'ageTypeIds' | 'cities'
  > = {
      name: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите название компетенции',
        trigger: ['blur']
      }],
      code: [{
        required: true,
        pattern: validatorsPattern.stringEmpty,
        message: 'Введите код',
        trigger: ['blur']
      }],
      ageGroupIds: [{
        required: true,
        type: 'array',
        message: 'Выберите возрастную группу',
        trigger: ['change']
      }],
      ageTypeIds: [{
        required: true,
        type: 'array',
        message: 'Выберите тип возраста',
        trigger: ['change']
      }],
      cities: [{
        required: true,
        type: 'array',
        message: 'Выберите хотя бы один город',
        trigger: ['change']
      }]
    }

  async getChampionshipCompetencies (pageParams?: PageParams<NChampionshipCompetency.ICompetency>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipCompetencies' },
      this.$championship.competency.getList(pageParams, save)
    )
  }

  async getChampionshipCompetencyById (id: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipCompetencyById' },
      this.$championship.competency.getItem(id, save)
    )
  }

  async removeChampionshipCompetencyById (id: number) {
    return await this.asyncRequestHandler(
      { loader: 'removeChampionshipCompetencyById' },
      this.$championship.competency.removeItem(id)
    )
  }

  async saveChampionshipCompetency (id?: number) {
    return await this.asyncRequestHandler(
      { loader: 'saveChampionshipCompetency' },
      id
        ? this.$championship.competency.editItem(id)
        : this.$championship.competency.createItem()
    )
  }

  setChampionshipCompetencyItemProp<K extends keyof NChampionshipCompetency.ICompetency> ({ key, value }: { key: K, value: NChampionshipCompetency.ICompetency[K] }) {
    this.$championship.competency.setItemProp(key, value)
  }

  async updateFormItemProp ({ key, value, id } : { key: keyof NChampionshipCompetency.ICompetency, value: number, id: number }) {
    const temp = this.$championship.competency.itemByIdentifier(id)

    if (!temp) { return }

    this.$championship.competency.item = { ...temp, [key]: value }

    await this.saveChampionshipCompetency(id)

    this.$championship.competency.resetItem()

    this.$fetch()
  }
}
