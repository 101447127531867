import PushNotificationModule from './push-notification/store'
import PushNotificationPlugin from './push-notification/plugin'
import PushNotificationTemplateModule from './push-notification-template/store'
import PushNotificationTemplatePlugin from './push-notification-template/plugin'

export { PushNotificationModule as OneSignalPushNotificationModule }
export { PushNotificationTemplateModule as OneSignalPushNotificationTemplateModule }

export class OneSignalPlugin {
  notification: PushNotificationPlugin
  template: PushNotificationTemplatePlugin

  constructor (
    pushNotificationModule: PushNotificationModule,
    pushNotificationTemplateModule: PushNotificationTemplateModule
  ) {
    this.notification = new PushNotificationPlugin(pushNotificationModule)
    this.template = new PushNotificationTemplatePlugin(pushNotificationTemplateModule)
  }
}

export default function getOneSignalApi (
  pushNotificationModule: PushNotificationModule,
  pushNotificationTemplateModule: PushNotificationTemplateModule
) {
  return new OneSignalPlugin(
    pushNotificationModule,
    pushNotificationTemplateModule
  )
}
