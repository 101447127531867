import { Component } from 'nuxt-property-decorator'
import { NChampionshipAgeType } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'ChampionshipAgeTypeMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  async getChampionshipAgeTypes (pageParams?: PageParams<NChampionshipAgeType.IAgeType>, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipAgeTypes' },
      this.$championship.ageType.getList(pageParams, save)
    )
  }

  async getChampionshipAgeTypeById (id: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipAgeTypeById' },
      this.$championship.ageType.getItem(id, save)
    )
  }

  createChampionshipAgeType () {
    return this.asyncRequestHandler(
      {
        loader: 'createChampionshipAgeType',
        message: (ageType: NChampionshipAgeType.IAgeType) => `Тип возраста ${ageType.name} создана`
      },
      this.$championship.ageType.createItem()
    )
  }

  editChampionshipAgeType (id: NChampionshipAgeType.IAgeType['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editChampionshipAgeType',
        message: (ageType: NChampionshipAgeType.IAgeType) => `Тип возраста ${ageType.name} изменена`
      },
      this.$championship.ageType.editItem(id)

    )
  }

  async removeChampionshipAgeTypeById (id: NChampionshipAgeType.IAgeType['id']) {
    return await this.asyncRequestHandler(
      { loader: 'getChampionshipAgeTypeById' },
      this.$championship.ageType.removeItem(id)
    )
  }
}
