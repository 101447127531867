import { RolesPlugin, Role } from './interfaces'
import RolesModule from './store'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (rolesStore: RolesModule) {
  const api: RolesPlugin = {
  // ? ________ getters ________

    get roles (): ResponseData<Role> {
      return rolesStore.roles
    },

    get role (): Role {
      return rolesStore.role
    },

    get rolesPages (): Record<string, string[]> | null {
      return rolesStore.rolesPages
    },

    get validators (): ValidatorParams {
      return rolesStore.validators
    },

    roleByName (name: string): Role | undefined {
      return rolesStore.roleByName(name)
    },

    // ? ________ setters ________

    set roles (roles: ResponseData<Role>) {
      rolesStore.setRoles(roles)
    },

    set role (role: Role) {
      rolesStore.setRole(role)
    },

    set rolesPages (config: Record<string, string[]> | null) {
      rolesStore.setRolesPages(config)
    },

    resetRole () {
      rolesStore.resetRole()
    },

    resetRoles () {
      rolesStore.resetRoles()
    },

    // ? ________ actions ________

    getRoles (pageParams: PageParams | null = null): Promise<ResponseData<Role>> {
      return rolesStore.getRoles(pageParams)
    },

    getRoleByName (name: string): Promise<Role> {
      return rolesStore.getRoleByName(name)
    },

    createRole () {
      return rolesStore.createRole()
    },

    editRole () {
      return rolesStore.editRole()
    },

    removeRole (name: string) {
      return rolesStore.removeRole(name)
    }
  }

  return api
}
