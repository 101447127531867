import { NChampionshipTeamProfileFields } from './interfaces'
import ChampionshipTeamProfileFieldsModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class TeamProfileFieldsPlugin extends BasePlugin<
  NChampionshipTeamProfileFields.ITeamProfileField,
  'id',
  keyof NChampionshipTeamProfileFields.ITeamProfileField,
  ChampionshipTeamProfileFieldsModule
> {}
