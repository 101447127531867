import { NChampionshipPermission } from './interfaces'
import championshipPermissionModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export default class ChampionshipPermissionPlugin extends BasePlugin<
    NChampionshipPermission.IPermission,
    'id',
    keyof NChampionshipPermission.IPermission,
    championshipPermissionModule
> {}
