import Vue from 'vue'
import { NOneSignal } from '../interfaces'
import PushNotificationModule from './store'
import BasePlugin from '~/types/store/plugin-base'
import { PageParams, ResponseData } from '~/store/interfaces'

export default class PushNotificationPlugin extends BasePlugin<
  NOneSignal.IOneSignalNotification,
  'id',
  keyof NOneSignal.IOneSignalNotification,
  PushNotificationModule
> {
  get createdNotification (): NOneSignal.IOneSignalCreatedNotification {
    return this.module.createdNotification
  }

  set createdNotification (notificationData) {
    this.module.setCreatedNotification(notificationData)
  }

  get subscribersList (): ResponseData<NOneSignal.IOneSignalSubscribersResponseData> {
    return this.module.subscribersList
  }

  set subscribersList (subscribersData) {
    this.module.setSubscribers(subscribersData)
  }

  createPushNotification (): Promise<void> {
    // @ts-ignore
    return this.module.createPushNotification()
  }

  getSubscribers (pageParams: PageParams) {
    return this.module.getSubscribers(pageParams)
  }
}
