import { Component, mixins } from 'nuxt-property-decorator'
import { NGamificationRewards } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { ORDER, PageParams, ValidatorParams } from '~/types/store/store-base-type'
import validatorsPattern from '~/utils/validators'
import DataViewerMixin from '~/mixins/data-viewer'
import pageSizes from '~/utils/page-sizes'
import { ESortDir } from '~/types/data-viewer'
import { convertKeysToCamelCase, parseStringFromCamelToSnakeCase } from '~/utils/snake-camel-cases'

@Component({
  name: 'GamificationRewardsMixin',
  mixins: [StoreMixin, DataViewerMixin]
})
export default class extends mixins(StoreMixin, DataViewerMixin) {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$gamification.rewards.resetItem()
    this.$gamification.rewards.resetList()
    this.$gamification.rewards.resetFilters()
    next()
  }

  validators: any = {
    title: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название награды',
      trigger: ['blur']
    }],
    typeCode: [{
      required: true,
      message: 'Выберите тип награды',
      trigger: ['change']
    }],
    achievementTitle: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название достижения',
      trigger: ['blur']
    }],
    achievementDescription: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите описание достижения',
      trigger: ['blur']
    }]
  }

  async getGamificationRewards (pageParams?: PageParams<NGamificationRewards.IReward>) {
    return await this.asyncRequestHandler(
      { loader: 'getGamificationRewards' },
      (async () => {
        const data = await this.fetchDataView<any>(
          {
            schema: {
              tableName: this.ETablesNames.GAMIFICATION_REWARDS_VIEW,
              page: +(pageParams?.page || 1),
              pageSize: +(pageParams?.pageSize || pageSizes.default),
              sortBy: parseStringFromCamelToSnakeCase(pageParams?.sort as string || 'id') as keyof NGamificationRewards.IReward,
              sortDir: (pageParams?.order || ORDER.ASC) as any,
              conditions: (pageParams?.conditions?.[0]?.conditions?.length && pageParams?.conditions) || undefined
            }
          },
          'getRewards'
        )

        data.data = convertKeysToCamelCase(data.data)

        this.$gamification.rewards.list = data
      })()
    )
  }

  async getGamificationRewardById (id: number, save = true) {
    return await this.asyncRequestHandler(
      { loader: 'getGamificationRewardById' },
      this.$gamification.rewards.getItem(id, save)
    )
  }

  async removeGamificationRewardById (id: number) {
    return await this.asyncRequestHandler(
      {
        loader: 'removeGamificationRewardById',
        message: (reward: NGamificationRewards.IReward) => `Награда ${reward.title} удалена`
      },
      this.$gamification.rewards.removeItem(id)
    )
  }

  async saveGamificationReward (id?: number) {
    return await this.asyncRequestHandler(
      {
        loader: 'saveGamificationReward',
        message: (reward: NGamificationRewards.IReward) => `Награда ${reward.title} ${id ? 'изменена' : 'создана'}`
      },
      id
        ? this.$gamification.rewards.editItem(id)
        : this.$gamification.rewards.createItem()
    )
  }

  setGamificationRewardItemProp<K extends keyof NGamificationRewards.IReward> ({ key, value }: { key: K, value: NGamificationRewards.IReward[K] }) {
    this.$gamification.rewards.setItemProp(key, value)
  }

  async updateRewardFormItemProp ({ key, value, id } : { key: keyof NGamificationRewards.IReward, value: number, id: number }) {
    const temp = this.$gamification.rewards.itemByIdentifier(id)

    if (!temp) { return }

    this.$gamification.rewards.item = { ...temp, [key]: value }

    await this.saveGamificationReward(id)

    this.$gamification.rewards.resetItem()

    this.$fetch()
  }
}
