export const websiteAssistantPageFields = [
  { name: 'id' },
  { name: 'title' },
  { name: 'url' },
  { name: 'active' },
  { name: 'params' },
  { name: 'created_at', alias: 'createdAt' },
  { name: 'updated_at', alias: 'updatedAt' },
  { name: 'count_questions', alias: 'countQuestions' },
  { name: 'count_answers', alias: 'countAnswers' },
  { name: 'form_id', alias: 'formId' },
  { name: 'creator_id', alias: 'creatorId' },
  { name: 'media_file_id', alias: 'mediaFileId' },
  { name: 'site_title', alias: 'siteTitle' }
]

export const websiteAssistantPagesPath = '/website-assistant/assistant-pages'
