
// @ts-ignore
// eslint-disable-next-line import/no-named-as-default
import Sortable from 'sortablejs'
import Vue from 'vue'
import { Component, Watch } from 'nuxt-property-decorator'
import ScrollPane from '~/components/common/tags-view/scroll-pane.vue'
import SiteSearch from '~/components/common/site-search/index.vue'
@Component({
  components: { ScrollPane, SiteSearch }
})
export default class TagsView extends Vue {
  /**
   * *________________ Data ______________________
   */
  tags = [] as {path: string, name: string}[]
  visible = false
  top = 0
  left = 0
  theme: string | null = ''
  drawerVisible = false
  selectedTag = {} as {path: string, name: string}
  affixTags = [{ path: '/', name: 'Главная' }]
  fixedTags = [] as {path: string, name: string}[]
  fixedColors = [
    'rgb(64, 159, 255)', 'rgb(0, 150, 136)', 'rgb(83, 109, 254)', 'rgb(255, 92, 147)', 'rgb(238, 79, 18)', 'rgb(255, 152, 0)', 'rgb(163, 64, 249)'
  ]

  sortable:any = {}

  sideBarMenu = [{
    sliderTextColor: '#b8bee5',
    sliderOpened: '#101538',
    sliderColor: '#2f3556'
  }, {
    sliderTextColor: '#303133',
    sliderOpened: '#fff',
    sliderColor: '#FAFAFA'
  }, {
    sliderTextColor: '#bfcbd9',
    sliderOpened: '#303030',
    sliderColor: '#212121'
  }, {
    sliderTextColor: '#bfcbd9',
    sliderColor: '#434F68',
    sliderOpened: '#334058'
  }, {
    sliderOpened: '#474E59',
    sliderTextColor: '#bfcbd9',
    sliderColor: '#383f45'
  }, {
    sliderOpened: '#031627',
    sliderTextColor: '#bfcbd9',
    sliderColor: '#0f2537'
  }, {
    sliderColor: '#474e54',
    sliderOpened: '#494f5a',
    sliderTextColor: '#bfcbd9'
  }, {
    sliderColor: '#570c35',
    sliderOpened: '#410a28',
    sliderTextColor: '#b9b9b9'
  }]

  currentSideBar = 0
  primaryColor: string | null = ''

  /**
   * *________________ Computed ______________________
   */
  get themeActive () {
    return this.theme === 'dark'
  }

  /**
   * *________________ Watch ______________________
   */
  @Watch('$route')
  onChangeRoute () {
    this.addTags()
  }

  @Watch('visible')
  onChangeVisible (value: any) {
    if (value) {
      document.body.addEventListener('click', this.closeMenu)
    } else {
      document.body.removeEventListener('click', this.closeMenu)
    }
  }

  /**
   * *________________ Mounted ______________________
   */
  mounted () {
    this.fixedTags = JSON.parse(localStorage.getItem('tags') || '[]')
    this.primaryColor = localStorage.getItem('primaryColor') || '64, 159, 255'
    document.body.style.setProperty('--primary', this.primaryColor)
    document.body.style.setProperty('--primaryPlain', `rgb(${this.primaryColor}, 0.1)`)
    this.currentSideBar = +(localStorage.getItem('sidebarTheme') || 0)
    if (this.sideBarMenu[this.currentSideBar]) {
      document.body.style.setProperty('--sliderTextColor', this.sideBarMenu[this.currentSideBar].sliderTextColor)
      document.body.style.setProperty('--sliderOpened', this.sideBarMenu[this.currentSideBar].sliderOpened)
      document.body.style.setProperty('--sliderColor', this.sideBarMenu[this.currentSideBar].sliderColor)
    }

    this.theme = localStorage.getItem('nuxt-color-mode')
    if (!this.theme) {
      this.theme = 'light'
    }
    this.tags = [...this.affixTags, ...this.fixedTags]
    this.addTags()
    this.$nextTick(() => {
      this.setSort()
    })
  }

  /**
   * *________________ Methods ______________________
   */
  changeSideBar (index: number) {
    this.currentSideBar = index
    document.body.style.setProperty('--sliderTextColor', this.sideBarMenu[index].sliderTextColor)
    document.body.style.setProperty('--sliderOpened', this.sideBarMenu[index].sliderOpened)
    document.body.style.setProperty('--sliderColor', this.sideBarMenu[index].sliderColor)
    localStorage.setItem('sidebarTheme', String(this.currentSideBar))
  }

  clearColors () {
    this.primaryColor = '64, 159, 255'
    document.body.style.setProperty('--primary', this.primaryColor)
    document.body.style.setProperty('--primaryPlain', `rgb(${this.primaryColor}, 0.1)`)
    localStorage.setItem('primaryColor', this.primaryColor)
    this.currentSideBar = 0
    document.body.style.setProperty('--sliderTextColor', this.sideBarMenu[0].sliderTextColor)
    document.body.style.setProperty('--sliderOpened', this.sideBarMenu[0].sliderOpened)
    document.body.style.setProperty('--sliderColor', this.sideBarMenu[0].sliderColor)
    localStorage.setItem('sidebarTheme', String(this.currentSideBar))
  }

  changePrimaryColor (value: string) {
    this.primaryColor = value.slice(4, value.length - 1)
    document.body.style.setProperty('--primary', this.primaryColor)
    document.body.style.setProperty('--primaryPlain', `rgb(${this.primaryColor}, 0.1)`)
  }

  submitPrimaryColor (value: string) {
    if (value) {
      this.primaryColor = value.slice(4, value.length - 1)
      document.body.style.setProperty('--primary', this.primaryColor)
      document.body.style.setProperty('--primaryPlain', `rgb(${this.primaryColor}, 0.1)`)
      localStorage.setItem('primaryColor', this.primaryColor)
    } else {
      this.primaryColor = localStorage.getItem('primaryColor')
      document.body.style.setProperty('--primary', this.primaryColor)
      document.body.style.setProperty('--primaryPlain', `rgb(${this.primaryColor}, 0.1)`)
    }
  }

  openDrawer () {
    this.drawerVisible = true
  }

  setSort () {
    // @ts-ignore
    const el = this.$refs.scrollPane.$el.querySelectorAll('.el-scrollbar__view')[0]
    this.sortable = Sortable.create(el, {
      ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
      setData (dataTransfer: { setData: (arg0: string, arg1: string) => void }) {
        dataTransfer.setData('Text', '')
      },
      onEnd: (evt: any) => {
        this.$nextTick(() => {
          if (evt.newIndex !== evt.oldIndex) {
            const targetRow = this.tags.splice(evt.oldIndex, 1)[0]
            const affixTag = this.affixTags.findIndex(item => item.path === targetRow.path)
            const fixedTag = this.fixedTags.findIndex(item => item.path === targetRow.path)

            if (affixTag !== -1 && evt.newIndex >= this.affixTags.length - 1) {
              this.$nextTick(() => {
                this.tags.splice(this.affixTags.length - 1, 0, targetRow)
              })
            } else if (fixedTag !== -1 && (evt.newIndex >= this.affixTags.length + this.fixedTags.length - 1 || evt.newIndex <= this.affixTags.length)) {
              if (evt.newIndex >= this.affixTags.length + this.fixedTags.length - 1) {
                this.$nextTick(() => {
                  this.tags.splice(this.affixTags.length + this.fixedTags.length - 1, 0, targetRow)
                })
              } else if (evt.newIndex <= this.affixTags.length) {
                this.$nextTick(() => {
                  this.tags.splice(this.affixTags.length, 0, targetRow)
                })
              }
            } else if (evt.newIndex < this.affixTags.length + this.fixedTags.length) {
              if (fixedTag !== -1) {
                this.$nextTick(() => {
                  this.tags.splice(this.affixTags.length + evt.newIndex - 1, 0, targetRow)
                })
              } else {
                this.$nextTick(() => {
                  this.tags.splice(this.affixTags.length + this.fixedTags.length, 0, targetRow)
                })
              }
            } else {
              this.$nextTick(() => {
                this.tags.splice(evt.newIndex, 0, targetRow)
              })
            }
            if (fixedTag !== -1) {
              this.$nextTick(() => {
                this.fixedTags = this.tags.slice(this.affixTags.length, this.fixedTags.length + this.affixTags.length)
                this.fixedTags = this.fixedTags.map((tag) => { return { path: tag.path, name: tag.name } })
                localStorage.setItem('tags', JSON.stringify(this.fixedTags))
              })
            }
          }
        })
      }
    })
  }

  fixPage (tag: { path: any; name: any }) {
    let tagIndex = this.fixedTags.findIndex(tagItem => tagItem.path === tag.path)
    if (tagIndex === -1) {
      this.fixedTags.push({ name: tag.name, path: tag.path })
      localStorage.setItem('tags', JSON.stringify(this.fixedTags))
      tagIndex = this.tags.findIndex(tagItem => tagItem.path === tag.path)
      this.tags.splice(this.affixTags.length + this.fixedTags.length - 1, 0, this.tags.splice(tagIndex, 1)[0])
    } else {
      this.fixedTags.splice(tagIndex, 1)
      localStorage.setItem('tags', JSON.stringify(this.fixedTags))
      tagIndex = this.tags.findIndex(tagItem => tagItem.path === tag.path)
      this.tags.splice(this.affixTags.length + this.fixedTags.length, 0, this.tags.splice(tagIndex, 1)[0])
    }
  }

  refreshPage (tag: { path: any }) {
    if (tag.path === this.$route.path) {
      this.$nuxt.refresh()
    } else {
      this.$router.push(tag.path)
    }
  }

  closeSelectedTag (tag: { path: any }) {
    const deletedTag = this.tags.findIndex(route => route.path === tag.path)
    this.tags.splice(deletedTag, 1)
    if (tag.path === this.$route.path) {
      this.$router.push({ path: this.tags[deletedTag - 1].path })
    }
  }

  closeAllTags () {
    this.tags = [...this.affixTags, ...this.fixedTags]
    const path = this.tags.findIndex(route => route.path === this.selectedTag.path)
    if (path !== -1) {
      this.$router.push(this.tags[path].path)
    } else { this.$router.push(this.affixTags[0].path) }
  }

  closeOthersTags () {
    this.tags = [...this.affixTags, ...this.fixedTags, this.selectedTag]
    this.$router.push(this.selectedTag.path)
  }

  openMenu (tag: any, e: { clientX: number; clientY: any }) {
    const menuMinWidth = 105
    // @ts-ignore
    const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
    // @ts-ignore
    const offsetWidth = this.$el.offsetWidth // container width
    const maxLeft = offsetWidth - menuMinWidth // left boundary
    const left = e.clientX - offsetLeft + 15 // 15: margin right
    if (left > maxLeft) {
      this.left = maxLeft
    } else {
      this.left = left
    }

    this.top = e.clientY
    this.visible = true
    this.selectedTag = tag
  }

  isActive (route: { path: any }) {
    return route.path === this.$route.path
  }

  isAffix (tag: { path: any }) {
    const index = this.affixTags.findIndex(tagItem => tagItem.path === tag.path)
    return index !== -1
  }

  isFix (tag: { path: any }) {
    const index = this.fixedTags.findIndex(tagItem => tagItem.path === tag.path)
    return index !== -1
  }

  addTags () {
    const pathRoute = this.$route.path.split('/')
    if (+pathRoute[pathRoute.length - 1]) {
      return
    }
    if (/\/seo\/search-logs\/.+/.test(this.$route.path)) { return }
    if (this.$store.getters['breadcrumbs/breadcrumbs'].length > 3) { return }
    const tag = this.tags.findIndex(route => route.path === this.$route.path)
    if (tag === -1) {
      this.tags.push({ path: this.$route.path, name: this.$store.getters['breadcrumbs/breadcrumbs'][this.$store.getters['breadcrumbs/breadcrumbs'].length - 1].title })
      // @ts-ignore
      this.$nextTick(() => { if (this.$refs.scrollPane) { this.$refs.scrollPane.moveToTarget(this.$refs.tag[this.$refs.tag.length - 1]) } })
    } else if (tag > 0) {
      const tags = this.$refs?.tag as any[]
      if (tags) {
        this.$nextTick(() => {
          for (const tag of tags) {
            if (tag.to.path === this.$route.path) {
              // @ts-ignore
              this.$refs.scrollPane.moveToTarget(tag)
            }
          }
        })
      }
    } else {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs.scrollPane.moveToTarget(this.$refs.tag[0])
      })
    }
  }

  closeMenu () {
    this.selectedTag = {} as any
    this.visible = false
  }

  handleScroll () {
    this.closeMenu()
  }

  changeTheme () {
    this.$colorMode.preference =
        this.$colorMode.value === 'light' ? 'dark' : 'light'
    this.theme = this.$colorMode.preference
  }
}
