import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class OrdersStatusesStatisticsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$ordersStatusesStatistics.resetOrdersStatusesStatistics()
    next()
  }

  async getOrdersStatusesStatistics (from: number, to: number) {
    try {
      this.$wait.start('getOrdersStatusesStatistics')
      return await this.$ordersStatusesStatistics.getOrdersStatusesStatistics(from, to)
    } catch (e: any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getOrdersStatusesStatistics')
    }
  }
}
