import { Module, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { permissionsDefaultData, NChampionshipPermission } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig, ResponseData } from '~/types/store/store-base-type'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'
import { defaultData } from '~/types/store/constants'

@Module({
  name: 'championshipPermission',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipPermissionModule extends BaseModule<
    NChampionshipPermission.IPermission,
    'id',
    keyof NChampionshipPermission.IPermission
> {
  protected config: ModuleConfig<NChampionshipPermission.IPermission, 'id'> = {
    apiUrl: '/championship/permission',
    identifier: 'id'
  }

  protected itemData: NChampionshipPermission.IPermission = permissionsDefaultData

  protected permissionsViewListData: ResponseData<NChampionshipPermission.IPermissionsListItem> = defaultData

  get permissionsViewList () {
    return this.permissionsViewListData
  }

  @Mutation
  setPermissionsViewList (data: ResponseData<NChampionshipPermission.IPermissionsListItem>) {
    this.permissionsViewListData = data
  }

    @Action({
      rawError: true,
      commit: 'setPermissionsViewList'
    })
  async getChampionshipPermissionsView () {
    try {
      const { data } = await $axios.get<ResponseData<NChampionshipPermission.IPermissionsListItem>>(
        '/championship/permission/permissions',
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
