import { NAchievements } from './interfaces'
import AchievementsModule from './store'
import BasePlugin from '~/types/store/plugin-base'

export class AchievementsPlugin extends BasePlugin<
  NAchievements.IAchievement,
  'id',
  keyof NAchievements.IAchievement,
  AchievementsModule
> {}

export default function getAchievementsApi (store: AchievementsModule) {
  return new AchievementsPlugin(store)
}
