import { Action, Module, Mutation } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { NGamificationTasks } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'
import { $axios } from '~/utils/api'
import { FormError } from '~/store/interfaces'

@Module({
  name: 'gamificationTasks',
  stateFactory: true,
  namespaced: true
})
export default class GamificationTasksModule extends BaseModule<
  NGamificationTasks.ITask,
  'id',
  keyof NGamificationTasks.ITask
> {
  protected config: ModuleConfig<NGamificationTasks.ITask, 'id'> = {
    apiUrl: '/gamification/v1/admin/tasks',
    identifier: 'id',
    apiUrlPrefixEnv: 'SITE_API'
  }

  protected itemData: NGamificationTasks.ITask = {
    active: true,
    announcement: '',
    autoRenewal: false,
    checkType: 'auto',
    dateFrom: null,
    dateTo: null,
    daysOfWeek: [],
    fields: [],
    isDaily: false,
    isMandatory: false,
    params: {},
    precedingTasks: [],
    receiveType: 'auto',
    rewardId: null,
    sort: 0,
    title: '',
    userFilterUrl: ''
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      active: true,
      announcement: '',
      autoRenewal: false,
      checkType: 'auto',
      dateFrom: null,
      dateTo: null,
      daysOfWeek: [],
      fields: [],
      isDaily: false,
      isMandatory: false,
      params: {},
      precedingTasks: [],
      receiveType: 'auto',
      rewardId: null,
      sort: 0,
      title: '',
      userFilterUrl: ''
    }
  }

  @Action({
    rawError: true
  })
  async updateAdditionalField ({ code, params } : { code: string, params: Record<string, boolean> }) {
    try {
      const { data } = await $axios.put(
        `/gamification/v1/admin/tasks/fields/${code}`,
        params,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
