import { Context } from '@nuxt/types'
import { PromoCodesPlugin, PromoCode, Filters, PromoCodesCreationMethod } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (this: Context, promoCodesStore:any) {
  const ctx = this
  const api: PromoCodesPlugin = {
    // ? ________ getters ________

    get filters (): Filters {
      return promoCodesStore.filters
    },

    get promoCodesList (): ResponseData<PromoCode> {
      return promoCodesStore.promoCodesList
    },

    get validators (): ValidatorParams {
      return promoCodesStore.validators
    },

    get methodsValidators (): ValidatorParams {
      return promoCodesStore.methodsValidators
    },

    promoCodeById (id: number): PromoCode | undefined {
      return promoCodesStore.promoCodeById(id)
    },

    get currentPromoCode (): PromoCode {
      return promoCodesStore.currentPromoCode
    },

    get currentPromoCodesCreationMethod (): PromoCodesCreationMethod {
      return promoCodesStore.currentPromoCodesCreationMethod
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      promoCodesStore.setFilters(filters)
    },

    resetFilters (): void {
      promoCodesStore.resetFilters()
    },

    set promoCodesList (promoCodes: ResponseData<PromoCode>) {
      promoCodesStore.setPromoCodesList(promoCodes)
    },

    resetCurrentPromoCode () {
      promoCodesStore.resetCurrentPromoCode()
    },

    resetCurrentPromoCodesCreationMethod () {
      promoCodesStore.resetCurrentPromoCodesCreationMethod()
    },

    resetPromoCodes () {
      promoCodesStore.resetPromoCodes()
    },

    set currentPromoCode (promoCode: PromoCode) {
      promoCodesStore.setCurrentPromoCode(promoCode)
    },

    set currentPromoCodesCreationMethod (promoCodesCreationMethod: PromoCodesCreationMethod) {
      promoCodesStore.setCurrentPromoCodesCreationMethod(promoCodesCreationMethod)
    },

    get promoCodesCreationMethodsList (): ResponseData<PromoCodesCreationMethod> {
      return promoCodesStore.promoCodesCreationMethodsList
    },

    set promoCodesCreationMethodsList (methods: ResponseData<PromoCodesCreationMethod>) {
      promoCodesStore.setPromoCodesCreationMethodsList(methods)
    },

    // ? ________ actions ________

    getPromoCodes (pageParams: PageParams): Promise<ResponseData<PromoCode>> {
      const params = { pageParams, siteDomain: ctx.$config.SITE_DOMAIN }

      return promoCodesStore.getPromoCodes(params)
    },

    getPromoCode (id: number): Promise<PromoCode> {
      const params = { id, siteDomain: ctx.$config.SITE_DOMAIN }

      return promoCodesStore.getPromoCode(params)
    },

    removePromoCode (id: number): Promise<PromoCode> {
      const params = { id, siteDomain: ctx.$config.SITE_DOMAIN }
      return promoCodesStore.removePromoCode(params)
    },

    editPromoCode (): Promise<PromoCode> {
      return promoCodesStore.editPromoCode(ctx.$config.SITE_DOMAIN)
    },

    createPromoCode (): Promise<PromoCode> {
      return promoCodesStore.createPromoCode(ctx.$config.SITE_DOMAIN)
    },

    createPromoCodesCreationMethod (): Promise<PromoCodesCreationMethod> {
      return promoCodesStore.createPromoCodesCreationMethod(ctx.$config.SITE_DOMAIN)
    },

    getPromoCodesCreationMethods (pageParams: PageParams): Promise<ResponseData<PromoCodesCreationMethod>> {
      const params = { pageParams, siteDomain: ctx.$config.SITE_DOMAIN }
      return promoCodesStore.getPromoCodesCreationMethods(params)
    }
  }
  return api
}
