import { Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import Vue from 'vue'
import type { CreateItem, IFilters, ModuleConfig, PageParams, ResponseData } from './store-base-type'
import { defaultData } from './constants'
import { FormError } from '~/store/interfaces'
import { $axios } from '~/utils/api'

/**
 * Базовый класс модуля стора для наследования другими модулями
 *
 * @template T - Тип сущности
 * @template I - Ключ идентификатора сущности
 * @template KEYS - Ключи свойств сущности (включая вложенные)
 * @template FILTERS_KEYS - Ключи свойств сущности используемые в качестве фильтров
 */
export default class BaseModule<
  T,
  I extends keyof T,
  KEYS,
  FILTERS_KEYS extends keyof Partial<T> = keyof Partial<T>
> extends VuexModule {
  /**
   * Конфигурация модуля
   */
  protected config: ModuleConfig<T, I> = {
    apiUrl: '',
    identifier: '' as I,
    apiUrlPrefixEnv: ''
  }

  /**
   * Значение фильтров списка сущностей
   */
  protected listFilters: IFilters<T, FILTERS_KEYS> = {}

  /**
   * Значение списка сущностей
   */
  protected listData: ResponseData<T> = defaultData

  /**
   * Значение сущности
   */
  protected itemData: T = {} as T

  /**
   * Геттер фильтров списка сущностей
   */
  get filters () {
    return this.listFilters
  }

  /**
   * Геттер списка сущностей
   */
  get list () {
    return this.listData
  }

  /**
   * Геттер сущности
   */
  get item () {
    return this.itemData
  }

  /**
   * Получение сущности по идентификатору
   */
  get itemByIdentifier () {
    return function (identifier: T[I]) {
      return this.listData?.data
        ?.slice()
        ?.find(
          (item: T) => item?.[`${this.config.identifier}` as I] === identifier
        )
    }
  }

  /**
   * Установка фильтров
   * @param data объект фильтров
   */
  @Mutation
  setFilters (data: IFilters<T, FILTERS_KEYS>) {
    this.listFilters = data
  }

  /**
   * Установка значения фильтра по ключу
   * @param key ключ фильтра
   * @param value значение фильтра
   */
  @Mutation
  setFilterByKey<K extends FILTERS_KEYS> ({
    key,
    value
  }: {
    key: K;
    value: IFilters<T, FILTERS_KEYS>[K];
  }) {
    // @ts-ignore
    Vue.set(this.listFilters, key, value)
  }

  /**
   * Сброс фильтров списка сущностей
   */
  @Mutation
  resetFilters () {
    this.listFilters = {}
  }

  /**
   * Установка списка сущностей
   * @param data список сущностей
   */
  @Mutation
  setList (data: ResponseData<T>) {
    this.listData = data
  }

  /**
   * Установка сущности
   * @param data сущность
   */
  @Mutation
  setItem (data: T) {
    this.itemData = data
  }

  /**
   * Установка свойства сущности по ключу
   * @param key ключ свойства
   * @param value значение свойства
   */
  @Mutation
  setItemProp<K extends keyof T, V extends T[K]> ({
    key,
    value
  }: {
    key: K;
    value: V;
  }) {
    // @ts-ignore
    Vue.set(this.itemData, key, value)
  }

  /**
   * Сброс списка сущностей
   */
  @Mutation
  resetList () {
    this.listData = defaultData
  }

  @Mutation
  resetItemData () {
    this.itemData = {} as T
  }

  /**
   * * Сеттер свойства сущности по ключам
   * @param keys - ключи свойства в последовательности от верхнего к нижнему
   * @param value - значение свойства/элемента свойства
   * @returns
   */
  @Mutation
  setItemPropByKeys ({ keys, value }: { keys: (KEYS | number)[]; value: any }) {
    // * Прерывание операции, при отсутствии ключей
    if (!keys || !keys.length) {
      return
    }
    // * Создание инстанса сущности
    let stateInstance = this.itemData
    // * Сохранение индекса изменяемого свойства
    const setPropIndex = keys.length - 1
    // * Переменная сигнатуры отсутствующего параметра
    let missingParam
    // * Обход ключей родительских свойств сущности
    for (let keyIndex = 0; keyIndex < setPropIndex; keyIndex++) {
      // * Проверка наличия свойства по текущему ключу
      // @ts-ignore
      if (!stateInstance[keys[keyIndex]]) {
        // * Определение сигнатуры свойства по типу следующего ключа
        missingParam = typeof keys[keyIndex + 1] === 'number' ? [] : {}
        // * Если отсутствует, то реактивное добавление его сигнатуры, в сущность
        // @ts-ignore
        Vue.set(stateInstance, keys[keyIndex], missingParam)
      }
      // * Замена текущего инстанса, инстансом вложенного свойства
      // @ts-ignore
      stateInstance = stateInstance[keys[keyIndex]]
    }
    // * Реактивное изменение значения устанавливаемого свойства
    // @ts-ignore
    Vue.set(stateInstance, keys[setPropIndex], value)
  }

  /**
   * Метод получения списка сущностей
   * @param pageParams параметры пагинации
   * @returns список сущностей
   */
  @Action({
    rawError: true
  })
  async getList (pageParams?: PageParams<T>) {
    try {
      const { data } = await $axios.get<ResponseData<T>>(
        `${this.config.apiUrl}`,
        {
          params: { ...this.listFilters, ...pageParams },
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * Метод получения сущности по идентификатору
   * @param identifier идентификатор сущности
   * @returns сущность
   */
  @Action({
    rawError: true
  })
  async getItem (identifier: T[I]) {
    try {
      const resp = await $axios.get<{ data: T } | T>(
        `${this.config.apiUrl}/${
          identifier
        }`,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return (resp?.data as { data: T })?.data ?? resp?.data as T
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * Метод создания сущности
   * @returns сущность
   */
  @Action({
    rawError: true
  })
  async createItem (item: CreateItem<T>) {
    try {
      const resp = await $axios.post<{ data: T } | T>(
          `${this.config.apiUrl}`,
          item,
          {
            headers: {
              common: {
                apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
              }
            }
          }
      )
      return (resp?.data as { data: T })?.data ?? resp?.data as T
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * Метод редактирования сущности
   * @param identifier идентификатор сущности
   * @returns сущность
   */
  @Action({
    rawError: true
  })
  async editItem (params: { identifier: T[I]; item?: T }) {
    try {
      const resp = await $axios.put<{ data: T } | T>(
        `${this.config.apiUrl}/${
          params.identifier
        }`,
        params.item,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return (resp?.data as { data: T })?.data ?? resp?.data as T
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * Метод удаления сущности
   * @param identifier идентификатор сущности
   * @returns сущность
   */
  @Action({
    rawError: true
  })
  async removeItem (identifier: T[I]) {
    try {
      const resp = await $axios.delete<{ data: T } | T>(
        `${this.config.apiUrl}/${identifier}`,
        {
          headers: {
            common: {
              apiUrlPrefixEnv: this.config.apiUrlPrefixEnv || ''
            }
          }
        }
      )
      return (resp?.data as { data: T })?.data ?? resp?.data as T
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
