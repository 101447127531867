import { Component, Vue } from 'nuxt-property-decorator'
import { WebsiteAssistantPageForm, UpdateAssistantPageParams, UpdateAssistantQuestionParams, WebsiteAssistantQuestionForm } from './interfaces'
import { PageParams } from '~/store/interfaces'

@Component
export default class WebsiteAssistantMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    next()
  }

  mounted () {
    this.$wait.end('leaveRouter')
  }

  async fetchWebsiteAssistantPageByID (id: string) {
    try {
      this.$wait.start('fetchWebsiteAssistantPageByID')
      return await this.$websiteAssistant.fetchWebsiteAssistantPageByID(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('fetchWebsiteAssistantPageByID')
    }
  }

  async deleteWebsiteAssistantPageByID (id: number) {
    try {
      this.$wait.start('deleteWebsiteAssistantPageByID')
      return await this.$websiteAssistant.deleteWebsiteAssistantPageByID(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('deleteWebsiteAssistantPageByID')
    }
  }

  async createWebsiteAssistantPage (data: WebsiteAssistantPageForm) {
    try {
      this.$wait.start('createWebsiteAssistantPage')
      return await this.$websiteAssistant.createWebsiteAssistantPage(data)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createWebsiteAssistantPage')
    }
  }

  async updateWebsiteAssistantPageByID (
    id: number,
    params: UpdateAssistantPageParams
  ) {
    try {
      this.$wait.start('updateWebsiteAssistantPageByID')
      return await this.$websiteAssistant.updateWebsiteAssistantPageByID(
        id,
        params
      )
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('updateWebsiteAssistantPageByID')
    }
  }

  async fetchWebsiteAssistantQuestions (
    pagesAssistantId: number,
    params: PageParams
  ) {
    try {
      this.$wait.start('fetchWebsiteAssistantQuestions')
      return await this.$websiteAssistant.fetchWebsiteAssistantQuestions(
        pagesAssistantId,
        params
      )
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('fetchWebsiteAssistantQuestions')
    }
  }

  async fetchWebsiteAssistantQuestionByID (id:number) {
    try {
      this.$wait.start('fetchWebsiteAssistantQuestionByID')
      return await this.$websiteAssistant.fetchWebsiteAssistantQuestionByID(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('fetchWebsiteAssistantQuestionByID')
    }
  }

  async createWebsiteAssistantQuestion (id: number, data: WebsiteAssistantQuestionForm) {
    try {
      this.$wait.start('createWebsiteAssistantQuestion')
      return await this.$websiteAssistant.createWebsiteAssistantQuestion(id, data)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createWebsiteAssistantQuestion')
    }
  }

  async updateWebsiteAssistantQuestionByID (id: number, params: UpdateAssistantQuestionParams) {
    try {
      this.$wait.start('updateWebsiteAssistantQuestionByID')
      return await this.$websiteAssistant.updateWebsiteAssistantQuestionByID(
        id,
        params
      )
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('updateWebsiteAssistantQuestionByID')
    }
  }

  async deleteWebsiteAssistantQuestionByID (id: number) {
    try {
      this.$wait.start('deleteWebsiteAssistantQuestionByID')
      return await this.$websiteAssistant.deleteWebsiteAssistantQuestionByID(id)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('deleteWebsiteAssistantQuestionByID')
    }
  }
}
