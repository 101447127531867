import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import { FormError, defaultData, PageParams } from '../../interfaces'
import { RequestOffer, RequestOfferStatus, OffersListData, Filters } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'offers',
  stateFactory: true,
  namespaced: true
})

export default class OffersModule extends VuexModule {
  filtersValue: Filters = {}
  /**
   * * Массив статусов offers
   */
  offerStatusesValue: RequestOfferStatus[] = []

  /**
   * * Обьект текущего offer
   */
  offer: RequestOffer = {
    userId: 0,
    offerId: 0,
    operatorId: 0,
    statusId: 0,
    createdAt: null,
    data: {
      title: ''
    },
    userInfo: undefined
  }

  /**
   * * Массив всех offers исходя из запроса
   */
  offers: OffersListData = { ...defaultData, countNew: 0 }

  // ? ______________ getters ______________

  get filters () {
    return this.filtersValue
  }

  /**
   * * Получить offer из массива offers
   */
  get offerById () {
    const offers = this.offers
    return function (id: number): RequestOffer | undefined {
      return offers.data.find(offer => offer.id === id)
    }
  }

  get offerStatuses (): RequestOfferStatus[] {
    return this.offerStatusesValue
  }

  /**
   * * Получить массив offers и пагинацией
   */
  get offersList (): OffersListData {
    return this.offers
  }

  /**
   * * Получить текущий offer
   */
  get currentOffer (): RequestOffer {
    return this.offer
  }

  // ? ______________ setters ______________

  @Mutation
  setFilters (filters: Filters): void {
    this.filtersValue = filters
  }

  @Mutation
  resetFilters (): void {
    this.filtersValue = {}
  }

  /**
   * * Обнулить форму редактирования или создания
   */
  @Mutation
  resetCurrentOffer () {
    this.offer = {
      userId: 0,
      statusId: 0,
      offerId: 0,
      operatorId: 0,
      createdAt: null,
      data: {
        title: ''
      },
      userInfo: undefined
    }
  }

  @Mutation
  resetOffersList () {
    this.offers = { ...defaultData, countNew: 0 }
  }

  /**
   * * Установить массив Offers
   * @param offers массив Offers
   */
  @Mutation
  setOffersList (offers: OffersListData) {
    this.offers = offers
  }

  /**
   * * Установить массив статусов Offers
   * @param offerStatuses массив статусов Offers
   */
  @Mutation
  setOfferStatuses (offerStatuses: RequestOfferStatus[]) {
    this.offerStatusesValue = offerStatuses
  }

  /**
   * * Установить текущий Offer
   * @param offers текущий Offer
   */
  @Mutation
  setCurrentOffer (offer: RequestOffer) {
    this.offer = offer
  }

  // ? ______________________________________actions______________________________________

  /**
   * * Получить список Offers по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setOffersList'
  })
  async getOffers (pageParams: PageParams | null) {
    try {
      const { data } = await $axios.get('/shop/request-offers', { params: { ...pageParams, ...this.filters } })
      const response: OffersListData = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список Offers по параметрам запроса
   * @param pageParams параметры запроса
   */
  @Action({
    rawError: true,
    commit: 'setCurrentOffer'
  })
  async getOffer (id: number) {
    try {
      const { data: { data } } = await $axios.get(`/shop/request-offers/${id}`)
      const response: RequestOffer = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список статусы Offers
   */
  @Action({
    rawError: true,
    commit: 'setOfferStatuses'
  })
  async getRequestOfferStatuses () {
    try {
      const { data: { data } } = await $axios.get('/shop/request-offers/statuses')
      const response: RequestOfferStatus[] = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Получить список статусы Offers
   */
  @Action({
    rawError: true
  })
  async changeRequestOfferStatus ({ id, status }: {id:number, status:number}) {
    try {
      const { data: { data } } = await $axios.post(`/shop/request-offers/${id}/change-status`, { status })
      const response: RequestOffer = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

  /**
   * * Создать заказ
   * @param id id offer
   */
    @Action({
      rawError: true
    })
  async createOrderByOffer (id: number) {
    try {
      /**
       * paymentTypeId: this.offer.paymentTypeId removed from request body
       */
      const { data } = await $axios.post(`/shop/request-offers/${id}/create-order`)
      const response = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }
}
