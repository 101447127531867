export const notificationDefaultData = {
  id: null,
  completedAt: null,
  contents: '',
  createdAt: null,
  heading: '',
  image: '',
  role: '',
  successful: null,
  webUrl: ''
}

export const createdNotificationDefaultData = {
  content: '',
  fileId: null,
  heading: '',
  role: null,
  sendAfter: null,
  webUrl: ''
}

export const templateDefaultData = {
  id: null,
  code: '',
  content: '',
  createdAt: null,
  heading: '',
  webUrl: ''
}

export const createdTemplateDefaultData = {
  id: null,
  code: '',
  content: '',
  heading: '',
  link: ''
}

export const subscribersDefaultData = {
  players: [],
  totalCount: null,
  totalSubscribed: null,
  totalUnsubscribed: null
}

export const statusesDefaultData = {
  id: null,
  code: '',
  name: '',
  template: null
}
