declare global {
  interface Window {
    ymaps: any;
  }
}

/**
 * * Фильтры заказов
 */
export interface OrderFilters {
  /**
   * * id способа оплаты
   */
  paymentId?: number;
  /**
   * * id заказа
   */
  id?: number;
  /**
   * * Временной промежуток
   */
  rangeDate: Array<number>;
  /**
   * *
   */
  status: string;
  condition: string;
  /**
   * * Артикул
   */
  vendorCode?: number;
  /**
   * * Трек номер
   */
  track: string;
  /**
   * * id адреса доставки
   */
  shippingId?: number;
  /**
   * * id оператора
   */
  operatorId?: number;
  /**
   * * Грппа пользователя
   */
  userGroup: string;
  /**
   * * Телефонный номер комментарий
   */
  phone: string;
  /**
   * * Коммент
   */
  comment: string;
};

/**
 * TODO Тип доставки
 */
export interface DeliveryType {
  id: number;
  deliveryId: string;
  name: string;
};

/**
 * * Пункт меню sidebar
 */
export interface MenuItem {
  item: {
    value: string,
    icon: string,
    link: string
  },
  subItems?: Array<{
    value: string
    link: string
  }>
}

/**
 * * Информация о товаре в заказе
 */
export interface ProductInfo {
  /**
   * номер заказа
   */
  orderId: {
    name: string;
    value: number;
  };
  /**
   * * Категория
   */
  category: {
    name: string;
    value: string;
  };
  /**
   * * Название
   */
  name: {
    name: string;
    value: string;
  };
  /**
   * * Кол-во
   */
  amount: {
    name: string;
    value: number;
  };
  /**
   * * Цена
   */
  price: {
    name: string;
    value: number;
  };
  /**
   * * Цена за грамм
   */
  pricePerGram: {
    name: string;
    value: number;
  };
  /**
   * * Итоговая цена с учётом скидок
   */
  totalPrice: {
    name: string;
    value: number;
  };
  /**
   * * Корректировка цены
   */
  correction: {
    name: string;
    value: number;
  };
  /**
   * * Итоговая цена
   */
  total: {
    name: string;
    value: number;
  };
};

/**
 * * Параметры
 */
export interface ProductParametrs {
  /**
   * * Артикул
   */
  vendorCode: {
    name: string;
    value: number;
  };
  /**
   * * Штрихкод
   */
  barcode: {
    name: string;
    value: number;
  };
  /**
   * * Изготовитель
   */
  maker: {
    name: string;
    value: string;
  };
  /**
   * TODO Переиспользовать options из cms3-shop-module-ts
   */
  alloy: {
    name: string;
    value: number;
  };
  /**
   * TODO Переиспользовать options из cms3-shop-module-ts
   */
  size: {
    name: string;
    value: number;
  };
  /**
   * * Вес
   */
  weight: {
    name: string;
    value: number;
  };
};

/**
 * * Скидка на товар
 */
export interface ProductDiscount {
  /**
   * TODO ?
   */
  group: {
    name: string;
    value: number;
  };
  /**
   * Комплект, поле взято из верстки
   */
  kit: {
    name: string;
    value: number;
  };
}

/**
 * * Пользователь
 */
export interface User {
  id: number;
  login: string;
  email: string;
  status: boolean;
  role: string;
  phone: string;
  name: string;
  fio?: string
}
/**
 * * Информация о заказе
 */
export interface OrderInfo {
  /**
   * * Заказ
   */
  order: Order
  /**
   * * Пользователь
   */
  user: User,
  /**
   * * Дата заказа
   */
  orderDate: number,
  /**
   * * Товары
   */
  products: Array<{
    productinfo: ProductInfo,
    productparametrs: ProductParametrs,
    productdiscount: ProductDiscount
  }>
}

/**
 * * Способ оплаты
 * TODO множетсвенное числв в единственное
 */
export interface Payments {
  /**
   * * id способа оплаты
   */
  id: number;
  /**
   * * Название
   */
  name: string;
  /**
   * * Описание
   */
  description: string;
};

/**
 * * Группа пользователя
 */
export interface UserGroup {
  /**
   * * Название
   */
  name: string;
  /**
   * * Описание
   */
  description: string;
};

/**
 * * Заказ
 */
export interface Order {
  /**
   * * Способ оплаты
   */
  payment?: Payments;
  /**
   * * id
   */
  id?: number;
  /**
   * * Время
   */
  time?: number | string;
  /**
   * * Время в миллисекундах
   */
  timestamp?: number;
  /**
   * TODO ?
   */
  status?: string;
  /**
   * TODO ?
   */
  condition?: string;
  /**
   * * Артикул
   */
  vendorCode?: number;
  /**
   * * Трек id
   */
  track?: string;
  /**
   * TODO Взять из модуля cms3-shop-module-ts
   */
  shipping?: {
    id?: number;
    name?: string;
    zip?: number;
    contactPerson?: string;
    email?: string;
    phone?: string;
  };
  /**
   * TODO ?
   */
  operator?: {
    id: number;
    name: string;
  };
  /**
   * * Профиль
   */
  profile?: {
    name?: string;
    id?: number
  };
  /**
   * Точка доставки delivery_data на сервере
   */
  delivery?: {
    id?: number,
    price?: number,
    point?: string
  }
  /**
   * * Группа пользователя
   */
  userGroup?: UserGroup;
  /**
   * * Телефонный номер
   */
  phone?: string;
  /**
   * * Комментарий
   */
  comment?: string;
  /**
   * * Цена
   */
  price?: number;
  /**
   * * Итоговоя цена
   */
  totalPrice?: number;
  /**
   * * Предоплата
   */
  prepay?: boolean;
  /**
   * * E-mail
   */
  email?: string;
  offers?: any;
  withIsBlank?: boolean
}

/**
 * * Информация о заказах
 */
export interface AllOrders {
  /**
   * * Заказы
   */
  orders?: Array<Order>
  /**
   * * Итоговые цены со всех заказов
   */
  allOrdersPrice?: {
    price?: Number
    full_price?: Number // eslint-disable-line
  }
}

export interface OrderStasus {
  id?: number,
  name?: string,
  group_id?: number // eslint-disable-line
}

export interface OrderConfirm {
  id?: number;
  name?: string;
  color?: string;
}

export interface AllProducts {
  productList: Array<Product>
  meta: PageMeta
}

export interface PageMeta {
  totalCount: number;
  pageCount: number;
  currentPage: number;
  perPage: number;
  offset: number;
}

export interface Product {
  id: number
  title: string
  vendorCode: string
  imageId: string
  categoryId: number
  categoryName: string | undefined
  descr: string
  options: Array<Option>
  tags: Array<number>
  categories: Array<Category>
  isFavorite: boolean
  offers: Array<Offer>
}

export interface Option {
  id: number;
  title: string;
  code: string;
  values: Array<Option>
}

export interface Category {
  id: number;
  title: string;
  code: string;
  sort: boolean;
  active: boolean;
  guid: string;
  parentguid: string;
  childrenIds: Array<number>;
  parentId: number;
}

export interface Offer {
  id: number
  barcode: String
  title: String
  remnants: Remnants
  options: Array<Option>
  weight: number
  width: number
  height: number
  length: number
}

export interface RemnantDetail {
  id: number
  count: number
}

export interface Remnants {
  total: number
  detailS: Array<RemnantDetail>
}

/**
 * * Объект конфигурации файлов
 */
export interface ImagesConfig {
  quality?: number,
  format?: string,
  preview?: string
  // * Флаг применения квери параметров
  noQuery?: boolean
}

/**
 * * Объект конфигурации видео
 */
export interface VideosConfig {
  format?: string
}

export enum MimeTypes {
  'jpg' = 'image/jpeg',
  'jpeg' = 'image/jpeg',
  'png' = 'image/png',
  'gif' = 'image/gif',
  'svg' = 'image/svg+xml',
  'pdf' = 'application/pdf',
  'doc' = 'application/msword',
  'docx' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'xls' = 'application/vnd.ms-excel',
  'xlsx' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'ppt' = 'application/vnd.ms-powerpoint',
  'pptx' = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'txt' = 'text/plain',
  'html' = 'text/html',
  'css' = 'text/css',
  'js' = 'text/javascript',
  'json' = 'application/json',
  'xml' = 'application/xml',
  'zip' = 'application/zip',
  'rar' = 'application/x-rar-compressed',
  '7z' = 'application/x-7z-compressed'
}

export * from './table-settings/index'
