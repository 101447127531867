import { Component } from 'nuxt-property-decorator'
import { NChampionshipStages } from './interfaces'
import StoreMixin from '~/mixins/store-mixin'
import { PageParams } from '~/types/store/store-base-type'

@Component({
  name: 'ChampionshipStagesMixin'
})
export default class extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    // Запуск лоадера перехода по страницам
    this.$wait.start('leaveRouter')
    this.$championship.stages.resetItem()
    next()
  }

  getChampionshipStages (pageParams?: PageParams<NChampionshipStages.IStage>, save = true) {
    return this.asyncRequestHandler({
      loader: 'getChampionshipStages'
    },
    this.$championship.stages.getList(pageParams,
      save)
    )
  }

  getChampionshipStage (id: NChampionshipStages.IStage['id'], save = true) {
    return this.asyncRequestHandler(
      { loader: 'getChampionshipStage' },
      this.$championship.stages.getItem(id,
        save)
    )
  }

  createChampionshipStage () {
    return this.asyncRequestHandler(
      {
        loader: 'createChampionshipStage',
        message: (stage: NChampionshipStages.IStage) => `Стадия ${stage.name} создана`
      },
      this.$championship.stages.createItem()
    )
  }

  editChampionshipStage (id: NChampionshipStages.IStage['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'editChampionshipStage',
        message: (stage: NChampionshipStages.IStage) => `Стадия ${stage.name} изменена`
      },
      this.$championship.stages.editItem(id)

    )
  }

  removeChampionshipStage (id: NChampionshipStages.IStage['id']) {
    return this.asyncRequestHandler(
      {
        loader: 'removeChampionshipStage',
        message: (stage: NChampionshipStages.IStage) => `Стадия ${stage.name} удалена`
      },
      this.$championship.stages.removeItem(id)

    )
  }
}
