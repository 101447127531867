import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipAgeGroup } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipAgeGroup',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipAgeGroupModule extends BaseModule<
  NChampionshipAgeGroup.IAgeGroup,
  'id',
  keyof NChampionshipAgeGroup.IAgeGroup
> {
  protected config: ModuleConfig<NChampionshipAgeGroup.IAgeGroup, 'id'> = {
    apiUrl: '/championship/age-group',
    identifier: 'id'
  }

  protected itemData: NChampionshipAgeGroup.IAgeGroup = {
    name: '',
    code: '',
    sort: 0,
    active: true
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      name: '',
      code: '',
      sort: 0,
      active: true
    }
  }
}
