import { NRefund } from './interfaces'
import RefundModule from './store'
import { ResponseData } from '~/types/store/store-base-type'
import BasePlugin from '~/types/store/plugin-base'

export class RefundsPlugin extends BasePlugin<
  NRefund.IRefund,
  'id',
  keyof NRefund.IRefund,
  RefundModule
> {
  get refundsList (): NRefund.IRefundsData {
    const rawList = this.module.list as unknown as { data: { countNew: number; refunds: NRefund.IRefund[] } }

    return {
      ...this.module.list,
      data: rawList.data
    }
  }

  get statusesList (): ResponseData<NRefund.IRefundStatus> {
    return this.module.refundsStatuseslist
  }

  async getRefundsStatuses () {
    const data = await this.module.getRefundsStatuses()
    return data
  }

  async createOrderFromRefund (identifier: NRefund.IRefund['id']) {
    const data = await this.module.createOrderFromRefund(identifier)
    return data
  }

  async rejectRefund (identifier: NRefund.IRefund['id']) {
    const data = await this.module.rejectRefund(identifier)
    return data
  }
}

export default function getTasksApi (refundModule: RefundModule) {
  return new RefundsPlugin(refundModule)
}
