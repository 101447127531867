import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipStages } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipStages',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipStagesModule extends BaseModule<
  NChampionshipStages.IStage,
  'id',
  keyof NChampionshipStages.IStage
> {
  protected config: ModuleConfig<NChampionshipStages.IStage, 'id'> = {
    apiUrl: '/championship/stage',
    identifier: 'id'
  }

  protected itemData: NChampionshipStages.IStage = {
    id: 0,
    name: '',
    code: '',
    profileFields: [],
    cities: [],
    active: true,
    actions: []
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      id: 0,
      name: '',
      code: '',
      profileFields: [],
      cities: [],
      active: true,
      actions: []
    }
  }
}
