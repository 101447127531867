export function parseStringFromSnakeToCamelCase (string: string) {
  return string
    .split('_')
    .map((word, index) => index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
}

export function convertKeysToCamelCase (array: Array<Record<string, any>>): Array<Record<string, any>> {
  return array.map((item) => {
    return Object.keys(item).reduce((acc, key) => {
      acc[parseStringFromSnakeToCamelCase(key)] = item[key]
      return acc
    }, {} as Record<string, any>)
  })
}

export function parseStringFromCamelToSnakeCase (string: string): string {
  return string.replace(/([A-Z])/g, '_$1').toLowerCase()
}

export function convertKeysToSnakeCase (array: Array<Record<string, any>>): Array<Record<string, any>> {
  return array.map((item) => {
    return Object.keys(item).reduce((acc, key) => {
      acc[parseStringFromCamelToSnakeCase(key)] = item[key]
      return acc
    }, {} as Record<string, any>)
  })
}
