import { Module, Mutation } from 'vuex-module-decorators'
import { NChampionshipCompetency } from './interfaces'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'championshipCompetency',
  stateFactory: true,
  namespaced: true
})
export default class ChampionshipCompetencyModule extends BaseModule<
  NChampionshipCompetency.ICompetency,
  'id',
  keyof NChampionshipCompetency.ICompetency
> {
  protected config: ModuleConfig<NChampionshipCompetency.ICompetency, 'id'> = {
    apiUrl: '/championship/competency',
    identifier: 'id'
  }

  protected itemData: NChampionshipCompetency.ICompetency = {
    name: '',
    code: '',
    ageGroupIds: [],
    ageTypeIds: [],
    cities: [],
    sort: 0,
    active: true,
    family: false,
    payed: false,
    params: {}
  }

  @Mutation
  resetItemData () {
    this.itemData = {
      name: '',
      code: '',
      ageGroupIds: [],
      ageTypeIds: [],
      cities: [],
      sort: 0,
      active: true,
      family: false,
      payed: false,
      params: {}
    }
  }
}
