import { Component, Vue } from 'nuxt-property-decorator'
import { Method } from 'axios'

@Component
export default class ReindexerMixin extends Vue {
  /**
   * * Export reindexer
   */
  async reindexerExport (url: string = '/reindexer/export', method: Method = 'POST') {
    this.$wait.start(url)
    this.$notify({
      title: 'Началась выгрузка в reindexer',
      message: 'Это может занять некоторое время'
    })
    try {
      await this.$reindexer.export(url, method)
      this.$notify({
        title: 'Выполнено',
        message: 'Выгрузка в reindexer прошла успешно',
        type: 'success'
      })
    } catch (e:any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end(url)
    }
  }
}
